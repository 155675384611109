import React, { useContext } from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import useReactRouter from 'use-react-router'
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import {
  useGetUserQuery,
  useGetUserCommentsLazyQuery,
  useToggleUserCommentBanMutation,
} from '../generated/graphql'
import { PageLayout } from '../components/PageLayout'
import { Surface } from '../components/Surface'
import { ConfirmButton, ConfirmButtonVariants } from '../components/ConfirmButton'
import { UserContext } from '../components/UserProvider'
import { CommentList } from '../components/Comments/CommentList'

interface MatchParams {
  username: string
}

export const User = () => {
  const user = useContext(UserContext)
  const { match } = useReactRouter<MatchParams>()
  const { username } = match.params
  const { data, loading } = useGetUserQuery({ variables: { username } })
  const [toggleCommentingBan] = useToggleUserCommentBanMutation()
  const userId = data && data.user && data.user._id ? data.user._id : ''
  const commentBan = data && data.user && data.user.commentBan ? data.user.commentBan : ''
  const createdAt = data && data.user && data.user.createdAt ? data.user.createdAt : undefined

  return (
    <PageLayout loading={loading} noViewSwitch>
      <Surface
        title={username as string}
        subtitle={createdAt ? `Since: ${moment(createdAt).format('LL')}` : undefined}
        actions={
          user.isSuperAdmin || user.isModerator ? (
            commentBan ? (
              <ConfirmButton
                variant={ConfirmButtonVariants.iconButton}
                icon={<SpeakerNotesOffIcon />}
                label="Unban Commenting"
                confirmText="Are you sure you want to restore this users commenting abilities?"
                onClick={() => toggleCommentingBan({ variables: { id: userId } })}
              />
            ) : (
              <ConfirmButton
                variant={ConfirmButtonVariants.iconButton}
                icon={<SpeakerNotesIcon />}
                label="Ban Commenting"
                confirmText="Are you sure you want to ban this user from commenting?"
                onClick={() => toggleCommentingBan({ variables: { id: userId } })}
              />
            )
          ) : (
            undefined
          )
        }
      >
        <CommentList
          query={useGetUserCommentsLazyQuery}
          queryVars={{ variables: { id: userId } }}
          queryName="getUserComments"
          isModerate={user.isModerator || user.isSuperAdmin}
        />
      </Surface>
    </PageLayout>
  )
}

export const GET_USER = gql`
  query GetUser($username: String) {
    user(username: $username) {
      _id
      commentBan
      createdAt
    }
  }
`

export const GET_USER_COMMENTS = gql`
  query GetUserComments($id: String!, $limit: Float, $offset: Float) {
    commentPageLimit @client @export(as: "limit")
    getUserComments(id: $id, limit: $limit, offset: $offset) {
      ...CommentListData
    }
  }
  ${CommentList.Fragment}
`

export const TOGGLE_USER_COMMENT_BAN = gql`
  mutation ToggleUserCommentBan($id: String!) {
    toggleUserCommentBan(id: $id) {
      _id
      commentBan
    }
  }
`
