import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/react-hooks'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography, Snackbar, TextField, CircularProgress } from '@material-ui/core'
import { Video } from '../../generated/graphql'
import { useUpdateVideo } from '../../lib/graphql/video'
import { useInterval } from '../../lib/utils'
import { CopyButton } from '../CopyButton'
import { VideoPlayer } from '../VideoPlayer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    textField: {
      marginBottom: theme.spacing(4),
    },
    waitingRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(1),
    },
    waitingProgress: {
      marginRight: theme.spacing(2),
    },
    playerWrapper: {
      marginBottom: theme.spacing(3),
    },
  })
)

enum LiveStatus {
  broadcastWaiting = 'BROADCAST_WAITING',
  dvrWaiting = 'DVR_WAITING',
  live = 'LIVE',
  done = 'DONE',
}

export const LiveInfo = (props: Video) => {
  const client = useApolloClient()
  const theme = useTheme()
  const classes = useStyles(theme)
  const [pollingLiveStatus, setPollingLiveStatus] = useState(true)
  const updateVideo = useUpdateVideo(props._id)
  const [liveStatusIntervalOpen, setLiveStatusIntervalOpen] = useState(true)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [liveStatus, setLiveStatus] = useState(LiveStatus.broadcastWaiting)
  const handleSnackbarClose = () => setSnackbarOpen(false)
  const embedCode = `<div class="ifw-player" data-video-id="${props._id}"></div>\n<script src="https://www.infowarsmedia.com/js/player.js" async></script>`
  const copyItem = (text: string) => () =>
    navigator.clipboard.writeText(text).then(() => {
      setSnackbarOpen(true)
      setTimeout(handleSnackbarClose, 3000)
    })
  const publishVideo = () => updateVideo({ published: true }, true)
  const unLiveVideo = () => updateVideo({ live: false })

  useInterval(
    async () => {
      const { data } = await client.query({
        query: GET_LIVE_VIDEO_STATUS,
        variables: { id: props._id },
        fetchPolicy: 'network-only',
      })
      if (liveStatusIntervalOpen) {
        if (data && data.getLiveVideoStatus) {
          if (props.published) {
            setLiveStatus(LiveStatus.live)
            setLiveStatusIntervalOpen(false)
          } else {
            setLiveStatus(LiveStatus.dvrWaiting)
            await publishVideo()
            setPollingLiveStatus(false)
            setTimeout(() => {
              setLiveStatusIntervalOpen(false)
              setPollingLiveStatus(true)
              setLiveStatus(LiveStatus.live)
            }, 5000)
          }
        }
      } else {
        if (data && !data.getLiveVideoStatus) {
          setLiveStatus(LiveStatus.done)
          await unLiveVideo()
          setPollingLiveStatus(false)
        }
      }
    },
    pollingLiveStatus ? 5000 : null
  )

  const getLiveStatusItem = () => {
    switch (liveStatus) {
      case LiveStatus.broadcastWaiting:
        return (
          <div className={classes.waitingRow}>
            <CircularProgress color="secondary" size="20px" className={classes.waitingProgress} />
            <Typography variant="h6" component="h4">
              Waiting for broadcast...
            </Typography>
          </div>
        )
      case LiveStatus.dvrWaiting:
        return (
          <div className={classes.waitingRow}>
            <CircularProgress color="secondary" size="20px" className={classes.waitingProgress} />
            <Typography variant="h6" component="h4">
              Broadcast received. Waiting for DVR...
            </Typography>
          </div>
        )
      case LiveStatus.live:
        return (
          <Typography variant="h6" component="h4">
            DVR started. Video published. Begin the broadcast.
          </Typography>
        )
      case LiveStatus.done:
        return (
          <Typography variant="h6" component="h4">
            Broadcast concluded.
          </Typography>
        )
      default:
        return null
    }
  }

  return (
    <div>
      {liveStatus === LiveStatus.live ? (
        <div className={classes.playerWrapper}>
          <VideoPlayer {...props} />
        </div>
      ) : null}
      {props.liveInfo ? (
        <>
          <Typography variant="h4" component="h3" className={classes.title}>
            Settings for OBS
          </Typography>
          <TextField
            label="URL"
            type="text"
            value={props.liveInfo!.ingestURL}
            fullWidth
            variant="outlined"
            contentEditable={false}
            className={classes.textField}
            InputProps={{
              endAdornment: <CopyButton onClick={copyItem(props.liveInfo!.ingestURL)} />,
            }}
          />
          <TextField
            label="Stream Key"
            type="text"
            value={props.liveInfo!.streamKey}
            fullWidth
            variant="outlined"
            contentEditable={false}
            className={classes.textField}
            InputProps={{
              endAdornment: <CopyButton onClick={copyItem(props.liveInfo!.streamKey)} />,
            }}
          />
          <TextField
            label="Username"
            type="text"
            value={props.liveInfo!.ingestUser}
            fullWidth
            variant="outlined"
            contentEditable={false}
            className={classes.textField}
            InputProps={{
              endAdornment: <CopyButton onClick={copyItem(props.liveInfo!.ingestUser)} />,
            }}
          />
          <TextField
            label="Password"
            type="text"
            value={props.liveInfo!.ingestPassword}
            fullWidth
            variant="outlined"
            contentEditable={false}
            className={classes.textField}
            InputProps={{
              endAdornment: <CopyButton onClick={copyItem(props.liveInfo!.ingestPassword)} />,
            }}
          />
          <Typography component="p" gutterBottom>
            Provide the following embed code to the writers. Only start streaming in OBS when you
            are ready to start and your pre-roll is running.
          </Typography>
          <TextField
            multiline
            rows="2"
            value={embedCode}
            className={classes.textField}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: <CopyButton onClick={copyItem(embedCode)} />,
            }}
          />
          {getLiveStatusItem()}
        </>
      ) : (
        <Typography variant="h6" component="h4">
          Broadcast ended.
        </Typography>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message="Copied!"
      />
    </div>
  )
}

export const GET_LIVE_VIDEO_STATUS = gql`
  query GetLiveVideoStatus($id: String!) {
    getLiveVideoStatus(id: $id)
  }
`

LiveInfo.Fragment = gql`
  fragment LiveInfoData on Video {
    _id
    published
    liveInfo {
      ingestURL
      streamKey
      ingestUser
      ingestPassword
    }
    ...VideoPlayerData
  }
  ${VideoPlayer.Fragment}
`
