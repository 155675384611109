import { AppBar, Paper, Tab, Tabs } from '@material-ui/core'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import LiveTvIcon from '@material-ui/icons/LiveTv'
import MovieIcon from '@material-ui/icons/Movie'
import PhotoIcon from '@material-ui/icons/Photo'
import React from 'react'
import { AudioUpload } from '../components/AudioUpload'
import { ImageUpload } from '../components/ImageUpload'
import { LiveVideoUpload } from '../components/LiveVideoUpload'
import { VideoUpload } from '../components/VideoUpload'
import { useCreateVideo } from '../lib/graphql/video'
import { ThemeModes } from '../lib/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.type === ThemeModes.light ? theme.palette.grey['100'] : '#333',
      paddingBottom: theme.spacing(3),
    },
  })
)

export const Upload = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [value, setValue] = React.useState(0)
  const createVideo = useCreateVideo()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue)

  return (
    <Paper className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          centered
        >
          <Tab label="Video" icon={<MovieIcon />} />
          <Tab label="Live Video" icon={<LiveTvIcon />} />
          <Tab label="Image" icon={<PhotoIcon />} />
          {/* <Tab label="RSS Audio" icon={<AudiotrackIcon />} /> */}
        </Tabs>
      </AppBar>
      <VideoUpload value={value} index={0} createVideo={createVideo} />
      <LiveVideoUpload value={value} index={1} createVideo={createVideo} />
      <ImageUpload value={value} index={2} />
      <AudioUpload value={value} index={3} />
    </Paper>
  )
}
