import React from 'react'
import { makeStyles, createStyles, useTheme, Theme } from '@material-ui/core/styles'
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles'
import { Button, Popover, Typography } from '@material-ui/core'
import { TooltipProps } from '@material-ui/core/Tooltip'
import { TooltipIconButton } from './TooltipIconButton'
import { useDangerTheme } from '../lib/theme'
import { useError } from './ErrorModal'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
  })
)

export enum ConfirmButtonVariants {
  button = 'BUTTON',
  iconButton = 'ICON_BUTTON',
}

interface ConfirmButtonProps {
  variant?: ConfirmButtonVariants
  className?: any
  icon: JSX.Element
  label: string
  confirmText: string
  labelPlacement?: TooltipProps['placement']
  onClick: () => Promise<any>
}

export const ConfirmButton = (props: ConfirmButtonProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { setErrorMessage } = useError()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const isIconButton = props.variant === ConfirmButtonVariants.iconButton

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleConfirmClick() {
    try {
      props.onClick()
      handleClose()
    } catch (e) {
      setErrorMessage(e.message)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <MUIThemeProvider theme={useDangerTheme()}>
      {isIconButton ? (
        <TooltipIconButton
          label={props.label}
          onClick={handleClick}
          placement={props.labelPlacement}
        >
          {props.icon}
        </TooltipIconButton>
      ) : (
        <Button
          variant="contained"
          color="primary"
          className={props.className}
          onClick={handleClick}
        >
          {props.icon}
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Typography className={classes.typography}>{props.confirmText}</Typography>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleClose}>No</Button>
          <Button color="primary" onClick={handleConfirmClick}>
            Yes
          </Button>
        </div>
      </Popover>
    </MUIThemeProvider>
  )
}
