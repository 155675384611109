import React, { useState } from 'react'
import { Theme, createStyles, makeStyles, useTheme, fade } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: theme.spacing(1),
      width: 'auto',
      display: 'flex',
      alignItems: 'center',
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 440,
      },
    },
  })
)

interface SearchInputProps {
  onSubmit: (text: string) => void
}

export const SearchInput = (props: SearchInputProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [value, setValue] = useState('')
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        props.onSubmit(value)
        setValue('')
      }}
    >
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search Videos…"
          value={value}
          onChange={e => setValue(e.target.value)}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
    </form>
  )
}
