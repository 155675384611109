import { useState, useEffect, useRef } from 'react'
import gql from 'graphql-tag'
import { debounce, uniqBy } from 'lodash'
import useReactRouter from 'use-react-router'
import { useMutation } from '@apollo/react-hooks'
import { UpdateVideoInput, VideoInput, GetChannelsPageQuery } from '../../generated/graphql'
import { VIDEO_PAGE_SIZE, LOAD_MORE_DEBOUCE_MS } from '../../consts'
import { GET_CHANNELS_PAGE, GET_CHANNELS_PAGE_VARS } from '../../pages/Channels'
import { GET_CHANNEL_PAGE, GET_CHANNEL_PAGE_VARS } from '../../pages/Channel'
import { UPDATE_VIDEO, REMOVE_VIDEO } from '../../pages/Video'
import { GET_PLAYLISTS_PAGE, GET_PLAYLISTS_PAGE_VARS } from '../../pages/Playlists'

export const useCreateVideo = () => {
  const { history } = useReactRouter()
  const [create] = useMutation(CREATE_VIDEO)
  const createVideo = async (video: VideoInput) => {
    const { data } = await create({
      variables: { video },
      refetchQueries: [{ query: GET_CHANNEL_PAGE, variables: { id: video.channelId } }],
      update(cache, { data: { createVideo } }) {
        try {
          const recentChannelsQuery = cache.readQuery({
            query: GET_CHANNELS_PAGE,
          }) as GetChannelsPageQuery
          if (recentChannelsQuery && recentChannelsQuery.getAdminChannels) {
            cache.writeQuery({
              query: GET_CHANNELS_PAGE,
              data: {
                getAdminChannels: recentChannelsQuery.getAdminChannels.map(channel =>
                  channel._id === video.channelId
                    ? {
                        ...channel,
                        videos: [createVideo, ...channel.videos].slice(0, 4),
                      }
                    : channel
                ),
              },
            })
          }
        } catch (e) {
          console.log('ERROR', e)
        }
      },
    })

    if (data && data.createVideo) {
      history.push(
        `/channels/${data.createVideo.channel._id}/videos/${data.createVideo._id}`,
        video.live && !video.useFeed ? { broadcast: true } : undefined
      )
    }
  }

  return createVideo
}

export const useUpdateVideo = (videoId: string) => {
  const [update] = useMutation(UPDATE_VIDEO)
  const updateVideo = async (updateVideo: UpdateVideoInput, create?: boolean) =>
    update({ variables: { id: videoId, video: updateVideo, create } })

  return updateVideo
}

export const useRemoveVideo = (videoId: string, channelId: string) => {
  const { history } = useReactRouter()
  const [remove] = useMutation(REMOVE_VIDEO)
  const removeVideo = async () => {
    const { data } = await remove({
      variables: { id: videoId },
      refetchQueries: [
        {
          query: GET_PLAYLISTS_PAGE,
          variables: GET_PLAYLISTS_PAGE_VARS,
        },
        {
          query: GET_CHANNELS_PAGE,
          variables: GET_CHANNELS_PAGE_VARS,
        },
        {
          query: GET_CHANNEL_PAGE,
          variables: { ...GET_CHANNEL_PAGE_VARS, id: channelId },
        },
      ],
    })

    if (data.removeVideo) {
      history.goBack()
    }
  }

  return removeVideo
}

export const useLoadMoreVideos = (fetchMoreVideos: any, queryName: string, offsetName: string) => {
  const [hasMoreVideos, setHasMoreVideos] = useState(true)
  const page = useRef(0)
  const fetchMoreVideosFunc = useRef<any>()

  useEffect(() => {
    fetchMoreVideosFunc.current = fetchMoreVideos
    return () => {}
  }, [fetchMoreVideos])

  const loadMoreVideos = debounce(() => {
    if (hasMoreVideos) {
      const newVideoPage = page.current + 1
      page.current = newVideoPage
      fetchMoreVideosFunc.current({
        variables: { [offsetName]: newVideoPage * VIDEO_PAGE_SIZE },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev
          const videos = uniqBy(
            [...prev[queryName].videos, ...fetchMoreResult[queryName].videos],
            '_id'
          )
          if (
            !fetchMoreResult ||
            !fetchMoreResult[queryName] ||
            !fetchMoreResult[queryName].videos ||
            !fetchMoreResult[queryName].videos.length ||
            fetchMoreResult[queryName].videos.length < VIDEO_PAGE_SIZE
          ) {
            setHasMoreVideos(false)
          }
          return Object.assign({}, prev, {
            [queryName]: {
              ...prev[queryName],
              videos,
            },
          })
        },
      })
    }
  }, LOAD_MORE_DEBOUCE_MS)

  const resetLoadMoreVideos = () => {
    page.current = 0
    setHasMoreVideos(true)
  }
  return { loadMoreVideos, resetLoadMoreVideos, hasMoreVideos }
}

export const useLoadMoreChannelVideos = (fetchMoreVideos: any) =>
  useLoadMoreVideos(fetchMoreVideos, 'getAdminChannel', 'channelVideoOffset')
export const useLoadMorePlaylistVideos = (fetchMoreVideos: any) =>
  useLoadMoreVideos(fetchMoreVideos, 'getAdminPlaylist', 'playlistVideoOffset')

export const CREATE_VIDEO = gql`
  mutation CreateVideo($video: VideoInput!) {
    createVideo(video: $video) {
      _id
      title
      summary
      published
      unlisted
      live
      channel {
        _id
        title
      }
      playCount
      largeImage
      directUrl
      createdAt
      publishedAt
      liveStreamUrl
      streamUrl
      embedUrl
    }
  }
`
