import gql from 'graphql-tag'
import React, { useContext } from 'react'
import { useGetVideoCommentsLazyQuery, Video } from '../generated/graphql'
import { CommentList } from './Comments/CommentList'
import { Surface } from './Surface'
import { UserContext } from './UserProvider'

export const VideoComments = (props: Video) => {
  const user = useContext(UserContext)
  return props._id ? (
    <Surface title="Comments" collapsable>
      <CommentList
        query={useGetVideoCommentsLazyQuery}
        queryVars={{ variables: { id: props._id } }}
        queryName="getVideoComments"
        isModerate={
          user.isChannelCollaborator(props.channel ? props.channel._id : '') ||
          user.isModerator ||
          user.isSuperAdmin
        }
      />
    </Surface>
  ) : null
}

export const GET_VIDEO_COMMENTS = gql`
  query GetVideoComments($id: String!, $limit: Float, $offset: Float) {
    commentPageLimit @client @export(as: "limit")
    getVideoComments(id: $id, limit: $limit, offset: $offset) {
      ...CommentListData
    }
  }
  ${CommentList.Fragment}
`
