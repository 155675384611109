import React, { useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { PlaylistInput } from '../generated/graphql'
import { TooltipFab } from './TooltipFab'
import { PlaylistAddForm } from './PlaylistAddForm'

interface PlaylistAddProps {
  onSubmit: (playlist: PlaylistInput) => Promise<any>
}

export const PlaylistAdd = (props: PlaylistAddProps) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  return (
    <>
      <TooltipFab tooltipLabel="Create Playlist" aria-label="create playlist" onClick={toggleOpen}>
        <AddIcon />
      </TooltipFab>
      <PlaylistAddForm open={open} close={() => setOpen(false)} onSubmit={props.onSubmit} />
    </>
  )
}
