import React from 'react'
import gql from 'graphql-tag'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Divider, Chip } from '@material-ui/core'
import { Video } from '../../generated/graphql'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(3, 0),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  })
)

export const VideoTags = (props: Video) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return props.tags && props.tags.length ? (
    <>
      <Divider variant="fullWidth" className={classes.divider} />
      <div>
        {props.tags.map(({ _id, name }) => (
          <Chip key={_id} label={name} className={classes.chip} />
        ))}
      </div>
    </>
  ) : null
}

VideoTags.Fragment = gql`
  fragment VideoTagsData on Video {
    tags {
      _id
      name
    }
  }
`
