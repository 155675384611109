import React from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Table as MUITable, Paper } from '@material-ui/core'
import { Data } from './'
import { TableHead } from './TableHead'
import { TableToolbar } from './TableToolbar'
import { TableBody } from './TableBody'
import { TablePagination } from './TablePagination'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
  })
)

export interface HeadRow<D> {
  disablePadding: boolean
  id: keyof D
  label: string
  alignRight: boolean
}

type Order = 'asc' | 'desc'

interface TableProps<D> {
  label: string
  rows: D[]
  headRows: HeadRow<D>[]
  totalRows?: number
  actions?: Array<{
    tooltip: string
    onClick: (items: string[]) => void
    icon: JSX.Element
  }>
  children: (props: {
    row: D
    handleClick: (event: React.MouseEvent<unknown>, id: string) => void
    isItemSelected: boolean
    labelId: string
  }) => JSX.Element | null
  selectedItems?: string[]
  defaultRowsPerPage?: number
  defaultSort?: keyof Data
  loadMore?: () => void
}

export const Table = (props: TableProps<Data>) => {
  const { headRows, rows, label, actions, totalRows, defaultSort, children } = props
  const theme = useTheme()
  const classes = useStyles(theme)
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<keyof Data>(defaultSort || 'createdAt')
  const [selected, setSelected] = React.useState<string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(props.defaultRowsPerPage || 5)

  function handleRequestSort(event: React.MouseEvent<unknown>, property: keyof Data) {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  function handleClick(event: React.MouseEvent<unknown>, id: string) {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  function handleChangePage(event: unknown, newPage: number) {
    if (
      props.loadMore &&
      totalRows &&
      rows.length < totalRows &&
      (newPage + 1) * rowsPerPage >= rows.length
    ) {
      props.loadMore()
    }
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const toolbarActions = actions
    ? actions.map(a => ({ ...a, onClick: () => a.onClick(selected) }))
    : undefined

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableToolbar
          label={label}
          numSelected={props.selectedItems ? props.selectedItems.length : selected.length}
          actions={toolbarActions}
        />
        <div className={classes.tableWrapper}>
          <MUITable className={classes.table} aria-labelledby="tableTitle" size="medium">
            <TableHead
              headRows={headRows}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody
              rows={rows}
              page={page}
              order={order}
              orderBy={orderBy}
              rowsPerPage={rowsPerPage}
              selected={selected}
              handleClick={handleClick}
            >
              {children}
            </TableBody>
          </MUITable>
        </div>
        <TablePagination
          rows={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          totalRows={totalRows}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
