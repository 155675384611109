import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { DeleteButton, DeleteButtonVariants } from './DeleteButton'
import { UserContext } from './UserProvider'
import { useViewType } from './SwitchViewButton'
import { VideoGrid } from './VideoGrid'
import { VideoTable } from './VideoTable'
import { Surface } from './Surface'
import { ChannelViewProps } from './ChannelView'

export const ChannelFeaturedVideo = (props: ChannelViewProps) => {
  const user = useContext(UserContext)
  const { isGrid } = useViewType()
  return props.featuredVideo ? (
    <Surface
      title="Featured Video"
      collapsable={isGrid}
      actions={
        user.isSuperAdmin || user.isAdmin || user.isChannelCollaborator(props._id) ? (
          isGrid ? (
            <DeleteButton
              variant={DeleteButtonVariants.iconButton}
              onClick={props.removeChannelFeaturedVideo!}
            />
          ) : (
            undefined
          )
        ) : (
          undefined
        )
      }
    >
      {isGrid ? (
        <VideoGrid videos={[props.featuredVideo]} />
      ) : (
        <VideoTable videos={[props.featuredVideo]} label="Featured Video" />
      )}
    </Surface>
  ) : null
}

ChannelFeaturedVideo.Fragment = gql`
  fragment ChannelFeaturedVideoData on Channel {
    _id
    featuredVideo {
      ...VideoCardData
      ...VideoTableData
    }
  }
  ${VideoGrid.Fragment}
  ${VideoTable.Fragment}
`
