import React from 'react'
import { Pages } from '../../types'
import { Playlist } from '../../generated/graphql'
import { PlaylistListItem } from './PlaylistListItem'

interface PlaylistListProps {
  playlists: Playlist[]
  variant?: Pages
}

export const PlaylistList = (props: PlaylistListProps) => {
  const { playlists, variant } = props

  return (
    <>
      {playlists.length
        ? playlists.map(playlist => (
            <PlaylistListItem key={playlist._id} {...playlist} variant={variant} />
          ))
        : null}
    </>
  )
}

PlaylistList.Fragment = PlaylistListItem.Fragment
