import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { Formik } from 'formik'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import { PlaylistInput, Channel, useGetPlaylistAddChannelsLazyQuery } from '../generated/graphql'
import { VideoPicker } from './VideoPicker'
import { useError } from './ErrorModal'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      flex: 1,
      marginTop: theme.spacing(2),
    },
    selectVideosButton: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    selectChannelInput: {
      paddingRight: 15,
    },
    channelRow: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    globalCheck: {
      marginRight: 0,
    },
    loadMoreButton: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  })
)

interface PlaylistAddFormProps {
  open: boolean
  close: () => void
  onSubmit: (playlist: PlaylistInput) => Promise<any>
  channel?: string
  video?: string
}

export const PlaylistAddForm = (props: PlaylistAddFormProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [loadChannels, { data, called }] = useGetPlaylistAddChannelsLazyQuery()
  const { setErrorMessage } = useError()
  const [videoPickerOpen, setVideoPickerOpen] = useState(false)
  const channels = (data && data.getAdminChannels ? data.getAdminChannels : []) as Channel[]

  useEffect(() => {
    if (props.open && !called) {
      loadChannels()
    }
    return () => {}
  }, [props.open, called, loadChannels])

  return (
    <Formik
      initialValues={{
        title: '',
        summary: '',
        channel: props.channel || '',
        videoIds: (props.video ? [props.video] : []) as string[],
        isGlobal: false,
      }}
      validate={values => {
        let errors: any = {}
        if (!values.title) {
          errors.title = 'Required'
        }
        if (!values.channel) {
          errors.channel = 'Required'
        }
        if (!values.videoIds.length) {
          errors.videoIds = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await props.onSubmit(values)
        } catch (e) {
          setErrorMessage(e.message)
        }
        setSubmitting(false)
        props.close()
        resetForm()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        resetForm,
      }) => {
        const close = () => {
          resetForm()
          props.close()
        }
        const toggleIsGlobal = () => {
          setFieldValue('isGlobal', !values.isGlobal)
          setFieldValue('videoIds', [])
        }
        const setChannel = (
          e: React.ChangeEvent<{
            name?: string | undefined
            value: unknown
          }>
        ) => {
          setFieldValue('channel', e.target.value)
          setFieldValue('videoIds', [])
        }
        return (
          <>
            <Dialog
              open={props.open}
              onClose={close}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle id="form-dialog-title">Create Playlist</DialogTitle>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                  />
                  {touched.title && errors.title ? (
                    <FormHelperText error>{errors.title}</FormHelperText>
                  ) : null}
                  <TextField
                    fullWidth
                    id="summary"
                    label="Summary (Optional)"
                    name="summary"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.summary}
                  />
                  {touched.summary && errors.summary ? (
                    <FormHelperText error>{errors.summary}</FormHelperText>
                  ) : null}
                  <FormGroup row className={classes.channelRow}>
                    <FormControl
                      className={clsx(classes.formControl, classes.selectChannelInput)}
                      disabled={values.isGlobal}
                    >
                      <InputLabel htmlFor="channel">Channel</InputLabel>
                      <Select
                        value={values.channel}
                        onChange={setChannel}
                        inputProps={{ name: 'channel', id: 'channel' }}
                      >
                        {channels.map(({ _id, title }) => (
                          <MenuItem key={_id} value={_id}>
                            {title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControlLabel
                      label="Global"
                      className={classes.globalCheck}
                      control={<Checkbox checked={values.isGlobal} onChange={toggleIsGlobal} />}
                    />
                  </FormGroup>
                  {touched.channel && errors.channel ? (
                    <FormHelperText error>{errors.channel}</FormHelperText>
                  ) : null}
                  <Button
                    variant="contained"
                    className={classes.selectVideosButton}
                    disabled={!values.channel}
                    onClick={() => setVideoPickerOpen(true)}
                  >
                    Select Videos {values.videoIds.length ? `(${values.videoIds.length})` : ''}
                  </Button>
                  {touched.videoIds && errors.videoIds ? (
                    <FormHelperText error>{errors.videoIds}</FormHelperText>
                  ) : null}
                </DialogContent>
                <DialogActions>
                  <Button onClick={close} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button type="submit" color="secondary" disabled={isSubmitting}>
                    Submit
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            {values.isGlobal ? (
              <VideoPicker.MultiChannel
                open={videoPickerOpen}
                close={() => setVideoPickerOpen(false)}
                selectedItems={values.videoIds}
                onSelect={(_id: string) => {
                  if (values.videoIds.indexOf(_id) === -1) {
                    setFieldValue('videoIds', values.videoIds.concat([_id]))
                  } else {
                    setFieldValue('videoIds', values.videoIds.filter(id => id !== _id))
                  }
                }}
              />
            ) : (
              <VideoPicker
                open={videoPickerOpen}
                close={() => setVideoPickerOpen(false)}
                channelId={values.channel}
                selectedItems={values.videoIds}
                onSelect={(_id: string) => {
                  if (values.videoIds.indexOf(_id) === -1) {
                    setFieldValue('videoIds', values.videoIds.concat([_id]))
                  } else {
                    setFieldValue('videoIds', values.videoIds.filter(id => id !== _id))
                  }
                }}
              />
            )}
          </>
        )
      }}
    </Formik>
  )
}

export const GET_PLAYLIST_ADD_CHANNELS = gql`
  query GetPlaylistAddChannels {
    getAdminChannels {
      _id
      title
    }
  }
`
