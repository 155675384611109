import React from 'react'
import gql from 'graphql-tag'
import InfiniteScroll from 'react-infinite-scroller'
import { useGetAdminActionsPageQuery } from '../generated/graphql'
import { usePagableQuery } from '../lib/pagable'
import { Loader } from '../components/Loader'
import { PageLayout } from '../components/PageLayout'
import { AdminActionList } from '../components/AdminActionList'

export const AdminActions = () => {
  const { items, hasMore, loadMore } = usePagableQuery({
    query: useGetAdminActionsPageQuery,
    queryVars: {},
    queryName: 'getAdminActions',
    limit: 20,
  })
  return (
    <PageLayout loading={false} noViewSwitch>
      <InfiniteScroll
        useWindow={true}
        pageStart={0}
        threshold={50}
        loader={<Loader key={0} />}
        hasMore={hasMore}
        loadMore={loadMore}
      >
        <AdminActionList adminActions={items} />
      </InfiniteScroll>
    </PageLayout>
  )
}

export const GET_ADMIN_ACTIONS_PAGE = gql`
  query GetAdminActionsPage($limit: Float, $offset: Float) {
    getAdminActions(limit: $limit, offset: $offset) {
      _id
      type
      title
      adminUser {
        _id
        email
      }
      createdAt
    }
  }
`
