import EditIcon from '@material-ui/icons/Edit'
import { loadCSS } from 'fg-loadcss'
import gql from 'graphql-tag'
import React, { useContext, useEffect } from 'react'
import { ChannelLinkIcon, ChannelLinkIconVariant } from './ChannelLinkIcon'
import { ChannelViewProps } from './ChannelView'
import { DeleteButton, DeleteButtonVariants } from './DeleteButton'
import { Surface } from './Surface'
import { TooltipIconButton } from './TooltipIconButton'
import { UserContext } from './UserProvider'

export const ChannelInfo = (props: ChannelViewProps) => {
  const user = useContext(UserContext)
  const channelLink = `${process.env.REACT_APP_VIDEO_HOST_URL}/channel/${escape(
    props.title
      .toLowerCase()
      .split(' ')
      .join('-')
  )}`

  useEffect(() => {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
      document.querySelector('#font-awesome-css')
    )
  }, [])

  return (
    <Surface
      title={props.title}
      subtitle={props.summary}
      noDivider
      actions={
        user.isSuperAdmin || user.isAdmin || user.isChannelCollaborator(props._id) ? (
          <div>
            <TooltipIconButton label="Edit" onClick={props.toggleEdit}>
              <EditIcon />
            </TooltipIconButton>
            {user.isSuperAdmin ? (
              <DeleteButton
                variant={DeleteButtonVariants.iconButton}
                onClick={props.removeChannel!}
              />
            ) : null}
          </div>
        ) : (
          undefined
        )
      }
    >
      <div style={{ marginTop: 20 }}>
        <a href={channelLink} target="_blank" rel="noopener noreferrer">
          {channelLink}
        </a>
      </div>
      {props.links ? (
        <div style={{ marginTop: 20 }}>
          {props.links.website ? (
            <ChannelLinkIcon link={props.links.website} variant={ChannelLinkIconVariant.website} />
          ) : null}
          {props.links.facebook ? (
            <ChannelLinkIcon
              link={props.links.facebook}
              variant={ChannelLinkIconVariant.facebook}
            />
          ) : null}
          {props.links.twitter ? (
            <ChannelLinkIcon link={props.links.twitter} variant={ChannelLinkIconVariant.twitter} />
          ) : null}
          {props.links.gab ? (
            <ChannelLinkIcon link={props.links.gab} variant={ChannelLinkIconVariant.gab} />
          ) : null}
          {props.links.minds ? (
            <ChannelLinkIcon link={props.links.minds} variant={ChannelLinkIconVariant.minds} />
          ) : null}
          {props.links.subscribeStar ? (
            <ChannelLinkIcon
              link={props.links.subscribeStar}
              variant={ChannelLinkIconVariant.subscribeStar}
            />
          ) : null}
          {props.links.telegram ? (
            <ChannelLinkIcon
              link={props.links.telegram}
              variant={ChannelLinkIconVariant.telegram}
            />
          ) : null}
        </div>
      ) : null}
    </Surface>
  )
}

ChannelInfo.Fragment = gql`
  fragment ChannelInfoData on Channel {
    _id
    title
    summary
    links {
      website
      facebook
      telegram
      twitter
      gab
      minds
    }
  }
`
