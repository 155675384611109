import React from 'react'
import gql from 'graphql-tag'
import { Pages } from '../types'
import { Channel } from '../generated/graphql'
import { ChannelInfo } from './ChannelInfo'
import { ChannelLiveStreamVideo } from './ChannelLiveStreamVideo'
import { ChannelFeaturedVideo } from './ChannelFeaturedVideo'
import { ChannelPlaylists } from './ChannelPlaylists'
import { ChannelVideos } from './ChannelVideos'

export interface ChannelViewProps extends Channel {
  variant?: Pages
  toggleEdit?: () => void
  removeChannel?: () => Promise<any>
  removeChannelFeaturedVideo?: () => Promise<any>
  removeChannelLiveStreamVideo?: () => Promise<any>
  onCreatePlaylist: (videoIds?: string[]) => void
  updateChannelPlaylistsOrder: (order: string[]) => Promise<any>
  loadMoreVideos?: () => void
  hasMoreVideos: boolean
}

export const ChannelView = (props: ChannelViewProps) => (
  <div>
    <ChannelInfo {...props} />
    <ChannelLiveStreamVideo {...props} />
    <ChannelFeaturedVideo {...props} />
    <ChannelPlaylists {...props} />
    <ChannelVideos {...props} />
  </div>
)

ChannelView.Fragment = gql`
  fragment ChannelViewData on Channel {
    _id
    ...ChannelInfoData
    ...ChannelLiveStreamVideoData
    ...ChannelFeaturedVideoData
    ...ChannelPlaylistsData
    ...ChannelVideosData
  }
  ${ChannelInfo.Fragment}
  ${ChannelLiveStreamVideo.Fragment}
  ${ChannelFeaturedVideo.Fragment}
  ${ChannelPlaylists.Fragment}
  ${ChannelVideos.Fragment}
`
