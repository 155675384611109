import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Paper, Typography, Divider, Button, IconButton, Collapse } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ThemeModes } from '../lib/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.type === ThemeModes.light ? theme.palette.grey['100'] : '#333',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    divider: {
      margin: theme.spacing(3, 0),
    },
    button: {
      marginLeft: theme.spacing(2),
    },
    disabled: {
      opacity: 0.4,
    },
  })
)

interface SurfaceProps {
  children?: JSX.Element | null | Array<JSX.Element | null>
  title?: string
  titleLink?: string
  subtitle?: string
  subtitleLink?: string
  noDivider?: boolean
  noBody?: boolean
  actions?: JSX.Element
  collapsable?: boolean
  disabled?: boolean
}

const SurfaceSubtitle = (props: SurfaceProps) =>
  props.subtitleLink ? (
    <Link to={props.subtitleLink}>
      <Typography component="p">{props.subtitle}</Typography>
    </Link>
  ) : (
    <Typography component="p">{props.subtitle}</Typography>
  )

const SurfaceTitle = (props: SurfaceProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return props.titleLink ? (
    <Link to={props.titleLink}>
      <Typography variant="h4" component="h3" className={classes.title}>
        {props.title}
      </Typography>
    </Link>
  ) : (
    <Typography variant="h4" component="h3" className={classes.title}>
      {props.title}
    </Typography>
  )
}

const SurfaceHeader = (props: SurfaceProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return props.title ? (
    <>
      <div className={classes.header}>
        <SurfaceTitle {...props} />
        <div>
          {props.actions ? props.actions : null}
          {props.titleLink ? (
            <Link to={props.titleLink}>
              <Button className={classes.button}>View More</Button>
            </Link>
          ) : null}
        </div>
      </div>
      {props.subtitle ? <SurfaceSubtitle {...props} /> : null}
      {props.noDivider ? null : <Divider variant="fullWidth" className={classes.divider} />}
    </>
  ) : null
}

export const Surface = (props: SurfaceProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [isExpanded, setIsExpanded] = useState(true)
  const actions = props.collapsable ? (
    <>
      {props.actions}
      <IconButton onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </IconButton>
    </>
  ) : (
    props.actions
  )
  return (
    <Paper
      className={clsx(classes.root, {
        [classes.disabled]: props.disabled,
      })}
      elevation={theme.palette.type === ThemeModes.light ? 0 : 1}
    >
      <Collapse in={!props.noBody && isExpanded} collapsedHeight="48px">
        <SurfaceHeader {...props} actions={actions} />
        {props.children}
      </Collapse>
    </Paper>
  )
}
