import { Card, CardActionArea, CardContent, Typography } from '@material-ui/core'
import { createStyles, emphasize, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import gql from 'graphql-tag'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Playlist } from '../generated/graphql'
import { GridVariants } from '../types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardAction: {
      position: 'relative',
    },
    title: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      minHeight: 64,
    },
    titleWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    metaWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    selected: {
      backgroundColor: emphasize(theme.palette.background.paper, 0.15),
    },
  })
)

interface PlaylistCardProps extends Playlist {
  variant?: GridVariants
  selectedItems?: string[]
  onSelect?: (id: string) => void
}

export const PlaylistCard = (props: PlaylistCardProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const isSelectList = props.variant === GridVariants.selectList
  const isSelected = props.selectedItems && props.selectedItems.indexOf(props._id) !== -1
  const item = (
    <CardActionArea className={classes.cardAction}>
      <CardContent>
        <div className={classes.titleWrapper}>
          <Typography variant="h6" component="h4" className={classes.title}>
            {props.title}
          </Typography>
        </div>
        <Typography variant="subtitle1" component="h4">
          {!props.isGlobal && props.channel ? props.channel.title : 'Global'}
        </Typography>
        <div className={classes.metaWrapper}>
          {/* <Typography variant="subtitle2" component="p">
            {props.totalVideoViews.toLocaleString()} Views
          </Typography> */}
          <Typography variant="subtitle2" component="p">
            {moment(props.createdAt).format('MM/DD/YYYY')}
          </Typography>
        </div>
      </CardContent>
      <div />
    </CardActionArea>
  )
  return (
    <Card
      raised={isSelected}
      className={isSelected ? classes.selected : undefined}
      onClick={isSelectList ? () => props.onSelect!(props._id) : undefined}
    >
      {isSelectList ? item : <Link to={`/playlists/${props._id}`}>{item}</Link>}
    </Card>
  )
}

PlaylistCard.Fragment = gql`
  fragment PlaylistCardData on Playlist {
    _id
    title
    isGlobal
    channel {
      _id
      title
    }
    totalVideoViews
    createdAt
  }
`
