import React, { useState, useContext } from 'react'
import gql from 'graphql-tag'
import useReactRouter from 'use-react-router'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'
import { Video, UpdateVideoInput } from '../../generated/graphql'
import { UserContext } from '../UserProvider'
import { Surface } from '../Surface'
import { VideoPlayer } from '../VideoPlayer'
import { VideoActions } from './VideoActions'
import { VideoInfo } from './VideoInfo'
import { VideoEdit } from './VideoEdit'
import { VideoTags } from './VideoTags'
import { LiveInfo } from './LiveInfo'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    divider: {
      margin: theme.spacing(3, 0),
    },
  })
)

export interface VideoViewProps extends Video {
  togglePublished: () => void
  toggleUnlisted: () => void
  removeVideo: () => Promise<any>
  editVideo: (video: UpdateVideoInput) => Promise<void>
  updateVideoLoading: boolean
  updateVideoUnlistLoading: boolean
}

export const VideoView = (props: VideoViewProps) => {
  const user = useContext(UserContext)
  const { location } = useReactRouter()
  const theme = useTheme()
  const classes = useStyles(theme)
  const [editOpen, setEditOpen] = useState(false)
  const toggleEdit = () => setEditOpen(!editOpen)
  const isBroadcast = location.state && location.state.broadcast
  return (
    <Surface>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ width: '100%', maxWidth: 1200 }}>
          {isBroadcast ? (
            <LiveInfo {...props} />
          ) : (
            <>
              <VideoPlayer {...props} />
              <VideoInfo {...props} />
              <VideoTags {...props} />
              <Divider variant="fullWidth" className={classes.divider} />
              {user.isSuperAdmin ||
              user.isAdmin ||
              user.isChannelCollaborator(props.channel._id) ? (
                <VideoActions {...props} toggleEdit={toggleEdit} />
              ) : null}
            </>
          )}
        </div>
      </div>
      {user.isSuperAdmin || user.isAdmin || user.isChannelCollaborator(props.channel._id) ? (
        <VideoEdit {...props} open={editOpen} close={toggleEdit} />
      ) : null}
    </Surface>
  )
}

VideoView.Fragment = gql`
  fragment VideoViewData on Video {
    _id
    channel {
      _id
    }
    ...LiveInfoData
    ...VideoPlayerData
    ...VideoInfoData
    ...VideoTagsData
    ...VideoActions
    ...VideoEditData
  }
  ${LiveInfo.Fragment}
  ${VideoPlayer.Fragment}
  ${VideoInfo.Fragment}
  ${VideoTags.Fragment}
  ${VideoActions.Fragment}
  ${VideoEdit.Fragment}
`
