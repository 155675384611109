import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { orderBy } from 'lodash'
import { Pages } from '../types'
import { Playlist, useGetPlaylistsPageQuery } from '../generated/graphql'
import { useCreatePlaylist } from '../lib/graphql/playlist'
import { UserContext } from '../components/UserProvider'
import { Surface } from '../components/Surface'
import { PageLayout } from '../components/PageLayout'
import { PlaylistList } from '../components/PlaylistList'
import { PlaylistTable } from '../components/PlaylistTable'
import { PlaylistAdd } from '../components/PlaylistAdd'
import { useViewType } from '../components/SwitchViewButton'

export const Playlists = () => {
  const user = useContext(UserContext)
  const { data, loading } = useGetPlaylistsPageQuery({
    variables: GET_PLAYLISTS_PAGE_VARS,
  })
  const playlists = (data && data.getAdminPlaylists ? data.getAdminPlaylists : []) as Playlist[]
  const { isGrid } = useViewType()
  const createPlaylist = useCreatePlaylist()
  const displayPlaylists = orderBy(playlists, 'title', 'asc')
  return (
    <PageLayout loading={loading}>
      <>
        {isGrid ? (
          <PlaylistList playlists={displayPlaylists} variant={Pages.playlists} />
        ) : (
          <Surface>
            <PlaylistTable
              playlists={displayPlaylists}
              label="All Playlists"
              variant={Pages.playlists}
            />
          </Surface>
        )}
        {user.isSuperAdmin || user.isAdmin || user.isUploader ? (
          <PlaylistAdd onSubmit={createPlaylist} />
        ) : null}
      </>
    </PageLayout>
  )
}

export const GET_PLAYLISTS_PAGE = gql`
  query GetPlaylistsPage(
    $limit: Float
    $offset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    getAdminPlaylists(limit: $limit, offset: $offset) {
      ...PlaylistListItemData
      ...PlaylistTableData
    }
  }
  ${PlaylistList.Fragment}
  ${PlaylistTable.Fragment}
`

export const GET_PLAYLISTS_PAGE_VARS = { limit: 50, playlistVideoLimit: 4 }

export const CREATE_PLAYLIST = gql`
  mutation CreatePlaylist(
    $playlist: PlaylistInput!
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    videoPageLimit @client @export(as: "limit")
    createPlaylist(playlist: $playlist) {
      ...PlaylistListItemData
      ...PlaylistTableData
    }
  }
  ${PlaylistList.Fragment}
  ${PlaylistTable.Fragment}
`
