import gql from 'graphql-tag'
import React, { useContext, useState } from 'react'
import { PageLayout } from '../components/PageLayout'
import { SaleHeaderToggle } from '../components/SaleHeaderToggle'
import { SalesTextAdd } from '../components/SalesTextAdd'
import { SalesTextList } from '../components/SalesTextList'
import { SalesTextUpdate } from '../components/SalesTextUpdate'
import { UserContext } from '../components/UserProvider'
import {
  useCreateSaleMutation,
  useGetSalesQuery,
  useRemoveSaleMutation,
  useResetAllSaleClicksMutation,
  useResetSaleClicksMutation,
  useToggleHiddenMutation,
} from '../generated/graphql'

export const Sales = () => {
  const user = useContext(UserContext)
  const { data, loading, refetch } = useGetSalesQuery()
  const [updateSaleModelActive, setUpdateSaleModelActive] = useState(false)
  const [activeSale, setActiveSale] = useState<any>()
  const [create] = useCreateSaleMutation()
  const [remove] = useRemoveSaleMutation()
  const [toggleHidden] = useToggleHiddenMutation()
  const [resetClicks] = useResetSaleClicksMutation()
  const [resetAllClicks] = useResetAllSaleClicksMutation()
  const sales = data && data.getSales ? data.getSales : []
  const createSale = (args: {
    text: string
    videoUploadId: string
    description: string
    url: string
    textSale: string
  }) => create({ variables: args })
  const removeSale = async (id: string) => {
    await remove({ variables: { id } })
    refetch()
  }
  const toggleHiddenAd = async (id: string, isChecked: boolean) => {
    await toggleHidden({ variables: { id, isChecked } })
    refetch()
  }
  const resetSaleClicks = (id: string) => resetClicks({ variables: { id } })
  const resetAllSaleClicks = () => resetAllClicks()
  const toggleUpdateSale = async (id: string) => {
    const activeSale = await sales.filter(sale => sale._id === id)[0]
    setActiveSale(activeSale)
    setUpdateSaleModelActive(true)
  }
  return (
    <PageLayout loading={loading} noViewSwitch>
      <SalesTextList
        sales={sales}
        toggleHidden={toggleHiddenAd}
        removeSalesText={removeSale}
        resetSalesTextClicks={resetSaleClicks}
        resetAllSalesTextClicks={resetAllSaleClicks}
        toggleUpdateSale={toggleUpdateSale}
      />
      {user.isSuperAdmin || user.isAdmin ? <SaleHeaderToggle /> : null}
      <SalesTextAdd
        onSubmit={async values => {
          await createSale(values)
          refetch()
        }}
      />
      {updateSaleModelActive ? <SalesTextUpdate {...activeSale} /> : null}
    </PageLayout>
  )
}

export const GET_SALES = gql`
  query GetSales {
    getSales {
      ...SalesTextListData
    }
  }
  ${SalesTextList.Fragment}
`

export const CREATE_SALE = gql`
  mutation CreateSale(
    $text: String!
    $description: String!
    $url: String!
    $videoUploadId: String!
    $textSale: String!
  ) {
    createSale(
      text: $text
      description: $description
      url: $url
      videoUploadId: $videoUploadId
      textSale: $textSale
    ) {
      ...SalesTextListData
    }
  }
  ${SalesTextList.Fragment}
`
export const UPDATE_SALE = gql`
  mutation UpdateSale(
    $id: String!
    $text: String!
    $description: String!
    $url: String!
    $textSale: String!
  ) {
    updateSale(id: $id, text: $text, description: $description, url: $url, textSale: $textSale) {
      ...SalesTextListData
    }
  }
  ${SalesTextList.Fragment}
`
export const REMOVE_SALE = gql`
  mutation RemoveSale($id: String!) {
    removeSale(id: $id)
  }
`
export const TOGGLE_HIDDEN = gql`
  mutation ToggleHidden($id: String!, $isChecked: Boolean!) {
    toggleHidden(id: $id, isChecked: $isChecked)
  }
`
export const RESET_SALE_CLICKS = gql`
  mutation ResetSaleClicks($id: String!) {
    resetSaleClicks(id: $id) {
      ...SalesTextListData
    }
  }
  ${SalesTextList.Fragment}
`

export const RESET_ALL_SALE_CLICKS = gql`
  mutation ResetAllSaleClicks {
    resetAllSaleClicks {
      ...SalesTextListData
    }
  }
  ${SalesTextList.Fragment}
`
