import axios from 'axios'

interface UploadFileArgs {
  file: File
  toggleLoading: () => void
  onProgress: (percent: number) => void
  onComplete: (res: any) => void
  onError: (e: Error) => void
}

export const uploadFile = async (args: UploadFileArgs) => {
  const { file, toggleLoading, onProgress, onComplete, onError } = args
  try {
    const token = localStorage.getItem('token')
    const fileMimeType = file.type
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/upload`,
      {
        name: file.name,
        mimetype: fileMimeType,
        size: file.size,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    if (!response.data) throw new Error('Failed to upload file')
    const { data } = response
    const { id, url, key, bucket } = data
    toggleLoading()

    const headers = {}
    const { searchParams } = new URL(url)
    const params = Object.fromEntries(searchParams)
    for (const key in params) {
      headers[key] = params[key]
    }

    const fileUpload = await axios.put(url, file, {
      headers: {
        'Content-Type': fileMimeType,
        ...headers,
      },
      onUploadProgress: e => {
        const percentCompleted = Math.floor((e.loaded * 100) / e.total)
        onProgress(percentCompleted)
      },
    })
    const fileUploadEtag = fileUpload.headers.etag.replace(/"/g, '')

    onComplete(response.data)

    const completeUploadQueryStr = `?bucket=${bucket}&key=${key}&etag=${fileUploadEtag}`
    const completeUpload = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/upload/${id}/process${completeUploadQueryStr}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    console.warn('Upload to Bucket complete!', completeUpload.status, completeUpload.statusText)
  } catch (e) {
    onError(e)
  }
}

export const getImageUpload = async (uploadId: string) => {
  const token = localStorage.getItem('token')
  const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/upload/${uploadId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  const { data } = response
  return `${process.env.REACT_APP_IMAGE_URL}/images/${data.id}`
}
