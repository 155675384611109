import React, { useEffect, Dispatch, SetStateAction } from 'react'
import gql from 'graphql-tag'
import InfiniteScroll from 'react-infinite-scroller'
import { Video, Channel, useGetVideoPickerVideosLazyQuery } from '../../generated/graphql'
import { useLoadMoreChannelVideos } from '../../lib/graphql/video'
import { VIDEO_PAGE_SIZE } from '../../consts'
import { GridVariants } from '../../types'
import { useViewType } from '../SwitchViewButton'
import { PageLayout } from '../PageLayout'
import { VideoGrid } from '../VideoGrid'
import { VideoTable } from '../VideoTable'
import { Loader } from '../Loader'
import { VideoPickerProps } from './'

interface VideoPickerVideosProps extends VideoPickerProps {
  channelId: string
  channels?: Channel[]
  setCurrentChannel?: Dispatch<SetStateAction<string>>
  includeLive: boolean
  includeUnpublished: boolean
  includeUnlisted: boolean
  liveOnly?: boolean
}

export const VideoPickerVideos = (props: VideoPickerVideosProps) => {
  const { isGrid } = useViewType()
  const [loadGetVideoPickerVideos, { data, loading, fetchMore }] = useGetVideoPickerVideosLazyQuery(
    {
      fetchPolicy: 'cache-and-network',
    }
  )
  const { loadMoreVideos, resetLoadMoreVideos, hasMoreVideos } = useLoadMoreChannelVideos(fetchMore)

  useEffect(() => {
    if (props.open && !!props.channelId) {
      resetLoadMoreVideos()
      loadGetVideoPickerVideos({
        variables: {
          id: props.channelId,
          limit: 0,
          includeLive: props.includeLive,
          includeUnpublished: props.includeUnpublished,
          includeUnlisted: props.includeUnlisted,
        },
      })
    }
    return () => {}
  }, [
    props.open,
    props.channelId,
    props.includeLive,
    props.includeUnpublished,
    props.includeUnlisted,
  ])

  const channel =
    data && data.getAdminChannel && props.channelId === data.getAdminChannel._id
      ? data.getAdminChannel
      : null

  const videos = (channel ? channel.videos : []) as Video[]

  const hasMore = !!(
    videos &&
    videos.length &&
    !!props.channelId &&
    hasMoreVideos &&
    videos.length >= VIDEO_PAGE_SIZE
  )

  const videosToDisplay = props.liveOnly ? videos.filter(({ live }) => !!live) : videos

  return loading && !videos.length ? (
    <Loader />
  ) : (
    <>
      {isGrid ? (
        <InfiniteScroll
          style={{ flex: 1 }}
          useWindow={false}
          pageStart={0}
          threshold={50}
          loader={<Loader key={0} />}
          hasMore={hasMore}
          loadMore={loadMoreVideos}
        >
          <PageLayout loading={false} noViewSwitch>
            <VideoGrid
              videos={videosToDisplay}
              variant={GridVariants.selectList}
              selectedItems={props.selectedItems}
              onSelect={props.onSelect}
            />
          </PageLayout>
        </InfiniteScroll>
      ) : (
        <PageLayout loading={loading && !videosToDisplay.length}>
          <VideoTable
            videos={videosToDisplay}
            label="Featured Video"
            selectedItems={props.selectedItems}
            onSelect={props.onSelect}
            loadMore={loadMoreVideos}
            totalVideos={channel ? channel.totalVideos : undefined}
            defaultRowsPerPage={10}
            noLink
          />
        </PageLayout>
      )}
    </>
  )
}

export const GET_VIDEO_PICKER_VIDEOS = gql`
  query GetVideoPickerVideos(
    $id: String!
    $limit: Float
    $offset: Float
    $includeLive: Boolean
    $includeUnpublished: Boolean
    $includeUnlisted: Boolean
  ) {
    videoPageLimit @client @export(as: "limit")
    getAdminChannel(id: $id) {
      _id
      totalVideos
      videos(
        limit: $limit
        offset: $offset
        includeLive: $includeLive
        includeUnpublished: $includeUnpublished
        includeUnlisted: $includeUnlisted
      ) {
        _id
        live
        ...VideoCardData
        ...VideoTableData
      }
    }
  }
  ${VideoGrid.Fragment}
  ${VideoTable.Fragment}
`
