import React, { useState } from 'react'
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles'
import theme, { ThemeContext, ThemeModes } from '../lib/theme'

export const ThemeProvider: React.FC = props => {
  const [themeMode, setThemeMode] = useState(ThemeModes.dark)
  const toggleThemeMode = () =>
    setThemeMode(themeMode === ThemeModes.light ? ThemeModes.dark : ThemeModes.light)
  return (
    <ThemeContext.Provider value={{ mode: themeMode, toggleMode: toggleThemeMode }}>
      <MUIThemeProvider theme={theme(themeMode)}>{props.children}</MUIThemeProvider>
    </ThemeContext.Provider>
  )
}
