import React from 'react'
import gql from 'graphql-tag'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { List, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core'
import DragIcon from '@material-ui/icons/DragHandle'
import DeleteIcon from '@material-ui/icons/Delete'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { Channel, Playlist } from '../generated/graphql'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    thumbnail: {
      width: 70,
      borderRadius: 4,
      marginRight: 15,
    },
  })
)

interface DraggableChannelAndPlaylistListProps {
  items: Array<Channel | Playlist>
  onDragEnd: (result: DropResult) => void
  onRemove?: (id: string) => void
  noIcons?: boolean
}

export const DraggableChannelAndPlaylistList = (props: DraggableChannelAndPlaylistListProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <div className={classes.root}>
        <Droppable droppableId="video-list">
          {droppable => (
            <List
              component="ul"
              aria-label="channel and playlist list"
              ref={droppable.innerRef}
              {...droppable.droppableProps}
            >
              {props.items.map((item, i) => (
                <Draggable key={item._id} draggableId={item._id} index={i}>
                  {draggable => (
                    <ListItem key={item._id} ref={draggable.innerRef} {...draggable.draggableProps}>
                      <ListItemIcon>
                        <div {...draggable.dragHandleProps}>
                          <DragIcon />
                        </div>
                      </ListItemIcon>
                      {props.noIcons ? null : (
                        <ListItemIcon>
                          {item.__typename === 'Channel' ? (
                            <OndemandVideoIcon />
                          ) : (
                            <PlaylistPlayIcon />
                          )}
                        </ListItemIcon>
                      )}
                      <ListItemText primary={item.title} />
                      {props.onRemove ? (
                        <IconButton aria-label="delete" onClick={() => props.onRemove!(item._id)}>
                          <DeleteIcon />
                        </IconButton>
                      ) : null}
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {droppable.placeholder}
            </List>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
}

DraggableChannelAndPlaylistList.Fragment = gql`
  fragment DraggableChannelList on Channel {
    _id
    title
  }
`
