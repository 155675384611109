import React, { useState } from 'react'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import * as yup from 'yup'
import { Theme, createStyles, useTheme, makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, TextField, Button, CircularProgress } from '@material-ui/core'
import background from '../assets/images/login-background.jpg'
import { useForgotPasswordAdminMutation } from '../generated/graphql'

const emailSchema = yup.string().email()

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      marginBottom: 50,
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      maxWidth: 450,
    },
    formWrapper: {
      padding: theme.spacing(3, 2),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    title: {
      paddingBottom: theme.spacing(2),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  })
)

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  filter: blur(3px);
  -webkit-filter: blur(3px);
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

export const ForgotPassword = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [forgotPasswordAdmin, { loading }] = useForgotPasswordAdminMutation()
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [messageVisible, setMessageVisible] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const onSubmit = async (e?: any) => {
    if (e && e.preventDefault) e.preventDefault()
    const isValid = await emailSchema.isValid(email)
    if (!email.length || !isValid) {
      setError('Enter a valid email.')
    } else {
      setError('')
      setMessageVisible(true)
      forgotPasswordAdmin({ variables: { email } })
    }
  }

  return (
    <Wrapper>
      <Background />
      <div className={classes.root}>
        <Paper className={classes.formWrapper}>
          {messageVisible ? (
            <Typography variant="body1" gutterBottom align="center">
              An email has been sent with password reset instructions.
            </Typography>
          ) : (
            <>
              <Typography variant="h4" component="h3" align="center" className={classes.title}>
                Forgot Password
              </Typography>
              {error && (
                <Typography variant="body1" gutterBottom align="center" color="error">
                  {error}
                </Typography>
              )}
              <form id="forgotForm" onSubmit={onSubmit} className={classes.form}>
                <TextField
                  id="outlined-email"
                  label="Email"
                  className={classes.textField}
                  value={email}
                  onChange={handleChange}
                  margin="normal"
                  variant="outlined"
                  type="email"
                />
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  className={classes.button}
                  onClick={onSubmit}
                  type="submit"
                  form="forgotForm"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size="26px" /> : 'Submit'}
                </Button>
              </form>
            </>
          )}
        </Paper>
      </div>
    </Wrapper>
  )
}

export const FORGOT_PASSWORD_ADMIN = gql`
  mutation ForgotPasswordAdmin($email: String!) {
    forgotPasswordAdmin(email: $email)
  }
`
