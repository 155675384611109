import React, { useState, useEffect, useContext } from 'react'
import gql from 'graphql-tag'
import { find } from 'lodash'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Divider } from '@material-ui/core'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import { DropResult } from 'react-beautiful-dnd'
import { Pages } from '../types'
import { UserContext } from './UserProvider'
import { useViewType } from './SwitchViewButton'
import { PlaylistTable } from './PlaylistTable'
import { Surface } from './Surface'
import { TooltipIconButton } from './TooltipIconButton'
import { PlaylistListItem } from './PlaylistList/PlaylistListItem'
import { DraggableChannelAndPlaylistList } from './DraggableChannelAndPlaylistList'
import { ChannelViewProps } from './ChannelView'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadMore: {
      marginTop: theme.spacing(3),
    },
    divider: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
    },
  })
)

export const ChannelPlaylists = (props: ChannelViewProps) => {
  const user = useContext(UserContext)
  const theme = useTheme()
  const classes = useStyles(theme)
  const { isGrid } = useViewType()
  const [isEdit, setIsEdit] = useState(false)
  const [editList, setEditList] = useState(props.playlists || [])

  useEffect(() => {
    setEditList(props.playlists || [])
    return () => {}
  }, [props.playlists])

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result

    if (!destination) return

    if (destination.droppableId === source.droppableId && destination.index === source.index) return

    const newPlaylistIds = Array.from(editList)
    const item = find(newPlaylistIds, ({ _id }) => _id === draggableId)!
    newPlaylistIds.splice(source.index, 1)
    newPlaylistIds.splice(destination.index, 0, item)

    setEditList(newPlaylistIds)
  }

  const save = () => {
    props.updateChannelPlaylistsOrder(editList.map(({ _id }) => _id))
    setIsEdit(false)
  }

  return props.playlists && props.playlists.length ? (
    <Surface
      title="Playlists"
      collapsable={isGrid}
      actions={
        user.isSuperAdmin || user.isAdmin || user.isChannelCollaborator(props._id) ? (
          <>
            {isEdit ? (
              <TooltipIconButton label="Save Playlists" onClick={save}>
                <SaveIcon />
              </TooltipIconButton>
            ) : (
              <TooltipIconButton label="Edit Playlists" onClick={() => setIsEdit(true)}>
                <EditIcon />
              </TooltipIconButton>
            )}
            <TooltipIconButton label="Create Playlist" onClick={props.onCreatePlaylist}>
              <PlaylistAddIcon />
            </TooltipIconButton>
          </>
        ) : (
          undefined
        )
      }
    >
      {isEdit ? (
        <DraggableChannelAndPlaylistList noIcons items={editList} onDragEnd={onDragEnd} />
      ) : isGrid ? (
        <>
          {props.playlists.map((playlist, i) => (
            <div key={playlist._id}>
              <PlaylistListItem {...playlist} variant={Pages.channel} />
              {props.playlists && i !== props.playlists.length - 1 ? (
                <Divider className={classes.divider} />
              ) : null}
            </div>
          ))}
        </>
      ) : (
        <>
          <PlaylistTable playlists={props.playlists} label="Channel Playlists" />
        </>
      )}
    </Surface>
  ) : null
}

ChannelPlaylists.Fragment = gql`
  fragment ChannelPlaylistsData on Channel {
    _id
    playlists {
      ...PlaylistListItemData
      ...PlaylistTableData
    }
  }
  ${PlaylistListItem.Fragment}
  ${PlaylistTable.Fragment}
`
