import { InputBase } from '@material-ui/core'
import { createStyles, fade, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
        cursor: 'pointer',
      },
      marginLeft: theme.spacing(1),
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
  })
)

interface PageSearchInputProps {
  value?: string
  onChange: (text: string) => void
}

export const PageSearchInput = (props: PageSearchInputProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    //@ts-ignore
    <div className={classes.search} onClick={() => document.getElementById('searchInput').focus()}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        id="searchInput"
        placeholder="Search…"
        value={props.value}
        onChange={e => props.onChange(e.target.value)}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
    </div>
  )
}
