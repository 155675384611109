import React from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(4),
    },
    loaderWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
  })
)

export const Loader = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <div className={classes.loaderWrapper}>
      <CircularProgress className={classes.progress} />
    </div>
  )
}
