import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import gql from 'graphql-tag'
import { find } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import { Channel, Playlist } from '../generated/graphql'

interface PageItemsListProps {
  channels: Channel[]
  order: string[]
}

export const PageItemsList = (props: PageItemsListProps) => {
  const filteredChannels = props.channels.filter(channel => channel !== null)
  const pageItems = props.order
    .map(id => (filteredChannels ? find(filteredChannels, ({ _id }) => _id === id) : null))
    .filter(item => !!item) as Array<Channel | Playlist>

  return (
    <List component="ul" aria-label="page items list">
      {pageItems.map((item, i) => {
        return (
          <ListItem key={item._id}>
            <ListItemIcon>
              <OndemandVideoIcon />
            </ListItemIcon>
            <Link to={`/channels/${item._id}`}>
              <ListItemText primary={item.title} />
            </Link>
          </ListItem>
        )
      })}
    </List>
  )
}

PageItemsList.Fragment = gql`
  fragment PageItemsListData on Channel {
    _id
    title
  }
`
