import { Button, Card, CardActions, IconButton, Tooltip } from '@material-ui/core'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import Block from '@material-ui/icons/Block'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import Face from '@material-ui/icons/Face'
import FlagIcon from '@material-ui/icons/Flag'
import Repeat from '@material-ui/icons/Repeat'
import WarningIcon from '@material-ui/icons/Warning'
import gql from 'graphql-tag'
import React from 'react'
import { Comment } from '../../generated/graphql'
import { useApproveComment, useRejectComment } from '../../lib/graphql/comment'
import { CommentContent } from './CommentContent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: 275,
      marginBottom: theme.spacing(2),
      position: 'relative',
    },
    username: {
      fontWeight: 700,
      marginRight: theme.spacing(1),
    },
    videoTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 12,
    },
    button: {
      color: '#fff',
    },
    viewReportsButton: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
    },
  })
)

interface CommentModerationCardProps extends Comment {
  noViewVideo?: boolean
  tabName?: string
  duplicate?: boolean
  bannedFromComment?: boolean
  openReportModal?: (id: string) => void
  rejectedByUploader?: boolean
}

export const CommentModerationCard = (props: CommentModerationCardProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const approve = useApproveComment(props._id)
  const reject = useRejectComment(props._id)
  return (
    <Card className={classes.card}>
      {props.openReportModal ? (
        props.spam ? (
          <Tooltip title="Spam" placement="bottom">
            <IconButton className={classes.viewReportsButton} disableRipple disableFocusRipple>
              <WarningIcon />
            </IconButton>
          </Tooltip>
        ) : props.reported ? (
          <Tooltip title="Reported" placement="bottom">
            <IconButton
              className={classes.viewReportsButton}
              onClick={() => props.openReportModal!(props._id)}
            >
              <FlagIcon />
            </IconButton>
          </Tooltip>
        ) : props.bannedFromComment ? (
          <Tooltip title="User Automatically Banned Due to Comment" placement="bottom">
            <IconButton className={classes.viewReportsButton} onClick={() => {}}>
              <Block />
            </IconButton>
          </Tooltip>
        ) : props.duplicate ? (
          <Tooltip title="Duplicate Comment" placement="bottom">
            <IconButton className={classes.viewReportsButton} onClick={() => {}}>
              <Repeat />
            </IconButton>
          </Tooltip>
        ) : null
      ) : props.bannedFromComment ? (
        <Tooltip title="User Automatically Banned Due to Comment" placement="bottom">
          <IconButton className={classes.viewReportsButton} onClick={() => {}}>
            <Block />
          </IconButton>
        </Tooltip>
      ) : props.duplicate ? (
        <Tooltip title="Duplicate Comment" placement="bottom">
          <IconButton className={classes.viewReportsButton} onClick={() => {}}>
            <Repeat />
          </IconButton>
        </Tooltip>
      ) : props.rejectedByUploader ? (
        <Tooltip title="Comment Removed by Channel Contributor" placement="bottom">
          <IconButton className={classes.viewReportsButton} onClick={() => {}}>
            <Face />
          </IconButton>
        </Tooltip>
      ) : null}
      <CommentContent {...props} isModerate />
      <CardActions>
        <Button
          size="medium"
          variant={props.approved ? 'contained' : 'outlined'}
          color="secondary"
          onClick={approve}
          className={props.approved ? classes.button : undefined}
        >
          <CheckIcon className={props.approved ? classes.button : undefined} />{' '}
          <span style={{ marginLeft: 2 }}>Approve</span>
        </Button>
        <Button
          size="medium"
          color="primary"
          variant={props.rejected ? 'contained' : 'outlined'}
          onClick={reject}
          className={props.rejected ? classes.button : undefined}
        >
          <CloseIcon className={props.rejected ? classes.button : undefined} />{' '}
          <span style={{ marginLeft: 2 }}>Reject</span>
        </Button>
      </CardActions>
    </Card>
  )
}

CommentModerationCard.Fragment = gql`
  fragment CommentModerationCardData on Comment {
    _id
    spam
    reported
    approved
    rejected
    bannedFromComment
    rejectedByUploader
    duplicate
    ...CommentContentData
  }
  ${CommentContent.Fragment}
`
