import React from 'react'
import clsx from 'clsx'
import { Theme, lighten, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  // FormControlLabel,
  // Switch,
} from '@material-ui/core'
// import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
      flex: '1 1 100%',
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: '0 0 auto',
    },
  })
)

interface TableToolbarProps {
  numSelected: number
  label: string
  actions?: Array<{
    tooltip: string
    onClick: () => void
    icon: JSX.Element
  }>
}

export const TableToolbar = (props: TableToolbarProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { numSelected, label, actions } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            {label}
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 && actions
          ? actions.map(a => (
              <Tooltip key={a.tooltip} title={a.tooltip}>
                <IconButton onClick={a.onClick}>{a.icon}</IconButton>
              </Tooltip>
            ))
          : null}
        {/* <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip> */}
      </div>
    </Toolbar>
  )
}
