import React from 'react'
import gql from 'graphql-tag'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogContent,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { TransitionProps } from '@material-ui/core/transitions'
import { Channel, useGetChannelPickerQuery } from '../generated/graphql'
import { GridVariants } from '../types'
import { PageLayout } from './PageLayout'
import { ChannelTable } from './ChannelTable'
import { ChannelGrid } from './ChannelGrid'
import { useViewType } from './SwitchViewButton'
import { SwitchViewbutton } from './SwitchViewButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: '#fff',
    },
    submitButton: {
      color: '#fff',
      marginLeft: theme.spacing(1),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
    },
  })
)

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface ChannelPickerProps {
  open: boolean
  single?: boolean
  filters?: boolean
  close: () => void
  onSubmit?: () => void
  onSelect: (id: string) => void
  selectedItems: string[]
}

export const ChannelPicker = (props: ChannelPickerProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { isGrid } = useViewType()
  const { data, loading } = useGetChannelPickerQuery()
  const channels = (data && data.getAdminChannels ? data.getAdminChannels : []) as Channel[]
  return (
    <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" onClick={props.close} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Channels
          </Typography>
          <SwitchViewbutton />
          {props.single ? null : (
            <Button
              variant="contained"
              color="secondary"
              disabled={!props.selectedItems.length}
              onClick={props.onSubmit ? props.onSubmit : props.close}
              className={classes.submitButton}
            >
              Submit
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <PageLayout loading={loading || !channels}>
          {isGrid ? (
            <ChannelGrid
              channels={channels}
              onSelect={props.onSelect}
              selectedItems={props.selectedItems}
              variant={GridVariants.selectList}
            />
          ) : (
            <ChannelTable channels={channels} label="All Channels" noLink />
          )}
        </PageLayout>
      </DialogContent>
    </Dialog>
  )
}

export const GET_CHANNEL_PICKER = gql`
  query GetChannelPicker {
    getAdminChannels {
      ...ChannelTableData
      ...ChannelCardData
    }
  }
  ${ChannelTable.Fragment}
  ${ChannelGrid.Fragment}
`
