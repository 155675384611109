import React from 'react'
import { Fab, Tooltip } from '@material-ui/core'
import { FabProps } from '@material-ui/core/Fab'
import { Theme, useTheme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
}))

interface TooltipFab extends FabProps {
  children: JSX.Element
  tooltipLabel: string
}

export const TooltipFab = (props: TooltipFab) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <Tooltip title={props.tooltipLabel} placement="left">
      <Fab
        aria-label={props['aria-label']}
        className={classes.fab}
        color="primary"
        onClick={props.onClick}
      >
        {props.children}
      </Fab>
    </Tooltip>
  )
}
