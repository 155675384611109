import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useMediaQuery, CssBaseline } from '@material-ui/core'
import theme from '../../lib/theme'
import { Breadcrumbs } from './Breadcrumbs'
import { Header } from './Header'
import { Drawer } from './Drawer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    content: {
      // maxWidth: 2000,
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      padding: theme.spacing(3),
    },
    breakcrumbs: {
      marginBottom: theme.spacing(3),
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  })
)

export const MainLayout: React.FC = props => {
  const classes = useStyles(theme)
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = () => setOpen(!open)

  React.useEffect(() => {
    setOpen(!isSmall)
    return () => {}
  }, [isSmall])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header toggleDrawer={toggleDrawer} />
      <Drawer open={open} toggleDrawer={toggleDrawer} />
      <div className={classes.wrapper}>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Breadcrumbs />
          {props.children}
        </main>
      </div>
    </div>
  )
}
