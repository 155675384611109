import gql from 'graphql-tag'
import { sortBy } from 'lodash'
import React from 'react'
import { AdminUserAdd } from '../components/AdminUserAdd'
import { AdminUserList } from '../components/AdminUserList'
import { PageLayout } from '../components/PageLayout'
import {
  AdminUser,
  GetAdminUsersPageQuery,
  Roles,
  useCreateAdminUserMutation,
  useGetAdminUsersPageQuery,
  useSetAdminUserPermissionsMutation,
  useSuspendAdminUserMutation,
} from '../generated/graphql'

export const AdminUsers = () => {
  const [createUser] = useCreateAdminUserMutation()
  const [setPermissions] = useSetAdminUserPermissionsMutation()
  const [suspendUser] = useSuspendAdminUserMutation()
  const { data, loading, refetch } = useGetAdminUsersPageQuery()
  console.log('data', data)
  const adminUsers = (data && data.getAdminUsers ? data.getAdminUsers : []) as AdminUser[]
  const displayAdminUsers = sortBy(
    adminUsers.filter(({ isAdmin }) => !isAdmin),
    ({ email }) => email
  )
  const setUserPermissions = (args: { id: string; roles: Roles[]; channels: string[] }) =>
    setPermissions({
      variables: args,
      update: (cache: any, { data }) => {
        if (data && data.setAdminUserPermissions) {
          const query = cache.readQuery({ query: GET_ADMIN_USERS_PAGE }) as GetAdminUsersPageQuery
          if (query && query.getAdminUsers) {
            cache.writeQuery({
              query: GET_ADMIN_USERS_PAGE,
              data: {
                getAdminUsers: query.getAdminUsers.map(user => {
                  if (user._id === args.id) {
                    return data.setAdminUserPermissions
                  }
                  return user
                }),
              },
            })
          }
        }
      },
    })
  const suspendAdminUser = (id: string) =>
    suspendUser({
      variables: { id },
      update: (cache: any, { data }) => {
        if (data && data.suspendAdminUser) {
          const query = cache.readQuery({ query: GET_ADMIN_USERS_PAGE }) as GetAdminUsersPageQuery
          if (query && query.getAdminUsers) {
            cache.writeQuery({
              query: GET_ADMIN_USERS_PAGE,
              data: {
                getAdminUsers: query.getAdminUsers.filter(user => user._id !== id),
              },
            })
          }
        }
      },
    })
  return (
    <PageLayout loading={loading} noViewSwitch>
      <AdminUserList
        adminUsers={displayAdminUsers}
        setAdminUserPermissions={setUserPermissions}
        suspendAdminUser={suspendAdminUser}
      />
      <AdminUserAdd
        onSubmit={async values => {
          await createUser({ variables: values })
          refetch()
        }}
      />
    </PageLayout>
  )
}

export const GET_ADMIN_USERS_PAGE = gql`
  query GetAdminUsersPage {
    getAdminUsers {
      ...AdminUserListData
    }
  }
  ${AdminUserList.Fragment}
`

export const SET_ADMIN_USER_PERMISSIONS = gql`
  mutation SetAdminUserPermissions($id: String!, $roles: [Roles!]!, $channels: [String!]!) {
    setAdminUserPermissions(id: $id, roles: $roles, channels: $channels) {
      ...AdminUserListData
    }
  }
  ${AdminUserList.Fragment}
`

export const SUSPEND_ADMIN_USER = gql`
  mutation SuspendAdminUser($id: String!) {
    suspendAdminUser(id: $id)
  }
`

export const CREATE_ADMIN_USER = gql`
  mutation CreateAdminUser($email: String!, $password: String!) {
    signUpAdminUser(email: $email, password: $password) {
      ...AdminUserListData
    }
  }
  ${AdminUserList.Fragment}
`
