import React from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles'
import { Button, CircularProgress } from '@material-ui/core'
import { useDangerTheme } from '../lib/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1),
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  })
)

interface ToggleButtonProps {
  active: boolean
  activeLabel: string
  inactiveLabel: string
  loading: boolean
  onClick: () => void
}

export const ToggleButton = (props: ToggleButtonProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const dangerTheme = useDangerTheme()
  return props.active ? (
    <MUIThemeProvider theme={dangerTheme}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={props.onClick}
      >
        {props.loading ? <CircularProgress size="24px" color="secondary" /> : props.activeLabel}
      </Button>
    </MUIThemeProvider>
  ) : (
    <Button variant="contained" color="primary" className={classes.button} onClick={props.onClick}>
      {props.loading ? <CircularProgress size="24px" color="secondary" /> : props.inactiveLabel}
    </Button>
  )
}
