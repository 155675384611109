import React from 'react'
import gql from 'graphql-tag'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Button, Hidden } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { DeleteButton } from '../DeleteButton'
import { ToggleButton } from '../ToggleButton'
import { VideoViewProps } from './VideoView'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1),
    },
    buttonRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  })
)

interface VideoActionsProps extends VideoViewProps {
  toggleEdit: () => void
}

export const VideoActions = (props: VideoActionsProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <>
      <Hidden smUp>
        <div className={classes.buttonRow}>
          <ToggleButton
            active={props.published}
            activeLabel="Unpublish"
            inactiveLabel="Publish"
            loading={props.updateVideoLoading}
            onClick={props.togglePublished}
          />
          <ToggleButton
            active={!props.unlisted}
            activeLabel="Make Private"
            inactiveLabel="Make Public"
            loading={props.updateVideoUnlistLoading}
            onClick={props.toggleUnlisted}
          />
        </div>
        <div className={classes.buttonRow} style={{ marginTop: 10 }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={props.toggleEdit}
          >
            <EditIcon />
          </Button>
          <DeleteButton className={classes.button} onClick={props.removeVideo} />
        </div>
      </Hidden>
      <Hidden xsDown>
        <div className={classes.buttonRow}>
          <ToggleButton
            active={props.published}
            activeLabel="Unpublish"
            inactiveLabel="Publish"
            loading={props.updateVideoLoading}
            onClick={props.togglePublished}
          />
          <ToggleButton
            active={!props.unlisted}
            activeLabel="Make Private"
            inactiveLabel="Make Public"
            loading={props.updateVideoUnlistLoading}
            onClick={props.toggleUnlisted}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={props.toggleEdit}
          >
            <EditIcon />
          </Button>
          <DeleteButton className={classes.button} onClick={props.removeVideo} />
        </div>
      </Hidden>
    </>
  )
}

VideoActions.Fragment = gql`
  fragment VideoActions on Video {
    published
    unlisted
  }
`
