export enum Pages {
  channel = 'CHANNEL_PAGE',
  playlist = 'PLAYLIST_PAGE',
  playlists = 'PLAYLISTS_PAGE',
  upload = 'UPLOAD_PAGE',
}

export enum GridVariants {
  selectList = 'SELECT_LIST',
}
