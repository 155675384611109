import React, { useState } from 'react'
import gql from 'graphql-tag'
import { Formik } from 'formik'
import {
  Button,
  TextField,
  Typography,
  FormHelperText,
  CircularProgress,
  Snackbar,
} from '@material-ui/core'
import { useResetPasswordAdminMutation } from '../generated/graphql'

export const ChangePassword = () => {
  const [resetPassword] = useResetPasswordAdminMutation()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [error, setError] = useState('')
  const handleSnackbarClose = () => setSnackbarOpen(false)
  return (
    <>
      {error && (
        <Typography variant="body1" gutterBottom align="center" color="error">
          {error}
        </Typography>
      )}
      <Formik
        initialValues={{ currentPassword: '', newPassword: '', passwordRepeat: '' }}
        validate={values => {
          let errors: any = {}
          if (!values.currentPassword) {
            errors.currentPassword = 'Required'
          }
          if (!values.newPassword) {
            errors.newPassword = 'Required'
          }
          if (!values.passwordRepeat) {
            errors.passwordRepeat = 'Required'
          }
          if (values.newPassword !== values.passwordRepeat) {
            errors.passwordRepeat = "Passwords don't match"
          }
          return errors
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={async (values, { setSubmitting, resetForm, setFieldError }) => {
          try {
            const { data } = await resetPassword({
              variables: {
                currentPassword: values.currentPassword,
                newPassword: values.newPassword,
                newPasswordConfirmed: values.passwordRepeat,
              },
            })
            if (data) {
              localStorage.setItem('token', data.resetPasswordAdmin)
              setSubmitting(false)
              resetForm()
              setSnackbarOpen(true)
              setTimeout(handleSnackbarClose, 3000)
            } else {
              setError('An error occured...')
            }
          } catch (e) {
            console.log('ERROR', e)
            setSubmitting(false)
            if (e.message.indexOf('Invalid password')) {
              setFieldError('currentPassword', 'Incorrect password')
            } else {
              setError(e.message)
            }
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                id="currentPassword"
                label="Current Password"
                name="currentPassword"
                margin="dense"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.currentPassword}
              />
              {touched.currentPassword && errors.currentPassword ? (
                <FormHelperText error>{errors.currentPassword}</FormHelperText>
              ) : null}
              <TextField
                fullWidth
                id="newPassword"
                label="New Password"
                name="newPassword"
                margin="dense"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
              />
              {touched.newPassword && errors.newPassword ? (
                <FormHelperText error>{errors.newPassword}</FormHelperText>
              ) : null}
              <TextField
                fullWidth
                id="passwordRepeat"
                label="Repeat Password"
                name="passwordRepeat"
                margin="dense"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.passwordRepeat}
              />
              {touched.passwordRepeat && errors.passwordRepeat ? (
                <FormHelperText error>{errors.passwordRepeat}</FormHelperText>
              ) : null}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginTop: 20, color: '#fff' }}
              >
                {isSubmitting ? <CircularProgress size="24px" color="secondary" /> : 'SUBMIT'}
              </Button>
            </form>
          )
        }}
      </Formik>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message="Change password successful!"
      />
    </>
  )
}

export const RESET_PASSWORD_ADMIN = gql`
  mutation ResetPasswordAdmin(
    $currentPassword: String!
    $newPassword: String!
    $newPasswordConfirmed: String!
  ) {
    resetPasswordAdmin(
      currentPassword: $currentPassword
      newPassword: $newPassword
      newPasswordConfirmed: $newPasswordConfirmed
    )
  }
`
