import React from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { CardContent, Typography, Link, Box } from '@material-ui/core'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import { Comment } from '../../generated/graphql'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: 275,
      marginBottom: theme.spacing(2),
    },
    username: {
      fontWeight: 700,
      marginRight: theme.spacing(1),
    },
    videoTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 12,
    },
    viewContext: {
      marginTop: theme.spacing(2),
    },
    button: {
      color: '#fff',
    },
  })
)

interface CommentContentProps extends Comment {
  tabName?: string
  noViewVideo?: boolean
  isModerate?: boolean
}

export const CommentContent = (props: CommentContentProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <CardContent>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
        <Typography
          variant="subtitle1"
          className={classes.username}
          color="textSecondary"
          gutterBottom
        >
          <Link href={`/users/${props.user.username}`} color="inherit">
            {props.user.username}
          </Link>
        </Typography>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          {moment(props.createdAt).fromNow()}
        </Typography>
      </div>
      {props.noViewVideo ? null : props.video ? (
        <Typography
          variant="subtitle2"
          className={classes.videoTitle}
          color="textSecondary"
          gutterBottom
        >
          {props.video.title}{' '}
          <Link
            href={`/channels/${props.video.channel._id}/videos/${props.video._id}`}
            onClick={() => {}}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginLeft: 8, marginRight: 4 }}>
                {props.isModerate ? 'Moderate' : 'View Video'}
              </span>{' '}
              <ArrowRightAltIcon />
            </div>
          </Link>
        </Typography>
      ) : null}
      <Typography variant="body1" component="p">
        {props.content}
      </Typography>

      {props.tabName === 'getReportedComments' ? (
        <Typography variant="subtitle2" color="textSecondary" style={{ marginTop: '20px' }}>
          {props.reports && props.reports.length ? (
            <div>
              <span>Report content</span>
              <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
                {props.reports[props.reports.length - 1].content}
              </Box>
            </div>
          ) : null}
        </Typography>
      ) : null}

      {props.replyTo ? (
        <Typography variant="subtitle2" className={classes.viewContext} color="textSecondary">
          <Link href={`/comments/${props.replyTo._id}`} onClick={() => {}}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 4 }}>View Context</span> <ArrowRightAltIcon />
            </div>
          </Link>
        </Typography>
      ) : null}
    </CardContent>
  )
}

CommentContent.Fragment = gql`
  fragment CommentContentData on Comment {
    _id
    content
    createdAt
    replyTo {
      _id
    }
    reports {
      content
    }
    video {
      _id
      title
      channel {
        _id
      }
    }
    user {
      username
    }
  }
`
