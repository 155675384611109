import React from 'react'
import clsx from 'clsx'
import { Link, NavLink } from 'react-router-dom'
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import {
  Drawer as MUIDrawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  IconButton,
  Typography,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { routeMap } from '../../components/Router'
import { UserContext } from '../../components/UserProvider'
import { useLogout } from '../../lib/auth'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    drawerItem: {
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    menuButton: {
      marginRight: 24,
    },
  })
)

interface DrawerProps {
  open: boolean
  toggleDrawer: () => void
}

export const Drawer = (props: DrawerProps) => {
  const user = React.useContext(UserContext)
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const logout = useLogout()
  const { open } = props
  const theme = useTheme()
  const classes = useStyles(theme)
  const pages = routeMap
    .filter(({ drawer }) => !!drawer)
    .map(({ superAdmin, admin, uploader, sales, moderator, drawer }) =>
      superAdmin
        ? user.isSuperAdmin
          ? drawer
          : null
        : admin
        ? user.isSuperAdmin || user.isAdmin
          ? drawer
          : null
        : uploader
        ? user.isSuperAdmin || user.isAdmin || user.isUploader
          ? drawer
          : null
        : sales
        ? user.isSuperAdmin || user.isSales
          ? drawer
          : null
        : moderator
        ? user.isSuperAdmin || user.isModerator
          ? drawer
          : null
        : drawer
    )
    .filter(d => !!d) as Array<{ name: string; icon: JSX.Element; route: string }>

  const drawerContent = (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.toggleDrawer}
            edge="start"
            className={classes.menuButton}
          >
            <ArrowBack />
          </IconButton>
          <Link to="/">
            <Typography variant="h6" noWrap>
              InfoWars Media
            </Typography>
          </Link>
        </Toolbar>
        <Divider />
        <List>
          {pages.map(({ name, icon, route }) => (
            <NavLink
              key={name}
              to={route}
              style={{ textDecoration: 'none' }}
              activeStyle={{ textDecoration: 'none' }}
            >
              <ListItem
                button
                className={classes.drawerItem}
                onClick={() => (isSmall ? props.toggleDrawer() : {})}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            </NavLink>
          ))}
        </List>
      </div>
      <Hidden smUp>
        <div>
          <Divider />
          <List>
            <ListItem button className={classes.drawerItem} onClick={logout}>
              {/* <ListItemIcon>{icon}</ListItemIcon> */}
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </div>
      </Hidden>
    </div>
  )

  return (
    <>
      <Hidden smUp>
        <MUIDrawer
          open={open}
          onClose={props.toggleDrawer}
          variant="temporary"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerContent}
        </MUIDrawer>
      </Hidden>
      <Hidden xsDown>
        <MUIDrawer
          open={open}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: true,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: true,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {drawerContent}
        </MUIDrawer>
      </Hidden>
    </>
  )
}
