import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
// import MomentUtils from '@date-io/moment'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Formik } from 'formik'
import gql from 'graphql-tag'
import { find, uniqBy } from 'lodash'
import moment from 'moment-timezone'
import React, { useRef } from 'react'
// import {
//   MuiPickersUtilsProvider,
//   MaterialUiPickersDate,
//   KeyboardDateTimePicker,
// } from '@material-ui/pickers'
import { useGetVideoUploadChannelsQuery, VideoInput } from '../generated/graphql'
import { useError } from './ErrorModal'
import { FileUpload, FileUploadVariants } from './FileUpload'
import { TabPanel, TabPanelProps } from './TabPanel'
import { TagsAutocomplete } from './TagsAutocomplete'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    videoUploadTitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    imageUploadTitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    audioUploadTitle: {
      marginTop: theme.spacing(1),
    },
    textField: {
      width: '100%',
    },
    formControl: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    submitButton: {
      width: '75%',
      marginRight: theme.spacing(1),
      color: '#fff',
    },
    resetButton: {
      width: '25%',
      marginLeft: theme.spacing(1),
    },
    buttonRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    timeMessage: {
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  })
)

interface VideoUploadProps extends TabPanelProps {
  createVideo: (video: VideoInput) => Promise<any>
}

export const VideoUpload = (props: VideoUploadProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { data } = useGetVideoUploadChannelsQuery()
  const videoUploadRef = useRef()
  const imageUploadRef = useRef()
  const audioUploadRef = useRef()
  const { setErrorMessage } = useError()
  const channels = data && data.getAdminChannels ? data.getAdminChannels : []
  const onVideoUploadComplete = async (id: string, setFieldFunc: any) => {
    await setFieldFunc('videoUploadId', id)
  }
  return (
    <TabPanel value={props.value} index={props.index}>
      <Formik
        initialValues={{
          title: null,
          summary: null,
          channelId: null,
          playlistId: '',
          videoUploadId: null,
          posterUploadId: null,
          audioUploadId: '',
          tags: [],
          publishedAt: null,
        }}
        validate={values => {
          const errors: any = {}
          if (!values.title || !values.title.length) errors.title = 'Title is required'
          if (!values.summary || !values.summary.length) errors.summary = 'Summary is required'
          if (!values.channelId || !values.channelId.length)
            errors.channelId = 'Channel is required'
          // if (!values.publishedAt) errors.publishedAt = 'Required'
          if (!values.posterUploadId || !values.posterUploadId.length)
            errors.posterUploadId = 'Thumbnail is required'
          if (!values.videoUploadId || !values.videoUploadId.length)
            errors.videoUploadId = 'Video is required'
          if (!values.tags.length || !values.tags.length)
            errors.tags = 'At least one tag is required'
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const tagIds = [] as string[]
          const newTags = [] as string[]
          values.tags.forEach(({ _id, value }) => {
            if (_id) return tagIds.push(_id)
            if (value) return newTags.push(value)
          })
          const video = {
            ...values,
            publishedAt: moment()
              .tz('America/Chicago')
              .toDate(),
            published: true,
            live: false,
            unlisted: false,
          } as VideoInput
          delete (video as any).tags
          if (tagIds && tagIds.length) video.tagIds = tagIds
          if (newTags && newTags.length) video.newTags = newTags
          try {
            await props.createVideo(video)
          } catch (e) {
            setErrorMessage(e.message)
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => {
          const reset = () => {
            const videoUpload = videoUploadRef.current! as any
            const imageUpload = imageUploadRef.current! as any
            const audioUpload = audioUploadRef.current! as any
            resetForm()
            if (videoUpload.reset) {
              videoUpload.reset()
            }
            if (imageUpload.reset) {
              imageUpload.reset()
            }
            if (audioUpload.reset) {
              audioUpload.reset()
            }
          }
          const channel = values.channelId
            ? find(channels, ({ _id }) => _id === values.channelId)
            : null
          const playlists = channel ? channel.playlists : null
          return (
            // <MuiPickersUtilsProvider utils={MomentUtils}>
            <form onSubmit={handleSubmit}>
              <FileUpload
                ref={videoUploadRef}
                variant={FileUploadVariants.video}
                onComplete={(id: string) => onVideoUploadComplete(id, setFieldValue)}
                onStart={() => setFieldTouched('videoUploadId')}
              />
              <Typography variant="subtitle1" component="p" className={classes.videoUploadTitle}>
                Video Upload <em>(Required)</em>
                {touched.videoUploadId && errors.videoUploadId ? (
                  <FormHelperText error>{errors.videoUploadId}</FormHelperText>
                ) : null}
              </Typography>
              <FileUpload
                ref={imageUploadRef}
                variant={FileUploadVariants.image}
                onComplete={(id: string) => setFieldValue('posterUploadId', id)}
                onStart={() => setFieldTouched('posterUploadId')}
              />
              <Typography variant="subtitle1" component="p" className={classes.imageUploadTitle}>
                Thumbnail Upload <em>(Required)</em>
                {touched.posterUploadId && errors.posterUploadId ? (
                  <FormHelperText error>{errors.posterUploadId}</FormHelperText>
                ) : null}
              </Typography>
              <FileUpload
                ref={audioUploadRef}
                variant={FileUploadVariants.audio}
                onComplete={(id: string) => setFieldValue('audioUploadId', id)}
                onStart={() => setFieldTouched('audioUploadId')}
              />
              <Typography variant="subtitle1" component="p" className={classes.audioUploadTitle}>
                Audio Upload
              </Typography>

              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="channelId">
                  Channel <em>(Required)</em>
                </InputLabel>
                {touched.channelId && errors.channelId ? (
                  <FormHelperText error>{errors.channelId}</FormHelperText>
                ) : null}
                <Select
                  value={values.channelId || ''}
                  onChange={e => setFieldValue('channelId', e.target.value)}
                  inputProps={{ name: 'channelId', id: 'channelId' }}
                  error={!!(touched.channelId && errors.channelId)}
                >
                  {channels.map(({ _id, title }) => (
                    <MenuItem key={_id} value={_id}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {playlists ? (
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="playlistId">Playlist</InputLabel>
                  <Select
                    value={values.playlistId}
                    onChange={e => setFieldValue('playlistId', e.target.value)}
                    inputProps={{ name: 'playlistId', id: 'playlistId' }}
                  >
                    {playlists.map(({ _id, title }) => (
                      <MenuItem key={_id} value={_id}>
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
              <TextField
                id="title"
                label="Title (Required)"
                name="title"
                className={classes.textField}
                value={values.title}
                onChange={handleChange}
                margin="normal"
                error={!!(touched.title && errors.title)}
              />
              {touched.title && errors.title ? (
                <FormHelperText error>{errors.title}</FormHelperText>
              ) : null}
              <TextField
                id="summary"
                label="Summary/Description (Required)"
                name="summary"
                className={classes.textField}
                value={values.summary}
                onChange={handleChange}
                multiline
                maxRows="4"
                margin="normal"
                error={!!(touched.summary && errors.summary)}
              />
              {touched.summary && errors.summary ? (
                <FormHelperText error>{errors.summary}</FormHelperText>
              ) : null}
              <TagsAutocomplete
                value={values.tags}
                onChange={(values: Array<{ label: string; value: string }>) =>
                  setFieldValue('tags', uniqBy(values, ({ value }) => value))
                }
              />
              {touched.tags && errors.tags ? (
                <FormHelperText error>{errors.tags}</FormHelperText>
              ) : null}
              {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Publish Date & Time"
                    format="MM/D/YYYY hh:mm"
                    minDate={moment()
                      .tz('America/Chicago')
                      .toDate()}
                    value={values.publishedAt}
                    onChange={(date: MaterialUiPickersDate) => {
                      if (date) {
                        const time = moment(date)
                        setFieldValue('publishedAt', time.tz('America/Chicago').toDate())
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date and time',
                    }}
                  />
                  <Typography component="p" className={classes.timeMessage}>
                    Time is converted to Austin local time
                  </Typography>
                </div>
                {touched.publishedAt && errors.publishedAt ? (
                  <FormHelperText error>{errors.publishedAt}</FormHelperText>
                ) : null} */}
              <div className={classes.buttonRow}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="secondary"
                  className={classes.submitButton}
                >
                  {isSubmitting ? <CircularProgress size="24px" color="secondary" /> : 'Submit'}
                </Button>
                <Button variant="contained" className={classes.resetButton} onClick={reset}>
                  Reset
                </Button>
              </div>
            </form>
            // </MuiPickersUtilsProvider>
          )
        }}
      </Formik>
    </TabPanel>
  )
}

export const GET_VIDEO_UPLOAD_CHANNELS = gql`
  query GetVideoUploadChannels {
    getAdminChannels {
      _id
      title
      playlists {
        _id
        title
      }
    }
  }
`
