import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import gql from 'graphql-tag'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Video } from '../generated/graphql'
import { Data, HeadRow, Table } from './Table'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
)

export interface VideoTableData {
  id: string
  channelId: string
  title: string
  playCount: number
  published: boolean
  createdAt: Date
  type: string
}

function createData(video: Video): VideoTableData {
  return {
    id: video._id,
    channelId: video.channel._id,
    title: video.title,
    playCount: video.playCount,
    published: video.published,
    createdAt: video.createdAt,
    type: 'video',
  }
}

const headRows: HeadRow<VideoTableData>[] = [
  { id: 'title', alignRight: false, disablePadding: true, label: 'Videos' },
  { id: 'playCount', alignRight: true, disablePadding: false, label: 'Views' },
  { id: 'published', alignRight: true, disablePadding: false, label: 'Published' },
  { id: 'createdAt', alignRight: true, disablePadding: false, label: 'Created At' },
]

function isVideo(row: any): row is VideoTableData {
  return (row as VideoTableData).type === 'video'
}

interface VideoTableProps {
  videos: Video[]
  label: string
  onCreatePlaylist?: (videoIds: string[]) => void
  onSelect?: (id: string) => void
  selectedItems?: string[]
  defaultRowsPerPage?: number
  noLink?: boolean
  loadMore?: () => void
  totalVideos?: number
}

export const VideoTable = (props: VideoTableProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const rows = props.videos.map(createData)
  const actions = []
  if (props.onCreatePlaylist) {
    actions.push({
      tooltip: 'Create Playlist',
      onClick: props.onCreatePlaylist,
      icon: <PlaylistAddIcon />,
    })
  }
  return (
    <div className={classes.root}>
      <Table
        rows={rows}
        label={props.label}
        headRows={headRows as HeadRow<Data>[]}
        actions={actions}
        selectedItems={props.selectedItems}
        defaultRowsPerPage={props.defaultRowsPerPage}
        totalRows={props.totalVideos}
        loadMore={props.loadMore}
      >
        {({ row, handleClick, isItemSelected, labelId }) => {
          const selected = props.selectedItems
            ? props.selectedItems.indexOf(row.id) !== -1
            : isItemSelected
          return isVideo(row) ? (
            <TableRow
              hover
              onClick={event =>
                props.onSelect ? props.onSelect(row.id) : handleClick(event, row.id)
              }
              role="checkbox"
              aria-checked={selected}
              tabIndex={-1}
              key={row.id}
              selected={selected}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={selected} inputProps={{ 'aria-labelledby': labelId }} />
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="none"
                style={props.noLink ? undefined : { textDecoration: 'underline' }}
              >
                {props.noLink ? (
                  row.title
                ) : (
                  <Link to={`/channels/${row.channelId}/videos/${row.id}`}>{row.title}</Link>
                )}
              </TableCell>
              {/* <TableCell align="right">{row.playCount.toLocaleString()}</TableCell> */}
              <TableCell align="right">{row.published ? 'Yes' : 'No'}</TableCell>
              <TableCell align="right">{moment(row.createdAt).calendar()}</TableCell>
            </TableRow>
          ) : null
        }}
      </Table>
    </div>
  )
}

VideoTable.Fragment = gql`
  fragment VideoTableData on Video {
    _id
    title
    playCount
    published
    createdAt
    channel {
      _id
    }
  }
`
