import { Divider, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import moment from 'moment'
import React from 'react'
import { ActionType, AdminAction } from '../generated/graphql'

const ACTION_TYPE_TO_LABEL = {
  [ActionType.CreateChannel]: 'created channel',
  [ActionType.UpdateChannelPlaylistOrder]: 'updated channel playlist order',
  [ActionType.UpdateChannel]: 'updated channel',
  [ActionType.RemoveChannelFeaturedVideo]: 'removed channel featured video',
  [ActionType.RemoveChannelLiveStreamVideo]: 'removed channel live stream video',
  [ActionType.RemoveChannel]: 'removed channel',
  [ActionType.CreateConfig]: 'created config',
  [ActionType.UpdateConfig]: 'updated config',
  [ActionType.CreatePlaylist]: 'created playlist',
  [ActionType.UpdatePlaylist]: 'updated playlist',
  [ActionType.RemovePlaylist]: 'removed playlist',
  [ActionType.AddVideosToPlaylist]: 'added videos to playlist',
  [ActionType.RemoveVideosFromPlaylist]: 'removed videos from playlist',
  [ActionType.CreateSale]: 'created sale',
  [ActionType.ResetSaleClicks]: 'reset sale clicks',
  [ActionType.ResetAllSaleClicks]: 'reset all sale clicks',
  [ActionType.CreateTag]: 'created tag',
  [ActionType.CreateVideo]: 'created video',
  [ActionType.UpdateVideo]: 'updated video',
  [ActionType.RemoveVideo]: 'removed video',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    primaryText: {
      display: 'flex',
      flexDirection: 'row',
      whiteSpace: 'pre',
    },
  })
)

export const AdminActionList = (props: { adminActions: AdminAction[] }) => {
  const classes = useStyles({})
  return (
    <div className={classes.root}>
      <List>
        {props.adminActions.map(({ _id, type, title, createdAt, adminUser }, i) => (
          <>
            <ListItem key={_id}>
              <ListItemText secondary={moment(createdAt).calendar()}>
                <div className={classes.primaryText}>
                  <Typography>{adminUser.email + ' '}</Typography>
                  <Typography color="secondary">{ACTION_TYPE_TO_LABEL[type] + ' '}</Typography>
                  <Typography>{title + ' '}</Typography>
                </div>
              </ListItemText>
            </ListItem>
            {i !== props.adminActions.length - 1 ? (
              <Divider variant="middle" component="li" />
            ) : null}
          </>
        ))}
      </List>
    </div>
  )
}
