import { Typography } from '@material-ui/core';
import React from 'react';

export const Blocked = () => {
  return (
      <Typography>
      <h1>You have been temporarily blocked.</h1>
      <p>
        Your account's activity has activated our anti SPAM protocol, please email <a style={
          {color: 'yellow'}
        } href="mailto:robd@infowars.com">robd@infowars.com</a>
      </p>
      </Typography>
  )
}