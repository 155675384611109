import React, { useState, useContext } from 'react'
import gql from 'graphql-tag'
import useReactRouter from 'use-react-router'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import { useMediaQuery, Hidden } from '@material-ui/core'
import {
  UpdateVideoInput,
  useGetVideoPageQuery,
  Video as VideoType,
  useAddVideosToPlaylistMutation,
} from '../generated/graphql'
import { useRemoveVideo, useUpdateVideo } from '../lib/graphql/video'
import { useCreatePlaylist } from '../lib/graphql/playlist'
import { UserContext } from '../components/UserProvider'
import { PageLayout } from '../components/PageLayout'
import { PlaylistGrid } from '../components/PlaylistGrid'
import { VideoView } from '../components/VideoView'
import { VideoEmbedCode } from '../components/VideoEmbedCode'
import { VideoComments } from '../components/VideoComments'
import { Surface } from '../components/Surface'
import { TooltipIconButton } from '../components/TooltipIconButton'
import { VideoAddToPlaylist } from '../components/VideoAddToPlaylist'
import { PlaylistAddForm } from '../components/PlaylistAddForm'

interface MatchParams {
  channelId: string
  videoId: string
}

export const Video = () => {
  const user = useContext(UserContext)
  const { match } = useReactRouter<MatchParams>()
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const { videoId } = match.params
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [loadingTogglePublish, setLoadingTogglePublish] = useState(false)
  const [loadingToggleUnlisted, setLoadingToggleUnlisted] = useState(false)
  const [addToPlaylistOpen, setAddToPlaylistOpen] = useState(false)
  const [addPlaylistOpen, setAddPlaylistOpen] = useState(false)
  const { data, loading, refetch } = useGetVideoPageQuery({ variables: { id: videoId } })
  const [addVideosToPlaylist] = useAddVideosToPlaylistMutation()
  const createPlaylist = useCreatePlaylist()
  const video = (data && data.getVideo) as VideoType
  const updateVideo = useUpdateVideo(videoId)
  const removeVideo = useRemoveVideo(videoId, video && video.channel && video.channel._id)
  const togglePublished = async () => {
    setLoadingTogglePublish(true)
    try {
      await updateVideo({ published: !video.published })
    } catch (e) {}
    setLoadingTogglePublish(false)
  }
  const toggleUnlisted = async () => {
    setLoadingToggleUnlisted(true)
    try {
      await updateVideo({ unlisted: !video.unlisted })
    } catch (e) {}
    setLoadingToggleUnlisted(false)
  }
  const onAddToPlaylist = async (id: string) => {
    setAddToPlaylistOpen(false)
    await addVideosToPlaylist({
      variables: {
        id: id,
        videoIds: [video._id],
      },
    })
    refetch()
  }

  const editVideo = async (updateVideoInput: UpdateVideoInput) => {
    setLoadingEdit(true)
    await updateVideo(updateVideoInput)
    setLoadingEdit(false)
  }

  return (
    <PageLayout loading={loading} noViewSwitch>
      <>
        <VideoView
          {...video}
          togglePublished={togglePublished}
          toggleUnlisted={toggleUnlisted}
          updateVideoLoading={loadingTogglePublish && !loadingEdit}
          updateVideoUnlistLoading={loadingToggleUnlisted && !loadingEdit}
          removeVideo={removeVideo}
          editVideo={editVideo}
        />
        {video && video.audio ? (
          <Surface title="Audio" collapsable>
            <div style={{ textAlign: 'center' }}>
              <audio controls src={video.audio} style={{ width: isSmall ? '250px' : 300 }} />
            </div>
          </Surface>
        ) : null}
        {video ? (
          <Surface
            title="Playlists"
            collapsable
            actions={
              user.isSuperAdmin || user.isAdmin || user.isChannelCollaborator(video.channel._id) ? (
                <TooltipIconButton
                  label="Add To Playlist"
                  onClick={() => setAddToPlaylistOpen(true)}
                >
                  <PlaylistAddIcon />
                </TooltipIconButton>
              ) : (
                undefined
              )
            }
          >
            <PlaylistGrid playlists={video.playlists || []} />
          </Surface>
        ) : null}
        <Hidden xsDown>
          <VideoEmbedCode {...video} />
        </Hidden>
        <VideoComments {...video} />
        {video ? (
          <>
            <VideoAddToPlaylist
              open={addToPlaylistOpen}
              close={() => setAddToPlaylistOpen(false)}
              videoId={video._id}
              channelId={video.channel._id}
              onSubmit={onAddToPlaylist}
              createPlaylist={() => {
                setAddToPlaylistOpen(false)
                setAddPlaylistOpen(true)
              }}
            />
            <PlaylistAddForm
              open={addPlaylistOpen}
              close={() => setAddPlaylistOpen(false)}
              onSubmit={createPlaylist}
              channel={video.channel._id}
              video={video._id}
            />
          </>
        ) : null}
      </>
    </PageLayout>
  )
}

export const GET_VIDEO_PAGE = gql`
  query GetVideoPage($id: String!) {
    getVideo(id: $id) {
      _id
      audio
      playlists {
        ...PlaylistCardData
      }
      ...VideoViewData
    }
  }
  ${PlaylistGrid.Fragment}
  ${VideoView.Fragment}
`

export const UPDATE_VIDEO = gql`
  mutation UpdateVideo($id: String!, $video: UpdateVideoInput!, $create: Boolean) {
    updateVideo(id: $id, video: $video, create: $create) {
      _id
      ...VideoViewData
    }
  }
  ${VideoView.Fragment}
`

export const REMOVE_VIDEO = gql`
  mutation RemoveVideo($id: String!) {
    removeVideo(id: $id)
  }
`

export const ADD_VIDEOS_TO_PLAYIST = gql`
  mutation AddVideosToPlaylist($id: String!, $videoIds: [String!]!) {
    addVideosToPlaylist(id: $id, videoIds: $videoIds) {
      ...PlaylistCardData
    }
  }
  ${PlaylistGrid.Fragment}
`
