import React from 'react'
import ReactMarkdown from 'react-markdown'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  a {
    text-decoration: underline;
  }
`

export const LinkifyText = (props: { children: string; noParagraphs?: boolean }) => (
  <Wrapper>
    <ReactMarkdown
      source={props.children}
      linkTarget="_blank"
      allowedTypes={['root', 'text', 'paragraph', 'link']}
      renderers={props.noParagraphs ? { paragraph: 'span' } : undefined}
    />
  </Wrapper>
)
