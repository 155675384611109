import React from 'react'
import gql from 'graphql-tag'
import { useViewType } from './SwitchViewButton'
import { VideoGrid } from './VideoGrid'
import { VideoTable } from './VideoTable'
import { Surface } from './Surface'
import { ChannelViewProps } from './ChannelView'

export const ChannelVideos = (props: ChannelViewProps) => {
  const { isGrid } = useViewType()
  return props.videos && props.videos.length ? (
    <Surface title="Videos" collapsable={isGrid}>
      {isGrid ? (
        <VideoGrid
          videos={props.videos}
          loadMore={props.loadMoreVideos}
          hasMoreVideos={props.hasMoreVideos}
        />
      ) : (
        <VideoTable
          videos={props.videos}
          label="Channel Videos"
          onCreatePlaylist={props.onCreatePlaylist}
          loadMore={props.loadMoreVideos}
          totalVideos={props.totalVideos}
        />
      )}
    </Surface>
  ) : null
}

ChannelVideos.Fragment = gql`
  fragment ChannelVideosData on Channel {
    _id
    videos(
      limit: $channelVideoLimit
      offset: $channelVideoOffset
      includeLive: $includeLive
      includeUnpublished: $includeUnpublished
      includeUnlisted: $includeUnlisted
    ) {
      ...VideoCardData
      ...VideoTableData
    }
  }
  ${VideoGrid.Fragment}
  ${VideoTable.Fragment}
`
