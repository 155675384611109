import EditIcon from '@material-ui/icons/Edit'
import gql from 'graphql-tag'
import React, { useContext, useState } from 'react'
import { HomeEdit } from '../components/HomeEdit'
import { PageItemsList } from '../components/PageItemsList'
import { PageLayout } from '../components/PageLayout'
import { Surface } from '../components/Surface'
import { useViewType } from '../components/SwitchViewButton'
import { TooltipFab } from '../components/TooltipFab'
import { UserContext } from '../components/UserProvider'
import { VideoGrid } from '../components/VideoGrid'
import { VideoTable } from '../components/VideoTable'
import {
  Channel,
  useGetActiveHomeConfigQuery,
  useUpdateConfigMutation,
  Video,
} from '../generated/graphql'

export const Home = () => {
  const user = useContext(UserContext)
  const { isGrid } = useViewType()
  const { data, refetch, loading } = useGetActiveHomeConfigQuery()
  const [update] = useUpdateConfigMutation()
  const [isEditMode, setIsEditMode] = useState(false)

  const videos = (data && data.adminGetActiveConfig
    ? data.adminGetActiveConfig.featuredVideos
    : []) as Video[]
  const navigationChannels = (data && data.adminGetActiveConfig
    ? data.adminGetActiveConfig.navigationChannels
    : []) as Channel[]

  return (user.isSuperAdmin || user.isAdmin) && isEditMode ? (
    <HomeEdit
      featuredVideos={videos.map(({ _id }) => _id)}
      navigationChannels={navigationChannels.filter(_id => _id !== null).map(({ _id }) => _id)}
      onSubmit={async (values: any) => {
        if (data && data.adminGetActiveConfig) {
          await update({
            variables: {
              id: data.adminGetActiveConfig._id,
              config: {
                isActive: true,
                featuredVideoIds: values.featuredVideos,
                navigationChannelIds: values.navigationChannels,
              },
            },
          })
          refetch()
          setIsEditMode(false)
        }
      }}
      onCancel={() => setIsEditMode(false)}
    />
  ) : (
    <PageLayout loading={loading}>
      <>
        <Surface title="Featured Videos" collapsable={isGrid}>
          {isGrid ? (
            <VideoGrid videos={videos} />
          ) : (
            <VideoTable videos={videos} label="Featured Videos" />
          )}
        </Surface>
        <Surface title="Navigation Channels" collapsable>
          <PageItemsList
            channels={navigationChannels}
            order={navigationChannels.filter(_id => _id !== null).map(({ _id }) => _id)}
          />
        </Surface>
        {user.isSuperAdmin || user.isAdmin ? (
          <TooltipFab
            tooltipLabel="Edit Home"
            aria-label="edit home"
            onClick={() => setIsEditMode(true)}
          >
            <EditIcon />
          </TooltipFab>
        ) : null}
      </>
    </PageLayout>
  )
}

export const GET_ACTIVE_HOME_CONFIG = gql`
  query GetActiveHomeConfig {
    adminGetActiveConfig {
      _id
      title
      featuredVideos {
        ...VideoTableData
        ...VideoCardData
      }
      navigationChannels {
        ...PageItemsListData
      }
    }
  }
  ${VideoTable.Fragment}
  ${VideoGrid.Fragment}
  ${PageItemsList.Fragment}
`

export const UPDATE_CONFIG = gql`
  mutation UpdateConfig($id: String!, $config: UpdateConfigInput!) {
    updateConfig(id: $id, config: $config) {
      _id
    }
  }
`
