import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import { TooltipProps } from '@material-ui/core/Tooltip'
import { ConfirmButton, ConfirmButtonVariants } from './ConfirmButton'
export { ConfirmButtonVariants as DeleteButtonVariants } from './ConfirmButton'

interface DeleteButtonProps {
  variant?: ConfirmButtonVariants
  className?: any
  onClick: () => Promise<any>
  labelPlacement?: TooltipProps['placement']
}

export const DeleteButton = (props: DeleteButtonProps) => (
  <ConfirmButton
    variant={props.variant}
    className={props.className}
    icon={<DeleteIcon />}
    label="Delete"
    confirmText="Are you sure you want to delete this?"
    onClick={props.onClick}
    labelPlacement={props.labelPlacement}
  />
)
