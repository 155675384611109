import { useState } from 'react'
import gql from 'graphql-tag'
import { useMutation, useApolloClient } from '@apollo/react-hooks'

const LOGIN = gql`
  mutation LoginAdmin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password)
  }
`

export const useLogin = () => {
  const client = useApolloClient()
  const [login] = useMutation(LOGIN)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const submit = async (email: string, password: string) => {
    setLoading(true)
    try {
      const { data } = await login({ variables: { email, password } })
      localStorage.setItem('token', data.loginAdmin)
      client.resetStore()
    } catch (e) {
      setError('Invalid Email / Password')
      setLoading(false)
    }
  }

  return { login: submit, error, loading }
}

export const useLogout = (noRedirect?: boolean) => {
  const client = useApolloClient()
  return () => {
    localStorage.clear()
    client.clearStore()
    if (!noRedirect) {
      window.location.href = '/'
    }
  }
}
