import React from 'react'
import gql from 'graphql-tag'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  IconButton,
  Avatar,
} from '@material-ui/core'
import DragIcon from '@material-ui/icons/DragHandle'
import DeleteIcon from '@material-ui/icons/Delete'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { Video } from '../generated/graphql'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    thumbnail: {
      width: 70,
      borderRadius: 4,
      marginRight: 15,
    },
  })
)

interface DraggbleVideoListProps {
  videos: Video[]
  onDragEnd: (result: DropResult) => void
  onRemove: (id: string) => void
}

export const DraggbleVideoList = (props: DraggbleVideoListProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <div className={classes.root}>
        <Droppable droppableId="video-list">
          {droppable => (
            <List
              component="ul"
              aria-label="video list"
              ref={droppable.innerRef}
              {...droppable.droppableProps}
            >
              {props.videos.map((video, i) => (
                <Draggable key={video._id} draggableId={video._id} index={i}>
                  {draggable => (
                    <ListItem
                      key={video._id}
                      ref={draggable.innerRef}
                      {...draggable.draggableProps}
                    >
                      <ListItemIcon>
                        <div {...draggable.dragHandleProps}>
                          <DragIcon />
                        </div>
                      </ListItemIcon>
                      <ListItemAvatar>
                        <Avatar
                          alt={video.title}
                          src={video.largeImage!}
                          className={classes.thumbnail}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={video.title} />
                      <IconButton aria-label="delete" onClick={() => props.onRemove(video._id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {droppable.placeholder}
            </List>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
}

DraggbleVideoList.Fragment = gql`
  fragment DraggbleVideoListData on Video {
    _id
    title
    largeImage
  }
`
