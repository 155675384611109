import React, { useState, useContext } from 'react'
import gql from 'graphql-tag'
import useReactRouter from 'use-react-router'
import { useMutation } from '@apollo/react-hooks'
import { Pages } from '../types'
import {
  PlaylistInput,
  UpdateChannelInput,
  Channel as ChannelType,
  useGetChannelPageQuery,
  UpdateChannelPlaylistsOrderMutation,
  GetChannelsPageQuery,
} from '../generated/graphql'
import { useCreatePlaylist } from '../lib/graphql/playlist'
import { useLoadMoreChannelVideos } from '../lib/graphql/video'
import { UserContext } from '../components/UserProvider'
import { PageLayout } from '../components/PageLayout'
import { ChannelView } from '../components/ChannelView'
import { ChannelEdit } from '../components/ChannelEdit'
import { ChannelPlaylistAdd } from '../components/ChannelPlaylistAdd'
import { GET_CHANNELS_PAGE, GET_CHANNELS_PAGE_VARS } from './Channels'

const useUpdateChannelPlaylistsOrder = (channelId: string) => {
  const [update] = useMutation<UpdateChannelPlaylistsOrderMutation>(UPDATE_CHANNEL_PLAYLISTS_ORDER)
  const updateChannelPlaylistsOrder = (order: string[]) =>
    update({
      variables: { id: channelId, order },
    })
  return updateChannelPlaylistsOrder
}

export const useUpdateChannel = (channelId: string) => {
  const [update] = useMutation(UPDATE_CHANNEL)
  const updateChannel = (channel: UpdateChannelInput) =>
    update({
      variables: { id: channelId, channel },
    })
  return updateChannel
}

const useRemoveChannel = (channelId: string) => {
  const { history } = useReactRouter()
  const [remove] = useMutation(REMOVE_CHANNEL)
  const removeChannel = async () => {
    const { data } = await remove({
      variables: { id: channelId },
      update(cache) {
        const channelsQuery = cache.readQuery({
          query: GET_CHANNELS_PAGE,
          variables: GET_CHANNELS_PAGE_VARS,
        }) as GetChannelsPageQuery
        if (channelsQuery && channelsQuery.getAdminChannels) {
          cache.writeQuery({
            query: GET_CHANNELS_PAGE,
            data: {
              getAdminChannels: channelsQuery.getAdminChannels.filter(
                ({ _id }) => _id !== channelId
              ),
            },
          })
        }
      },
    })

    if (data.removeChannel) {
      history.goBack()
    }
  }

  return removeChannel
}

export const useRemoveChannelFeaturedVideo = (channelId: string) => {
  const [remove] = useMutation(REMOVE_CHANNEL_FEATURED_VIDEO)
  const removeChannelFeaturedVideo = () => remove({ variables: { id: channelId } })
  return removeChannelFeaturedVideo
}

export const useRemoveChannelLiveStreamVideo = (channelId: string) => {
  const [remove] = useMutation(REMOVE_CHANNEL_LIVE_STREAM_VIDEO)
  const removeChannelLiveStreamVideo = () => remove({ variables: { id: channelId } })
  return removeChannelLiveStreamVideo
}

interface MatchParams {
  channelId: string
}

export const Channel = () => {
  const user = useContext(UserContext)
  const { match } = useReactRouter<MatchParams>()
  const { channelId } = match.params
  const [editOpen, setEditOpen] = useState(false)
  const [playlistVideoIds, setPlaylistVideoIds] = useState<string[]>([])
  const [createPlaylistOpen, setCreatePlaylistOpen] = useState(false)
  const createPlaylist = useCreatePlaylist()
  const updateChannel = useUpdateChannel(channelId)
  const removeChannel = useRemoveChannel(channelId)
  const updateChannelPlaylistsOrder = useUpdateChannelPlaylistsOrder(channelId)
  const removeChannelFeaturedVideo = useRemoveChannelFeaturedVideo(channelId)
  const removeChannelLiveStreamVideo = useRemoveChannelLiveStreamVideo(channelId)
  const { data, loading, fetchMore: fetchMoreVideos, refetch } = useGetChannelPageQuery({
    variables: {
      ...GET_CHANNEL_PAGE_VARS,
      id: channelId,
    },
  })
  const { loadMoreVideos, hasMoreVideos } = useLoadMoreChannelVideos(fetchMoreVideos)
  const toggleEdit = () => setEditOpen(!editOpen)
  const handleCreatePlaylist = (videoIds?: string[]) => {
    setPlaylistVideoIds(videoIds && videoIds.length ? videoIds : [])
    setCreatePlaylistOpen(true)
  }
  const createPlaylistAndReset = async (playlist: PlaylistInput) => {
    await createPlaylist(playlist)
    setPlaylistVideoIds([])
  }
  const onSubmitEdit = async (channel: UpdateChannelInput) => {
    await updateChannel(channel)
    await refetch()
  }
  const channel = (data && data.getAdminChannel) as ChannelType

  return (
    <PageLayout loading={loading}>
      <>
        <ChannelView
          {...channel}
          variant={Pages.channel}
          removeChannel={removeChannel}
          removeChannelFeaturedVideo={removeChannelFeaturedVideo}
          removeChannelLiveStreamVideo={removeChannelLiveStreamVideo}
          updateChannelPlaylistsOrder={updateChannelPlaylistsOrder}
          toggleEdit={toggleEdit}
          onCreatePlaylist={handleCreatePlaylist}
          loadMoreVideos={loadMoreVideos}
          hasMoreVideos={hasMoreVideos}
        />
        {user.isSuperAdmin ||
        user.isAdmin ||
        user.isChannelCollaborator(channel ? channel._id : '') ? (
          <ChannelEdit {...channel} open={editOpen} close={toggleEdit} onSubmit={onSubmitEdit} />
        ) : null}
        {user.isSuperAdmin ||
        user.isAdmin ||
        user.isChannelCollaborator(channel ? channel._id : '') ? (
          <ChannelPlaylistAdd
            channelId={channel && channel._id}
            videoIds={playlistVideoIds}
            selectVideos={!playlistVideoIds.length}
            open={createPlaylistOpen}
            setOpen={setCreatePlaylistOpen}
            onSubmit={createPlaylistAndReset}
          />
        ) : null}
      </>
    </PageLayout>
  )
}

export const GET_CHANNEL_PAGE = gql`
  query GetChannelPage(
    $id: String!
    $includeLive: Boolean
    $includeUnpublished: Boolean
    $includeUnlisted: Boolean
    $channelVideoLimit: Float
    $channelVideoOffset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    videoPageLimit @client @export(as: "channelVideoLimit")
    getAdminChannel(id: $id) {
      _id
      ...ChannelViewData
      ...ChannelEditData
    }
  }
  ${ChannelView.Fragment}
  ${ChannelEdit.Fragment}
`

export const GET_CHANNEL_PAGE_VARS = {
  includeLive: true,
  includeUnpublished: true,
  includeUnlisted: true,
  playlistVideoLimit: 4,
}

export const UPDATE_CHANNEL_PLAYLISTS_ORDER = gql`
  mutation UpdateChannelPlaylistsOrder(
    $id: String!
    $order: [String!]!
    $includeLive: Boolean
    $includeUnlisted: Boolean
    $includeUnpublished: Boolean
    $channelVideoLimit: Float
    $channelVideoOffset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    videoPageLimit @client @export(as: "playlistVideoLimit")
    updateChannelPlaylistsOrder(id: $id, order: $order) {
      _id
      ...ChannelViewData
      ...ChannelEditData
    }
  }
  ${ChannelView.Fragment}
  ${ChannelEdit.Fragment}
`

export const UPDATE_CHANNEL = gql`
  mutation UpdateChannel(
    $id: String!
    $channel: UpdateChannelInput!
    $includeLive: Boolean
    $includeUnlisted: Boolean
    $includeUnpublished: Boolean
    $channelVideoLimit: Float
    $channelVideoOffset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    updateChannel(id: $id, channel: $channel) {
      ...ChannelViewData
      ...ChannelEditData
    }
  }
  ${ChannelView.Fragment}
  ${ChannelEdit.Fragment}
`

export const REMOVE_CHANNEL = gql`
  mutation RemoveChannel($id: String!) {
    removeChannel(id: $id)
  }
`

export const REMOVE_CHANNEL_FEATURED_VIDEO = gql`
  mutation RemoveChannelFeaturedVideo(
    $id: String!
    $includeLive: Boolean
    $includeUnlisted: Boolean
    $includeUnpublished: Boolean
    $channelVideoLimit: Float
    $channelVideoOffset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    removeChannelFeaturedVideo(id: $id) {
      ...ChannelViewData
      ...ChannelEditData
    }
  }
  ${ChannelView.Fragment}
  ${ChannelEdit.Fragment}
`

export const REMOVE_CHANNEL_LIVE_STREAM_VIDEO = gql`
  mutation RemoveChannelLiveStreamVideo(
    $id: String!
    $includeLive: Boolean
    $includeUnlisted: Boolean
    $includeUnpublished: Boolean
    $channelVideoLimit: Float
    $channelVideoOffset: Float
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    removeChannelLiveStreamVideo(id: $id) {
      ...ChannelViewData
      ...ChannelEditData
    }
  }
  ${ChannelView.Fragment}
  ${ChannelEdit.Fragment}
`
