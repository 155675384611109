import React, { useState, useContext, createContext } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import ListViewIcon from '@material-ui/icons/ViewList'
import GridViewIcon from '@material-ui/icons/ViewModule'

export enum ViewTypes {
  table = 'Table',
  grid = 'Grid',
}

export const SwitchViewbutton = () => {
  const { viewType, toggleViewType } = useViewType()
  return (
    <Tooltip title={viewType} placement="left">
      <IconButton onClick={toggleViewType}>
        {viewType === ViewTypes.table ? <ListViewIcon /> : <GridViewIcon />}
      </IconButton>
    </Tooltip>
  )
}

export const ViewTypeContext = createContext({
  viewType: ViewTypes.grid,
  isGrid: true,
  isTable: false,
  toggleViewType: () => {},
})

export const ViewTypeProvider = (props: { children: JSX.Element }) => {
  const [viewType, setViewType] = useState<ViewTypes>(ViewTypes.grid)
  const toggleViewType = () => {
    switch (viewType) {
      case ViewTypes.grid:
        setViewType(ViewTypes.table)
        break
      case ViewTypes.table:
        setViewType(ViewTypes.grid)
        break
      default:
        setViewType(ViewTypes.grid)
        break
    }
  }
  return (
    <ViewTypeContext.Provider
      value={{
        viewType,
        toggleViewType,
        isGrid: viewType === ViewTypes.grid,
        isTable: viewType === ViewTypes.table,
      }}
    >
      {props.children}
    </ViewTypeContext.Provider>
  )
}

export const useViewType = () => useContext(ViewTypeContext)
