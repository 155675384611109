import { useState, useRef, useEffect } from 'react'
import { debounce, uniqBy } from 'lodash'

interface UsePagableQueryArgs {
  query: any
  queryVars: any
  queryName: string
  itemsName?: string
  limit: number
  lazy?: boolean
  reloader?: string
  fromPrev?: boolean
}

const getQueryItems = (args: UsePagableQueryArgs) => {
  const { query, queryVars, limit, lazy } = args
  if (lazy) {
    const [load, { called, data, loading, fetchMore }] = query
    return { load, called, data, loading, fetchMore }
  }
  const { called, data, loading, fetchMore } = query({ variables: { ...queryVars, limit } })
  return { called, data, loading, fetchMore }
}

export const usePagableQuery = (args: UsePagableQueryArgs) => {
  const { queryVars, limit, queryName, itemsName, lazy, reloader, fromPrev } = args
  const { load, called, data, loading, fetchMore } = getQueryItems(args)
  const [hasMore, setHasMore] = useState(true)
  const page = useRef(0)
  const fetchMoreFunc = useRef<any>(fetchMore)

  useEffect(() => {
    fetchMoreFunc.current = fetchMore
    if (lazy && reloader) {
      load({ variables: { ...queryVars, limit } })
    }
    return () => {}
  }, [fetchMore, reloader])

  useEffect(() => {
    if (reloader) reset()
  }, [reloader])

  const loadMore = debounce(
    async () => {
      if (hasMore && fetchMoreFunc.current) {
        const newVideoPage =
          fromPrev && data && data[queryName] && itemsName && data[queryName][itemsName]
            ? Math.floor(data[queryName][itemsName].length / limit)
            : page.current + 1
        const newOffset = newVideoPage * limit
        page.current = newVideoPage
        await new Promise(resolve => {
          fetchMoreFunc.current({
            variables: { ...queryVars, limit, offset: newOffset },
            updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
              if (!fetchMoreResult || !previousResult) return previousResult
              if (itemsName) {
                const items = uniqBy(
                  previousResult[queryName][itemsName].concat(
                    fetchMoreResult[queryName][itemsName]
                  ),
                  '_id'
                )
                if (
                  !fetchMoreResult ||
                  !fetchMoreResult[queryName] ||
                  !fetchMoreResult[queryName][itemsName] ||
                  !fetchMoreResult[queryName][itemsName].length ||
                  fetchMoreResult[queryName][itemsName].length < limit
                ) {
                  setHasMore(false)
                }
                setTimeout(() => resolve(), 1)
                return Object.assign({}, previousResult, {
                  [queryName]: {
                    ...previousResult[queryName],
                    [itemsName]: items,
                  },
                })
              } else {
                const items = uniqBy(
                  previousResult[queryName].concat(fetchMoreResult[queryName]),
                  '_id'
                )
                if (
                  !fetchMoreResult ||
                  !fetchMoreResult[queryName] ||
                  !fetchMoreResult[queryName].length ||
                  fetchMoreResult[queryName].length < limit
                ) {
                  setHasMore(false)
                }
                setTimeout(() => resolve(), 1)
                return Object.assign({}, previousResult, {
                  [queryName]: items,
                })
              }
            },
          })
        })
      }
    },
    1000,
    { leading: true }
  )

  const reset = () => {
    page.current = 0
    setHasMore(true)
  }

  const items = itemsName
    ? ((data && data[queryName] ? data[queryName][itemsName] : []) as any[])
    : ((data && data[queryName] ? data[queryName] : []) as any[])

  return {
    items,
    loading,
    hasMore: !!(called && items.length && hasMore),
    loadMore,
    reset,
    called,
    data,
  }
}
