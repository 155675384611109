import React, { useEffect, useState, useRef } from 'react'
import gql from 'graphql-tag'
import { uniqBy, debounce } from 'lodash'
import useReactRouter from 'use-react-router'
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import InfiniteScroll from 'react-infinite-scroller'
import { User, useGetAllUsersQuery } from '../generated/graphql'
import { USER_PAGE_SIZE, LOAD_MORE_DEBOUCE_MS } from '../consts'
import { PageLoader } from './PageLoader'
import { Surface } from './Surface'
import { Loader } from './Loader'

const useLoadMoreUsers = (fetchMoreUsers: any, queryName: string) => {
  const [hasMoreUsers, setHasMoreUsers] = useState(true)
  const page = useRef(0)
  const fetchMoreUsersFunc = useRef<any>()

  useEffect(() => {
    fetchMoreUsersFunc.current = fetchMoreUsers
    return () => {}
  }, [fetchMoreUsers])

  const loadMoreUsers = debounce(() => {
    if (hasMoreUsers) {
      const newVideoPage = page.current + 1
      page.current = newVideoPage
      fetchMoreUsersFunc.current({
        variables: { offset: newVideoPage * USER_PAGE_SIZE },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev
          const users = uniqBy([...prev[queryName], ...fetchMoreResult[queryName]], '_id')
          if (
            !fetchMoreResult ||
            !fetchMoreResult[queryName] ||
            !fetchMoreResult[queryName].length ||
            fetchMoreResult[queryName].length < USER_PAGE_SIZE
          ) {
            setHasMoreUsers(false)
          }
          return Object.assign({}, prev, {
            [queryName]: users,
          })
        },
      })
    }
  }, LOAD_MORE_DEBOUCE_MS)

  const resetLoadMoreUsers = () => {
    page.current = 0
    setHasMoreUsers(true)
  }
  return { loadMoreUsers, resetLoadMoreUsers, hasMoreUsers }
}

interface UserListProps {
  searchValue?: string
}

export const UserList = (props: UserListProps) => {
  const router = useReactRouter()
  const { data, called, loading, refetch, fetchMore } = useGetAllUsersQuery()
  const { loadMoreUsers, hasMoreUsers } = useLoadMoreUsers(fetchMore, 'getAllUsers')
  useEffect(() => {
    refetch({ query: props.searchValue })
    return () => {}
  }, [props.searchValue])

  const users = (data && data.getAllUsers ? data.getAllUsers : []) as User[]
  const content = users.map(({ _id, username }) => (
    <ListItem key={_id} button onClick={() => router.history.push(`/users/${username}`)}>
      <ListItemText primary={username} />
      <ListItemSecondaryAction>
        <Tooltip title="View Profile" placement="left">
          <IconButton edge="end" href={`/users/${username}`}>
            <ArrowForwardIcon />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  ))

  return (
    <Surface title="Users">
      <List>
        {!called || loading ? (
          <PageLoader />
        ) : loadMoreUsers ? (
          <InfiniteScroll
            useWindow={true}
            pageStart={0}
            threshold={50}
            loader={<Loader key={0} />}
            hasMore={hasMoreUsers}
            loadMore={loadMoreUsers}
          >
            {content}
          </InfiniteScroll>
        ) : (
          content
        )}
      </List>
    </Surface>
  )
}

export const GET_ALL_USERS = gql`
  query GetAllUsers($limit: Float, $offset: Float, $query: String) {
    userPageLimit @client @export(as: "limit")
    getAllUsers(limit: $limit, offset: $offset, query: $query) {
      _id
      username
    }
  }
`
