import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { useError } from '../components/ErrorModal'
import { TooltipFab } from '../components/TooltipFab'
import { useUpdateSaleMutation } from '../generated/graphql'

interface SalesTextUpdateProps {
  _id: string
  text: string
  description: string
  url: string
  videoUploadId: string
  textSale: string
}

export const SalesTextUpdate = (props: SalesTextUpdateProps) => {
  const [open, setOpen] = useState(true)
  const [update] = useUpdateSaleMutation()
  const toggleOpen = () => setOpen(!open)
  const { setErrorMessage } = useError()
  return (
    <>
      <TooltipFab tooltipLabel="Update Sale" aria-label="Update sale" onClick={toggleOpen}>
        <AddIcon />
      </TooltipFab>
      <Formik
        initialValues={{
          id: props._id,
          text: props.text,
          description: props.description,
          url: props.url,
          videoUploadId: props.videoUploadId,
          textSale: props.textSale,
        }}
        validate={values => {
          let errors: any = {}
          if (!values.text) {
            errors.text = 'Required'
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            await update({
              variables: {
                id: values.id,
                text: values.text,
                description: values.description,
                url: values.url,
                textSale: values.textSale,
              },
            })
          } catch (e) {
            setErrorMessage(e.message)
          }
          setSubmitting(false)
          setOpen(false)
          resetForm()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => {
          const close = () => {
            resetForm()
            toggleOpen()
          }
          return (
            <>
              <Dialog
                open={open}
                onClose={close}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle id="form-dialog-title">Update Sale</DialogTitle>
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="text"
                      label="Video Sale Title"
                      name="text"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.text}
                    />
                    {touched.text && errors.text ? (
                      <FormHelperText error>{errors.text}</FormHelperText>
                    ) : null}
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="description"
                      label="Video Sale Description"
                      name="description"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="url"
                      label="Promo URL"
                      name="url"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.url}
                    />
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="textSale"
                      label="Sale Text for Description of Videos - use mark down for clickable text. Example: [Click Here To Visit Link](https://infowars.com/)"
                      name="textSale"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.textSale}
                    />
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={close} disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button type="submit" color="secondary" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </>
          )
        }}
      </Formik>
    </>
  )
}
