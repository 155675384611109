import React, { useState } from 'react'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import useReactRouter from 'use-react-router'
import { Theme, createStyles, useTheme, makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, TextField, Button, CircularProgress } from '@material-ui/core'
import background from '../assets/images/login-background.jpg'
import { useResetPasswordAdminTokenMutation } from '../generated/graphql'

interface MatchParams {
  token: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      marginBottom: 40,
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      maxWidth: 450,
    },
    formWrapper: {
      padding: theme.spacing(3, 2),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    title: {
      paddingBottom: theme.spacing(2),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    forgot: {
      color: '#939393',
      padding: 10,
      cursor: 'pointer',
    },
  })
)

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  filter: blur(3px);
  -webkit-filter: blur(3px);
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

export const ForgotPasswordToken = () => {
  const { match } = useReactRouter<MatchParams>()
  const { token } = match.params
  const theme = useTheme()
  const classes = useStyles(theme)
  const [resetPasswordAdminToken, { loading }] = useResetPasswordAdminTokenMutation()
  const [error, setError] = useState('')
  const [values, setValues] = useState({
    newPassword: '',
    newPasswordConfirmed: '',
  })

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [name]: event.target.value })
  }

  const onSubmit = async (e?: any) => {
    if (e && e.preventDefault) e.preventDefault()
    const { newPassword, newPasswordConfirmed } = values
    try {
      const { data } = await resetPasswordAdminToken({
        variables: {
          token,
          newPassword,
          newPasswordConfirmed,
        },
      })
      if (data && data.resetPasswordAdminToken) {
        window.location.href = '/login'
      } else {
        setError('An error has occured.')
      }
    } catch (err) {
      if (err.message.indexOf('EXPIRED') !== -1) {
        setError('This token has expired.')
      } else {
        setError('An error has occured.')
      }
    }
  }

  return (
    <Wrapper>
      <Background />
      <div className={classes.root}>
        <Paper className={classes.formWrapper}>
          <Typography variant="h4" component="h3" align="center" className={classes.title}>
            Reset Password
          </Typography>
          {error && (
            <Typography variant="body1" gutterBottom align="center" color="error">
              {error}
            </Typography>
          )}
          <form id="resetPasswordTokenForm" onSubmit={onSubmit} className={classes.form}>
            <TextField
              id="new-password"
              label="New password"
              className={classes.textField}
              value={values.newPassword}
              onChange={handleChange('newPassword')}
              margin="normal"
              variant="outlined"
              type="password"
            />
            <TextField
              id="confirm-new-password"
              label="Confirm new password"
              className={classes.textField}
              value={values.newPasswordConfirmed}
              onChange={handleChange('newPasswordConfirmed')}
              margin="normal"
              variant="outlined"
              type="password"
            />
          </form>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={onSubmit}
            type="submit"
            form="loginForm"
            disabled={loading}
          >
            {loading ? <CircularProgress size="26px" /> : 'Submit'}
          </Button>
        </Paper>
      </div>
    </Wrapper>
  )
}

export const RESET_PASSWORD_ADMIN_TOKEN = gql`
  mutation ResetPasswordAdminToken(
    $token: String!
    $newPassword: String!
    $newPasswordConfirmed: String!
  ) {
    resetPasswordAdminToken(
      token: $token
      newPassword: $newPassword
      newPasswordConfirmed: $newPasswordConfirmed
    )
  }
`
