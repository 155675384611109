import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { orderBy } from 'lodash'
import useReactRouter from 'use-react-router'
import { useMutation } from '@apollo/react-hooks'
import {
  Channel,
  useGetChannelsPageQuery,
  GetChannelsPageQuery,
  ChannelInput,
} from '../generated/graphql'
import { UserContext } from '../components/UserProvider'
import { Surface } from '../components/Surface'
import { PageLayout } from '../components/PageLayout'
import { ChannelList } from '../components/ChannelList'
import { ChannelTable } from '../components/ChannelTable'
import { ChannelAdd } from '../components/ChannelAdd'
import { useViewType } from '../components/SwitchViewButton'

const useCreateChannel = () => {
  const { history } = useReactRouter()
  const [create] = useMutation(CREATE_CHANNEL)
  const createChannel = async (channel: ChannelInput) => {
    const { data } = await create({
      variables: { channel },
      update(cache, { data }) {
        const channelsQuery = cache.readQuery({
          query: GET_CHANNELS_PAGE,
          variables: GET_CHANNELS_PAGE_VARS,
        }) as GetChannelsPageQuery
        if (channelsQuery && channelsQuery.getAdminChannels) {
          cache.writeQuery({
            query: GET_CHANNELS_PAGE,
            data: {
              getAdminChannels: [data.createChannel, ...channelsQuery.getAdminChannels],
            },
          })
        }
      },
    })

    if (data && data.createChannel) {
      history.push(`/channels/${data.createChannel._id}`)
    }
  }

  return createChannel
}

export const Channels = () => {
  const user = useContext(UserContext)
  const { data, loading } = useGetChannelsPageQuery({ variables: GET_CHANNELS_PAGE_VARS })
  const { isGrid } = useViewType()
  const createChannel = useCreateChannel()
  const channels = (data && data.getAdminChannels) as Channel[]
  const displayChannels = orderBy(channels, 'title', 'asc')
  return (
    <PageLayout loading={loading || !channels}>
      <>
        {isGrid ? (
          <ChannelList channels={displayChannels} />
        ) : (
          <Surface>
            <ChannelTable channels={displayChannels} label="All Channels" />
          </Surface>
        )}
        {user.isSuperAdmin || user.isAdmin ? <ChannelAdd onSubmit={createChannel} /> : null}
      </>
    </PageLayout>
  )
}

export const GET_CHANNELS_PAGE = gql`
  query GetChannelsPage($channelVideoLimit: Float, $channelVideoOffset: Float) {
    getAdminChannels {
      ...ChannelListItemData
      ...ChannelTableData
    }
  }
  ${ChannelList.Fragment}
  ${ChannelTable.Fragment}
`

export const GET_CHANNELS_PAGE_VARS = { channelVideoLimit: 4 }

export const CREATE_CHANNEL = gql`
  mutation CreateChannel(
    $channel: ChannelInput!
    $channelVideoLimit: Float
    $channelVideoOffset: Float
  ) {
    createChannel(channel: $channel) {
      ...ChannelListItemData
      ...ChannelTableData
    }
  }
  ${ChannelList.Fragment}
  ${ChannelTable.Fragment}
`
