import React from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Playlist } from '../generated/graphql'
import { GridVariants } from '../types'
import { PlaylistCard } from './PlaylistCard'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
)

interface PlaylistGridProps {
  playlists: Playlist[]
  variant?: GridVariants
  selectedItems?: string[]
  onSelect?: (id: string) => void
}

export const PlaylistGrid = (props: PlaylistGridProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {props.playlists && props.playlists.length
          ? props.playlists.map(playlist => (
              <Grid key={playlist._id} item xs={12} sm={6} md={4} lg={3}>
                <PlaylistCard
                  {...playlist}
                  variant={props.variant}
                  selectedItems={props.selectedItems}
                  onSelect={props.onSelect}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </div>
  )
}

PlaylistGrid.Fragment = PlaylistCard.Fragment
