import { TableBody as MUITableBody, TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { Data } from './'

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(el => el[0])
}

type Order = 'asc' | 'desc'

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (a: { [key in K]: any }, b: { [key in K]: any }) => number {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

interface TableBodyProps<D> {
  rows: D[]
  order: Order
  orderBy: keyof D
  page: number
  rowsPerPage: number
  selected: string[]
  handleClick: (event: React.MouseEvent<unknown>, id: string) => void
  children: (props: {
    row: D
    handleClick: (event: React.MouseEvent<unknown>, id: string) => void
    isItemSelected: boolean
    labelId: string
  }) => JSX.Element | null
}

export const TableBody = (props: TableBodyProps<Data>) => {
  const { rows, order, orderBy, page, rowsPerPage, selected, handleClick, children } = props
  const isSelected = (id: string) => selected.indexOf(id) !== -1
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <MUITableBody>
      {stableSort(rows, getSorting(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: any, index) => {
          const isItemSelected = isSelected(row.id)
          const labelId = `enhanced-table-checkbox-${index}`
          return children({ row, handleClick, isItemSelected, labelId })
        })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 49 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </MUITableBody>
  )
}
