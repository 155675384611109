import React, { useState, useContext } from 'react'
import gql from 'graphql-tag'
import useReactRouter from 'use-react-router'
import { useMutation } from '@apollo/react-hooks'
import { Pages } from '../types'
import {
  useGetPlaylistPageQuery,
  useGetPlaylistVideoIdsQuery,
  Playlist as PlaylistType,
  UpdatePlaylistInput,
  GetPlaylistPageQuery,
  GetPlaylistsPageQuery,
} from '../generated/graphql'
import { useLoadMorePlaylistVideos } from '../lib/graphql/video'
import { UserContext } from '../components/UserProvider'
import { PageLayout } from '../components/PageLayout'
import { PlaylistListItem } from '../components/PlaylistList/PlaylistListItem'
import { PlaylistEdit } from '../components/PlaylistEdit'
import { GET_PLAYLISTS_PAGE, GET_PLAYLISTS_PAGE_VARS } from './Playlists'

export const useUpdatePlaylist = (playlistId: string) => {
  const [update] = useMutation(UPDATE_PLAYLIST)
  const updatePlaylist = async (playlist: UpdatePlaylistInput) => {
    await update({
      variables: { id: playlistId, playlist },
      update(cache, { data }) {
        try {
          const options = {
            query: GET_PLAYLISTS_PAGE,
            variables: GET_PLAYLISTS_PAGE_VARS,
          }
          const allPlaylistsQuery = cache.readQuery(options) as GetPlaylistsPageQuery
          if (allPlaylistsQuery && allPlaylistsQuery.getAdminPlaylists) {
            cache.writeQuery({
              ...options,
              data: {
                getAdminPlaylists: allPlaylistsQuery.getAdminPlaylists.map(p =>
                  p._id === playlistId ? data.updatePlaylist : p
                ),
              },
            })
          }
        } catch (e) {
          console.log('ERROR', e)
        }

        try {
          const options = {
            query: GET_PLAYLIST_PAGE,
            variables: { id: playlistId },
          }
          const playlistQuery = cache.readQuery(options) as GetPlaylistPageQuery
          if (playlistQuery && playlistQuery.getAdminPlaylist) {
            cache.writeQuery({
              ...options,
              data: {
                getAdminPlaylist: data.updatePlaylist,
              },
            })
          }
        } catch (e) {
          console.log('ERROR', e)
        }
      },
    })
  }

  return updatePlaylist
}

export const useRemovePlaylist = (playlistId: string, channelId?: string) => {
  const { history } = useReactRouter()
  const [remove] = useMutation(REMOVE_PLAYLIST)
  const removePlaylist = async () => {
    const { data } = await remove({
      variables: { id: playlistId },
      update(cache) {
        try {
          const options = {
            query: GET_PLAYLISTS_PAGE,
            variables: GET_PLAYLISTS_PAGE_VARS,
          }
          const allPlaylistsQuery = cache.readQuery(options) as GetPlaylistsPageQuery
          if (allPlaylistsQuery && allPlaylistsQuery.getAdminPlaylists) {
            cache.writeQuery({
              ...options,
              data: {
                getAdminPlaylists: allPlaylistsQuery.getAdminPlaylists.filter(
                  ({ _id }) => _id !== playlistId
                ),
              },
            })
          }
        } catch (e) {
          console.log('ERROR', e)
        }

        // if (channelId) {
        //   try {
        //     const id = `Channel:${channelId}`
        //     const options = {
        //       fragment: CHANNEL_PLAYLISTS,
        //       fragmentName: 'ChannelPlaylists',
        //       variables: { limit: VIDEO_PAGE_SIZE },
        //       id,
        //     }
        //     const channelPlaylists = cache.readFragment(options) as ChannelPlaylistsFragment
        //     cache.writeFragment({
        //       ...options,
        //       data: {
        //         ...channelPlaylists,
        //         playlists: channelPlaylists.playlists
        //           ? channelPlaylists.playlists.filter(({ _id }) => _id !== playlistId)
        //           : [],
        //       },
        //     })
        //   } catch (e) {
        //     console.log('ERROR', e)
        //   }
        // }
      },
    })

    if (data.removePlaylist) {
      history.goBack()
    }
  }

  return removePlaylist
}

interface MatchParams {
  playlistId: string
}

export const Playlist = () => {
  const user = useContext(UserContext)
  const { match } = useReactRouter<MatchParams>()
  const { playlistId } = match.params
  const { data, loading, fetchMore: fetchMoreVideos } = useGetPlaylistPageQuery({
    variables: { id: playlistId },
  })
  const { loadMoreVideos, hasMoreVideos } = useLoadMorePlaylistVideos(fetchMoreVideos)
  const { data: playlistVideoIdsData, refetch } = useGetPlaylistVideoIdsQuery({
    variables: { id: playlistId },
  })
  const playlist = (data && data.getAdminPlaylist) as PlaylistType
  const updatePlaylist = useUpdatePlaylist(playlistId)
  const channelId =
    playlist && !playlist.isGlobal && playlist.channel ? playlist.channel._id : undefined
  const removePlaylist = useRemovePlaylist(playlistId, channelId)
  const [editOpen, setEditOpen] = useState(false)
  const toggleEdit = () => setEditOpen(!editOpen)
  const videoIds =
    playlistVideoIdsData && playlistVideoIdsData.getAdminPlaylist
      ? playlistVideoIdsData.getAdminPlaylist.videoIds
      : []
  const onSubmitEdit = async (playlist: UpdatePlaylistInput) => {
    await updatePlaylist(playlist)
    await refetch()
  }

  return (
    <PageLayout loading={loading}>
      <>
        <PlaylistListItem
          {...playlist}
          variant={Pages.playlist}
          removePlaylist={removePlaylist}
          toggleEdit={toggleEdit}
          loadMoreVideos={loadMoreVideos}
          hasMoreVideos={hasMoreVideos}
        />
        {user.isSuperAdmin || user.isAdmin || user.isChannelCollaborator(channelId || '') ? (
          <PlaylistEdit
            {...playlist}
            open={editOpen}
            close={toggleEdit}
            onSubmit={onSubmitEdit}
            videoIds={videoIds}
          />
        ) : null}
      </>
    </PageLayout>
  )
}

export const GET_PLAYLIST_PAGE = gql`
  query GetPlaylistPage($id: String!, $playlistVideoLimit: Float, $playlistVideoOffset: Float) {
    videoPageLimit @client @export(as: "playlistVideoLimit")
    getAdminPlaylist(id: $id) {
      ...PlaylistListItemData
    }
  }
  ${PlaylistListItem.Fragment}
`

export const GET_PLAYLIST_VIDEO_IDS = gql`
  query GetPlaylistVideoIds($id: String!) {
    getAdminPlaylist(id: $id) {
      _id
      videoIds
    }
  }
`

export const UPDATE_PLAYLIST = gql`
  mutation UpdatePlaylist(
    $id: String!
    $playlist: UpdatePlaylistInput!
    $playlistVideoLimit: Float
    $playlistVideoOffset: Float
  ) {
    videoPageLimit @client @export(as: "playlistVideoLimit")
    updatePlaylist(id: $id, playlist: $playlist) {
      ...PlaylistListItemData
    }
  }
  ${PlaylistListItem.Fragment}
`

export const REMOVE_PLAYLIST = gql`
  mutation RemovePlaylist($id: String!) {
    removePlaylist(id: $id)
  }
`
