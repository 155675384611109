import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import { uniq } from 'lodash'
import { Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { useApolloClient } from '@apollo/react-hooks'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import MUIBreadcrumbs from '@material-ui/core/Breadcrumbs'
import CircularProgress from '@material-ui/core/CircularProgress'
import { routes, BreadcrumbPage } from '../Router'
import { Channel, Playlist } from '../../generated/graphql'
import theme from '../../lib/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breakcrumbs: {
      marginBottom: theme.spacing(3),
    },
    lastCrumb: {
      cursor: 'pointer',
    },
  })
)

const useGetCrumbs = (path: string) => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(true)
  const [breadCrumbs, setBreadCrumbs] = useState<Array<{ name: string; link: string }>>([])
  const crumbs = (path === '/' ? [''] : path.split('/')) as BreadcrumbPage[]
  let useLast = false
  let lastName = ''
  let currentPath = ''

  const getBreadcrumbs = async () => {
    setLoading(true)
    const bcs = await Promise.all(
      crumbs.map(async (c, i) => {
        if (i === crumbs.length - 1 && i !== 0 && c === '') return null
        if (!!(routes[c] && routes[c].getBreadcrumb)) {
          const ret = routes[c].getBreadcrumb!({ useLast, lastName, currentPath })
          useLast = ret.useLast
          lastName = ret.lastName
          currentPath = ret.currentPath
          return ret.none ? null : { name: ret.name, link: ret.link }
        } else {
          if (useLast) {
            useLast = false
            currentPath = currentPath + `${crumbs[i - 1]}/${c}`
            const crumb = { name: lastName, link: uniq(currentPath.split('/')).join('/') }
            if (crumb.name === 'User') {
              crumb.name = c
            } else if (crumb.name === 'Channel') {
              let channel
              try {
                channel = client.readFragment({
                  id: `Channel:${c}`,
                  fragment: CHANNEL_FIELDS,
                }) as Channel
              } catch (e) {
                console.log('ERROR', e)
              }

              if (channel && channel.title) {
                crumb.name = channel.title
              } else {
                try {
                  channel = client.readFragment({
                    id: `Channel:${c}`,
                    fragment: CHANNEL_FIELDS_EXTENDED,
                  }) as Channel
                } catch (e) {
                  console.log('ERROR', e)
                }

                if (channel && channel.title) {
                  crumb.name = channel.title
                } else {
                  const { data } = await client.query({
                    query: GET_CHANNEL_FIELDS,
                    variables: { id: c },
                  })
                  if (data && data.getAdminChannel && data.getAdminChannel.title) {
                    crumb.name = data.getAdminChannel.title
                  }
                }
              }
            } else if (crumb.name === 'Playlist') {
              const playlist = client.readFragment({
                id: `Playlist:${c}`,
                fragment: PLAYLIST_FIELDS,
              }) as Playlist

              if (playlist && playlist.title) {
                crumb.name = playlist.title
              } else {
                const { data } = await client.query({
                  query: GET_PLAYLIST_FILEDS,
                  variables: { id: c },
                })
                if (data && data.getAdminPlaylist && data.getAdminPlaylist.title) {
                  crumb.name = data.getAdminPlaylist.title
                }
              }
            }
            return crumb
          }
          return null
        }
      })
    )

    const filteredBcs = bcs.filter(c => c !== null) as Array<{ name: string; link: string }>
    setBreadCrumbs(filteredBcs)
    setLoading(false)
  }

  useEffect(() => {
    getBreadcrumbs()
    return () => {}
  }, [path])

  return { loading, breadCrumbs }
}

export const Breadcrumbs = () => {
  const { location } = useReactRouter()
  const classes = useStyles(theme)
  const { loading, breadCrumbs } = useGetCrumbs(location.pathname)
  return (
    <MUIBreadcrumbs aria-label="breadcrumb" className={classes.breakcrumbs}>
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        breadCrumbs.map(({ name, link }, i) =>
          i === breadCrumbs.length - 1 ? (
            <Typography key={link} color="textPrimary" className={classes.lastCrumb}>
              {name}
            </Typography>
          ) : (
            <Link key={link} to={link}>
              {name}
            </Link>
          )
        )
      )}
    </MUIBreadcrumbs>
  )
}

export const CHANNEL_FIELDS = gql`
  fragment ChannelFields on Channel {
    _id
    title
  }
`

export const CHANNEL_FIELDS_EXTENDED = gql`
  fragment ChannelFieldsExtended on Channel {
    _id
    title
  }
`

export const GET_CHANNEL_FIELDS = gql`
  query GetChannelFields($id: String!) {
    getAdminChannel(id: $id) {
      ...ChannelFields
    }
  }
  ${CHANNEL_FIELDS}
`

export const PLAYLIST_FIELDS = gql`
  fragment PlaylistFields on Playlist {
    _id
    title
    channel {
      _id
      title
    }
  }
`

export const GET_PLAYLIST_FILEDS = gql`
  query GetPlaylistFields($id: String!) {
    getAdminPlaylist(id: $id) {
      ...PlaylistFields
    }
  }
  ${PLAYLIST_FIELDS}
`
