import React from 'react'
import { Channel } from '../../generated/graphql'
import { ChannelListItem } from './ChannelListItem'

interface ChannelListProps {
  channels: Channel[]
}

export const ChannelList = (props: ChannelListProps) => {
  const { channels } = props
  return (
    <>
      {channels.length
        ? channels.map(channel => <ChannelListItem key={channel._id} {...channel} />)
        : null}
    </>
  )
}

ChannelList.Fragment = ChannelListItem.Fragment
