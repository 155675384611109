import React from 'react'
import gql from 'graphql-tag'
import { Switch, CircularProgress } from '@material-ui/core'
import {
  useGetHeaderSaleActiveQuery,
  useUpdateHeaderSaleMutation,
  GetHeaderSaleActiveQuery,
} from '../generated/graphql'
import { Surface } from './Surface'

export const SaleHeaderToggle = () => {
  const { data, loading } = useGetHeaderSaleActiveQuery()
  const [update] = useUpdateHeaderSaleMutation()
  const headerSaleActive =
    data && data.adminGetActiveConfig ? data.adminGetActiveConfig.headerSaleActive : false
  const configId = data && data.adminGetActiveConfig ? data.adminGetActiveConfig._id : ''
  const toggle = () => {
    const newValue = !headerSaleActive
    update({
      variables: { id: configId, config: { isActive: true, headerSaleActive: newValue } },
      optimisticResponse: {
        __typename: 'Mutation',
        updateConfig: {
          _id: configId,
          __typename: 'Config',
          headerSaleActive: newValue,
        },
      },
      update(cache, { data }) {
        if (data) {
          const config = cache.readQuery({
            query: GET_HEADER_SALE_ACTIVE,
          }) as GetHeaderSaleActiveQuery
          cache.writeQuery({
            query: GET_HEADER_SALE_ACTIVE,
            data: {
              adminGetActiveConfig: {
                ...config.adminGetActiveConfig,
                ...data.updateConfig,
              },
            },
          })
        }
      },
    })
  }
  return (
    <Surface
      title={'Toggle Header Sale'}
      noBody
      actions={
        loading ? (
          <CircularProgress color="secondary" size="32px" />
        ) : (
          <Switch checked={headerSaleActive} onChange={toggle} value={headerSaleActive} />
        )
      }
    />
  )
}

export const GET_HEADER_SALE_ACTIVE = gql`
  query GetHeaderSaleActive {
    adminGetActiveConfig {
      _id
      headerSaleActive
    }
  }
`

export const UPDATE_HEADER_SALE = gql`
  mutation UpdateHeaderSale($id: String!, $config: UpdateConfigInput!) {
    updateConfig(id: $id, config: $config) {
      _id
      headerSaleActive
    }
  }
`
