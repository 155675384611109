import React from 'react'
import { Tooltip, IconButton } from '@material-ui/core'
import { TooltipProps } from '@material-ui/core/Tooltip'

interface TooltipIconButtonProps {
  children: JSX.Element
  label: string
  placement?: TooltipProps['placement']
  onClick?: (x?: any) => void
  className?: any
  disabled?: boolean
}

export const TooltipIconButton = (props: TooltipIconButtonProps) => (
  <Tooltip title={props.label} placement={props.placement || 'bottom'} className={props.className}>
    <IconButton onClick={props.onClick} disabled={props.disabled}>
      {props.children}
    </IconButton>
  </Tooltip>
)
