import styled from '@emotion/styled'
import { CircularProgress, Typography } from '@material-ui/core'
import { WithTheme, withTheme } from '@material-ui/core/styles'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { uploadFile } from '../lib/upload'
import { AudioPlayer } from './AudioPlayer'
import { VideoPlayer } from './VideoPlayer'

const Wrapper = styled.div<{ active: boolean; spacing: any }>`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-style: ${props => (props.active ? 'solid' : 'dashed')};
  border-width: 2px;
  border-radius: 4px;
  cursor: pointer;
  padding: ${props => props.spacing}px;
`

const Progress = styled(CircularProgress)<{ done: boolean }>`
  position: absolute;
  left: calc(50% - 20px);
  top: calc(50% - 20px);
  opacity: ${props => (props.done ? 0 : 1)};
  transition: opacity 1s ease;
  z-index: 1;
`

const ItemWrapper = styled.div`
  width: 200px;
  border-radius: 8px;
`

export enum FileUploadVariants {
  video = 'VIDEO',
  image = 'IMAGE',
  audio = 'AUDIO',
}

const strings = {
  [FileUploadVariants.video]: 'Drag & drop a video file',
  [FileUploadVariants.image]: 'Drag & drop an image file',
  [FileUploadVariants.audio]: 'Drag & drop an audio file',
}

const mimeTypes = {
  [FileUploadVariants.video]: 'video/mp4',
  [FileUploadVariants.image]: 'image/png, image/jpeg, image/gif',
  [FileUploadVariants.audio]: 'audio/*',
}

const INITIAL_STATE = {
  file: null,
  uploadLoading: false,
  uploadProgress: 0,
  uploadError: undefined,
}

interface FileUploadProps extends WithTheme {
  ref?: React.Ref<any>
  variant: FileUploadVariants
  defaultImage?: string | null
  defaultVideo?: string | null
  defaultAudio?: string | null
  onStart?: () => void
  onComplete: (id: string) => void
  title?: string
}

interface FileUploadState {
  file: any
  uploadLoading: boolean
  uploadProgress: number
  uploadError?: Error
}

export class FileUploadClass extends Component<FileUploadProps, FileUploadState> {
  dropText: string
  acceptedTypes: string
  classes: any

  constructor(props: FileUploadProps) {
    super(props)
    this.dropText = props.title || strings[props.variant]
    this.acceptedTypes = mimeTypes[props.variant]
  }

  state = INITIAL_STATE

  reset = () => this.setState(INITIAL_STATE)

  setFile = (file: any) => this.setState({ file })

  toggleLoading = () => this.setState({ uploadLoading: !this.state.uploadLoading })

  getItem = (file: any) => {
    if (!file) return null
    switch (this.props.variant) {
      case FileUploadVariants.video:
        return file.src && !this.state.uploadLoading ? (
          <ItemWrapper>
            <VideoPlayer streamUrl={file.src} />
          </ItemWrapper>
        ) : !this.state.uploadLoading ? (
          <ItemWrapper style={{ textAlign: 'center' }}>
            <Typography variant="h6" component="p">
              {this.state.uploadProgress < 100
                ? `Upload Progress ${this.state.uploadProgress}%`
                : 'Upload Complete!'}
            </Typography>
          </ItemWrapper>
        ) : this.state.uploadError && this.state.uploadError.message ? (
          <ItemWrapper style={{ textAlign: 'center' }}>
            <Typography variant="h6" component="p">
              An Error Occurred, please click here and try again.
            </Typography>
          </ItemWrapper>
        ) : (
          <ItemWrapper style={{ textAlign: 'center' }}>
            <Typography variant="h6" component="p">
              File uploading...
            </Typography>
          </ItemWrapper>
        )
      case FileUploadVariants.image:
        return file.src ? (
          <ItemWrapper>
            <img key={file.id} src={file.src} width="100%" alt="" />
          </ItemWrapper>
        ) : (
          <ItemWrapper style={{ textAlign: 'center' }}>
            <Typography variant="h6" component="p">
              File uploading...
            </Typography>
          </ItemWrapper>
        )
      case FileUploadVariants.audio:
        return file.src ? (
          <ItemWrapper>
            <AudioPlayer src={file.src} />
          </ItemWrapper>
        ) : (
          <ItemWrapper style={{ textAlign: 'center' }}>
            <Typography variant="h6" component="p">
              File uploading...
            </Typography>
          </ItemWrapper>
        )
      default:
        return null
    }
  }

  onDrop = async (acceptedFiles: File[]) => {
    const upload = (file: File) =>
      uploadFile({
        file,
        toggleLoading: this.toggleLoading,
        onProgress: this.onUploadProgress,
        onComplete: this.onUploadComplete,
        onError: this.onUploadError,
      })
    if (this.props.onStart) {
      this.props.onStart()
    }
    this.reset()
    const file = acceptedFiles[0]
    const reader = new FileReader()

    reader.onabort = () => console.log('aborted')
    reader.onerror = e => console.log('error', e)
    reader.onloadstart = () => this.setFile({ id: file.name, file })
    reader.onload = e => {
      this.onUploadProgress(0)
      this.setFile({ id: file.name, file, src: reader.result })
      upload(file)
    }
    if (file.size < 100000000) {
      reader.readAsDataURL(file)
    } else {
      this.onUploadProgress(0)
      this.setFile({ id: file.name, file })
      upload(file)
    }
  }

  onUploadProgress = (progress: number) => this.setState({ uploadProgress: progress })

  onUploadError = (e: Error) => {
    console.log('ERROR', e)
    this.toggleLoading()
    this.onUploadProgress(0)
    this.setState({
      uploadError: {
        name: 'Upload Error',
        message: 'An Error Occurred. Please click here and try again.',
      },
    })
  }

  onUploadComplete = (res: any) => {
    this.toggleLoading()
    this.onUploadProgress(100)
    this.props.onComplete(res.id)
  }

  render() {
    const item = this.getItem(this.state.file)
    let noDragContent: any
    if (this.props.variant === FileUploadVariants.image && this.props.defaultImage) {
      noDragContent = <img src={this.props.defaultImage} width="60%" />
    } else if (this.props.variant === FileUploadVariants.video && this.props.defaultVideo) {
      noDragContent = <VideoPlayer streamUrl={this.props.defaultVideo} />
    } else if (this.props.variant === FileUploadVariants.audio && this.props.defaultAudio) {
      noDragContent = <AudioPlayer src={this.props.defaultAudio} />
    } else {
      noDragContent = (
        <Typography variant="h6" component="p">
          {this.dropText}
        </Typography>
      )
    }

    return (
      <Dropzone onDrop={this.onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <Wrapper {...getRootProps()} active={isDragActive} spacing={this.props.theme.spacing(3)}>
            <input {...getInputProps()} accept={this.acceptedTypes} />
            {item ? (
              <div style={{ position: 'relative' }}>
                <Progress
                  color="secondary"
                  variant="static"
                  value={this.state.uploadProgress}
                  done={this.state.uploadProgress === 100}
                />
                {item}
              </div>
            ) : (
              <>
                {isDragActive ? (
                  <Typography variant="h6" component="p">
                    Drop it!
                  </Typography>
                ) : (
                  noDragContent
                )}
              </>
            )}
          </Wrapper>
        )}
      </Dropzone>
    )
  }
}

export const FileUpload = withTheme(FileUploadClass)
