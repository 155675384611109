import React, { useState, useContext, createContext } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core'

enum ErrorMessages {
  maxFeaturedVideosExceeded = 'MAX_FEATURED_VIDEOS_EXCEEDED',
}

const ERROR_MESSAGES = {
  [ErrorMessages.maxFeaturedVideosExceeded]:
    'A maximum of five videos can be featured. Try again with five videos or less.',
}

interface ErrorModalProps {
  message: string
  open: boolean
  close: () => void
}

const ErrorModal = (props: ErrorModalProps) => {
  return (
    <Dialog onClose={props.close} aria-labelledby="simple-dialog-title" open={props.open}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">{props.message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={props.close}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const ErrorContext = createContext({
  setErrorMessage: (message: string) => {},
})

export const ErrorProvider = (props: { children: JSX.Element }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = (message: string) => {
    let m = message
    Object.keys(ERROR_MESSAGES).some((eMessage: string) => {
      if (message.indexOf(eMessage) !== -1) {
        m = ERROR_MESSAGES[eMessage as ErrorMessages]
        return true
      }
      return false
    })
    setErrorMessage(m)
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
    setErrorMessage('')
  }

  return (
    <ErrorContext.Provider value={{ setErrorMessage: openModal }}>
      <>
        <ErrorModal message={errorMessage} open={modalOpen} close={closeModal} />
        {props.children}
      </>
    </ErrorContext.Provider>
  )
}

export const useError = () => useContext(ErrorContext)
