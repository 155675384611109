import { SnackbarContent, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Comment } from '../../generated/graphql'
import { useLoadMoreComments } from '../../lib/graphql/comment'
import { Loader } from '../Loader'
import { PageLoader } from '../PageLoader'
import { TabPanel, TabPanelProps } from '../TabPanel'
import { TooltipIconButton } from '../TooltipIconButton'
import { CommentModerationCard } from './CommentModerationCard'

interface CommentTabProps extends TabPanelProps {
  query: () => any
  queryName: string
  openReportModal?: (id: string) => void
  getMoreQuery: (...args: any) => any
  getMoreQueryName: string
  search?: string
}

export const CommentTab = (props: CommentTabProps) => {
  const theme = useTheme()
  const [load, { data, loading, called, fetchMore, refetch }] = props.query()
  const { loadMoreComments, hasMoreComments } = useLoadMoreComments(fetchMore, props.queryName)
  const comments = (data && data[props.queryName] ? data[props.queryName] : []) as Comment[]
  const tabName = props.queryName
  const since = comments[0] && comments[0].updatedAt ? comments[0].updatedAt : null
  const skip = props.value !== props.index || !since || !called
  const { data: amountSinceData } = props.getMoreQuery({
    variables: { since },
    skip,
    pollInterval: skip ? 0 : 10000,
    fetchPolicy: 'network-only',
  })
  const newCommentAmount =
    amountSinceData && amountSinceData[props.getMoreQueryName]
      ? amountSinceData[props.getMoreQueryName]
      : 0

  useEffect(() => {
    if (props.value === props.index) {
      load({ variables: { query: props.search } })
    }
    return () => {}
  }, [props.value, props.search])

  const content = (
    <div>
      {newCommentAmount ? (
        <SnackbarContent
          style={{ marginBottom: theme.spacing(2) }}
          message={`${newCommentAmount} new comments`}
          action={
            <TooltipIconButton label="Refresh" placement="left" onClick={() => refetch()}>
              <RefreshIcon color="primary" />
            </TooltipIconButton>
          }
        />
      ) : null}
      {comments.map(comment => (
        //@ts-ignore
        <CommentModerationCard
          tabName={tabName}
          key={comment._id}
          //@ts-ignore
          {...comment}
          openReportModal={props.openReportModal}
        />
      ))}
    </div>
  )

  return (
    <TabPanel value={props.value} index={props.index}>
      {!called || loading ? (
        <PageLoader />
      ) : comments && comments.length ? (
        loadMoreComments ? (
          <InfiniteScroll
            useWindow={true}
            pageStart={0}
            threshold={50}
            loader={<Loader key={0} />}
            hasMore={hasMoreComments}
            loadMore={loadMoreComments}
          >
            {content}
          </InfiniteScroll>
        ) : (
          content
        )
      ) : (
        <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: 20 }}>
          No comments found
        </Typography>
      )}
    </TabPanel>
  )
}

CommentTab.Fragment = gql`
  fragment CommentTabData on Comment {
    _id
    ...CommentModerationCardData
    createdAt
  }
  ${CommentModerationCard.Fragment}
`
