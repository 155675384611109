import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { VIDEO_PAGE_SIZE, USER_PAGE_SIZE, COMMENT_PAGE_SIZE } from '../consts'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const cache = new InMemoryCache()

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  name: 'admin-web',
  version: '1.2',
  resolvers: {
    Query: {
      videoPageLimit() {
        return VIDEO_PAGE_SIZE
      },
      userPageLimit() {
        return USER_PAGE_SIZE
      },
      commentPageLimit() {
        return COMMENT_PAGE_SIZE
      },
    },
  },
})

cache.writeData({
  data: {
    videoPageLimit: VIDEO_PAGE_SIZE,
    userPageLimit: USER_PAGE_SIZE,
    commentPageLimit: COMMENT_PAGE_SIZE,
  },
})
