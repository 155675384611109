import { ApolloProvider } from '@apollo/react-hooks'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React from 'react'
import { ErrorProvider } from './components/ErrorModal'
import { Router } from './components/Router'
import { ViewTypeProvider } from './components/SwitchViewButton'
import { ThemeProvider } from './components/ThemeProvider'
import { UserProvider } from './components/UserProvider'
import { client } from './lib/apollo'

const App: React.FC = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ViewTypeProvider>
            <ErrorProvider>
              <UserProvider>
                <Router />
              </UserProvider>
            </ErrorProvider>
          </ViewTypeProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  )
}

export default App
