import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import { AppBar, Toolbar, Button, Typography, IconButton, Hidden } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
// import Brightness6Icon from '@material-ui/icons/Brightness6'
// import { ThemeContext } from '../../lib/theme'
import { useLogout } from '../../lib/auth'
import { UserContext } from '../UserProvider'
import { SearchInput } from './SearchInput'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
      marginRight: 24,
    },
    logoutButton: {
      marginLeft: theme.spacing(3),
    },
  })
)

interface HeaderProps {
  toggleDrawer: () => void
}

export const Header = (props: HeaderProps) => {
  const user = useContext(UserContext)
  // const context = useContext(ThemeContext)
  const router = useReactRouter()
  const theme = useTheme()
  const classes = useStyles(theme)
  const logout = useLogout()

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={props.toggleDrawer}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <Hidden xsDown>
          <Link to="/">
            <Typography variant="h6" noWrap>
              InfoWars Media
            </Typography>
          </Link>
        </Hidden>

        <Hidden xsDown>
          <div style={{ display: 'flex', flex: 1 }} />
        </Hidden>
        <SearchInput onSubmit={text => router.history.push(`/search?query=${escape(text)}`)} />
        <Hidden xsDown>
          <div style={{ display: 'flex', flex: 1 }} />
        </Hidden>
        {/* <IconButton
          color="inherit"
          aria-label="toggle light / dark"
          edge="end"
          onClick={context.toggleMode}
        >
          <Brightness6Icon />
        </IconButton> */}
        <Hidden xsDown>
          {user.isSuperAdmin || user.isAdmin || user.isUploader ? (
            <Link to="/upload">
              <Button className={classes.logoutButton} color="inherit">
                Upload
              </Button>
            </Link>
          ) : null}
          <Button className={classes.logoutButton} color="inherit" onClick={logout}>
            Logout
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}
