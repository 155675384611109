import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import gql from 'graphql-tag'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Playlist } from '../generated/graphql'
import { Pages } from '../types'
import { Data, HeadRow, Table } from './Table'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
)

export interface PlaylistTableData {
  id: string
  title: string
  channel?: string
  totalVideos: number
  totalPlayCount: number
  published: boolean
  createdAt: Date
  type: string
}

function createData(playlist: Playlist, isPlaylistsPage: boolean): PlaylistTableData {
  const row: any = {
    id: playlist._id,
    title: playlist.title,
    totalVideos: playlist.totalVideos,
    totalPlayCount: playlist.totalVideoViews,
    published: playlist.isPublic,
    createdAt: playlist.createdAt,
    type: 'playlist',
  }
  if (isPlaylistsPage) {
    row.channel = !playlist.isGlobal && playlist.channel ? playlist.channel.title : 'Global'
  }
  return row
}

const createHeadRows = (isPlaylistPage: boolean) => {
  const headRows = [
    { id: 'title', alignRight: false, disablePadding: true, label: 'Playlists' },
    isPlaylistPage
      ? { id: 'channel', alignRight: true, disablePadding: false, label: 'Channel' }
      : null,
    { id: 'totalVideos', alignRight: true, disablePadding: false, label: 'Total Videos' },
    { id: 'totalPlayCount', alignRight: true, disablePadding: false, label: 'Total Views' },
    { id: 'published', alignRight: true, disablePadding: false, label: 'Published' },
    { id: 'createdAt', alignRight: true, disablePadding: false, label: 'Created At' },
  ].filter(r => !!r)
  return headRows as HeadRow<PlaylistTableData>[]
}

function isPlaylist(row: any): row is PlaylistTableData {
  return (row as PlaylistTableData).type === 'playlist'
}

interface PlaylistTableProps {
  playlists: Playlist[]
  label: string
  variant?: Pages
  onSelect?: (id: string) => void
  selectedItems?: string[]
  noLink?: boolean
}

export const PlaylistTable = (props: PlaylistTableProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const isPlaylistPage = props.variant === Pages.playlists
  const headRows = createHeadRows(isPlaylistPage)
  const rows = props.playlists.map(p => createData(p, isPlaylistPage))
  return (
    <div className={classes.root}>
      <Table
        rows={rows}
        label={props.label}
        headRows={headRows as HeadRow<Data>[]}
        selectedItems={props.selectedItems}
      >
        {({ row, handleClick, isItemSelected, labelId }) => {
          const selected = props.selectedItems
            ? props.selectedItems.indexOf(row.id) !== -1
            : isItemSelected
          return isPlaylist(row) ? (
            <TableRow
              hover
              onClick={event =>
                props.onSelect ? props.onSelect(row.id) : handleClick(event, row.id)
              }
              role="checkbox"
              aria-checked={selected}
              tabIndex={-1}
              key={row.id}
              selected={selected}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={selected} inputProps={{ 'aria-labelledby': labelId }} />
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="none"
                style={props.noLink ? undefined : { textDecoration: 'underline' }}
              >
                {props.noLink ? row.title : <Link to={`/playlists/${row.id}`}>{row.title}</Link>}
              </TableCell>
              {isPlaylistPage ? <TableCell align="right">{row.channel}</TableCell> : null}
              <TableCell align="right">{row.totalVideos}</TableCell>
              {/* <TableCell align="right">{row.totalPlayCount.toLocaleString()}</TableCell> */}
              <TableCell align="right">{row.published ? 'Yes' : 'No'}</TableCell>
              <TableCell align="right">{moment(row.createdAt).calendar()}</TableCell>
            </TableRow>
          ) : null
        }}
      </Table>
    </div>
  )
}

PlaylistTable.Fragment = gql`
  fragment PlaylistTableData on Playlist {
    _id
    title
    totalVideos
    totalVideoViews
    isPublic
    createdAt
    isGlobal
    channel {
      _id
      title
    }
  }
`
