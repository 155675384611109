import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import { Video, useSearchQuery } from '../generated/graphql'
import { useViewType } from './SwitchViewButton'
import { Surface } from './Surface'
import { VideoGrid } from './VideoGrid'
import { VideoTable } from './VideoTable'
import { useLoadMoreVideos } from '../lib/graphql/video'

export const SearchVideos = (props: { query: string }) => {
  const { isGrid } = useViewType()
  const { data, fetchMore } = useSearchQuery({
    variables: { query: props.query, category: 'VIDEO' },
  })
  const { loadMoreVideos, hasMoreVideos, resetLoadMoreVideos } = useLoadMoreVideos(
    fetchMore,
    'search',
    'offset'
  )

  useEffect(() => {
    resetLoadMoreVideos()
    return () => {}
  }, [props.query])

  const videos = (data && data.search ? data.search.videos : []) as Video[]

  return (
    <Surface title="Search Videos">
      {isGrid ? (
        <VideoGrid videos={videos} hasMoreVideos={hasMoreVideos} loadMore={loadMoreVideos} />
      ) : (
        <VideoTable videos={videos} label="Featured Video" />
      )}
    </Surface>
  )
}

export const SEARCH = gql`
  query Search($query: String!, $category: String!, $limit: Float, $offset: Float) {
    search(query: $query, category: $category, limit: $limit, offset: $offset) {
      videos {
        ...VideoCardData
        ...VideoTableData
      }
    }
  }
  ${VideoGrid.Fragment}
  ${VideoTable.Fragment}
`
