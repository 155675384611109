import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import { useError } from '../components/ErrorModal'
import { TooltipFab } from '../components/TooltipFab'
import { FileUpload, FileUploadVariants } from './FileUpload'

interface SalesTextAddProps {
  onSubmit: (args: {
    text: string
    description: string
    url: string
    videoUploadId: string
    textSale: string
  }) => Promise<any>
}

export const SalesTextAdd = (props: SalesTextAddProps) => {
  const [open, setOpen] = useState(false)
  const [videoUploadId, setVideoUploadId] = useState('')
  const videoUploadRef = useRef()
  const toggleOpen = () => setOpen(!open)
  const { setErrorMessage } = useError()
  return (
    <>
      <TooltipFab tooltipLabel="Create Sale" aria-label="create sale" onClick={toggleOpen}>
        <AddIcon />
      </TooltipFab>
      <Formik
        initialValues={{ text: '', description: '', url: '', videoUploadId: '', textSale: '' }}
        validate={values => {
          let errors: any = {}
          if (!values.text) {
            errors.text = 'Required'
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            await props.onSubmit({
              ...values,
              description: values.description,
              url: values.url,
              text: values.text,
              videoUploadId,
              textSale: values.textSale,
            })
          } catch (e) {
            setErrorMessage(e.message)
          }
          setSubmitting(false)
          setOpen(false)
          resetForm()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => {
          const close = () => {
            resetForm()
            toggleOpen()
          }
          return (
            <>
              <Dialog
                open={open}
                onClose={close}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle id="form-dialog-title">Create Sale</DialogTitle>
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="text"
                      label="Video Sale Title"
                      name="text"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.text}
                    />
                    {touched.text && errors.text ? (
                      <FormHelperText error>{errors.text}</FormHelperText>
                    ) : null}
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="description"
                      label="Video Sale Description"
                      name="description"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                    />
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="url"
                      label="Promo URL"
                      name="url"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.url}
                    />
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="textSale"
                      label="Sale Text for Description of Videos - use mark down for clickable text. Example: [Click Here To Visit Link](https://infowars.com/)"
                      name="textSale"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.textSale}
                    />
                  </DialogContent>
                  <div
                    style={{
                      padding: '2rem 1rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="subtitle1" component="p" style={{ marginTop: '1rem' }}>
                      Video Upload
                    </Typography>
                    <FileUpload
                      ref={videoUploadRef}
                      variant={FileUploadVariants.video}
                      onComplete={(id: string) => {
                        setVideoUploadId(id)
                      }}
                      onStart={() => {}}
                    />
                  </div>

                  <DialogActions>
                    <Button onClick={close} disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button type="submit" color="secondary" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </>
          )
        }}
      </Formik>
    </>
  )
}
