import React from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      margin: theme.spacing(0, 3),
      padding: theme.spacing(3),
    },
    pannelInner: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
)

export interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

export const TabPanel = (props: TabPanelProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { children, value, index, ...other } = props

  return (
    <Paper
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={classes.panel}
      {...other}
    >
      <div className={classes.pannelInner}>
        <div style={{ width: '100%', maxWidth: 800 }}>{children}</div>
      </div>
    </Paper>
  )
}
