import styled from '@emotion/styled'
import { Button, CircularProgress, Paper, TextField, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { useLogin } from '../lib/auth'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      marginBottom: 40,
      display: 'flex',
      flexDirection: 'column',
      width: '80%',
      maxWidth: 450,
    },
    formWrapper: {
      padding: theme.spacing(3, 2),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    title: {
      paddingBottom: theme.spacing(2),
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    forgot: {
      color: '#939393',
      padding: 10,
      cursor: 'pointer',
    },
  })
)

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://images.unsplash.com/photo-1509023464722-18d996393ca8?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  filter: blur(3px);
  -webkit-filter: blur(3px);
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

export const Login = () => {
  const { login, loading, error } = useLogin()
  const theme = useTheme()
  const classes = useStyles(theme)
  const [values, setValues] = useState({
    email: '',
    password: '',
  })

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [name]: event.target.value })
  }

  const onSubmit = async (e?: any) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    const { email, password } = values
    login(email, password)
  }

  return (
    <Wrapper>
      <Background />
      <div className={classes.root}>
        <Paper className={classes.formWrapper}>
          <Typography variant="h4" component="h3" align="center" className={classes.title}>
            Login
          </Typography>
          {error && (
            <Typography variant="body1" gutterBottom align="center" color="error">
              {error}
            </Typography>
          )}
          <form id="loginForm" onSubmit={onSubmit} className={classes.form}>
            <TextField
              id="outlined-email"
              label="Email"
              className={classes.textField}
              value={values.email}
              onChange={handleChange('email')}
              margin="normal"
              variant="outlined"
              type="email"
            />
            <TextField
              id="outlined-password"
              label="Password"
              className={classes.textField}
              value={values.password}
              onChange={handleChange('password')}
              margin="normal"
              variant="outlined"
              type="password"
            />
          </form>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.button}
            onClick={onSubmit}
            type="submit"
            form="loginForm"
            disabled={loading}
          >
            {loading ? <CircularProgress size="26px" /> : 'Submit'}
          </Button>
        </Paper>
        <Typography
          variant="body1"
          className={classes.forgot}
          onClick={() => {
            window.location.href = '/forgot-password'
          }}
        >
          Forgot Password?
        </Typography>
      </div>
    </Wrapper>
  )
}
