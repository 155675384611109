import React, { useState } from 'react'
import { Formik } from 'formik'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useError } from '../components/ErrorModal'
import { TooltipFab } from '../components/TooltipFab'

interface AdminUserAddProps {
  onSubmit: (args: { email: string; password: string }) => Promise<any>
}

export const AdminUserAdd = (props: AdminUserAddProps) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  const { setErrorMessage } = useError()
  return (
    <>
      <TooltipFab tooltipLabel="Create User" aria-label="create user" onClick={toggleOpen}>
        <AddIcon />
      </TooltipFab>
      <Formik
        initialValues={{ email: '', password: '', passwordRepeat: '' }}
        validate={values => {
          let errors: any = {}
          if (!values.email) {
            errors.email = 'Required'
          }
          if (!values.password) {
            errors.password = 'Required'
          }
          if (!values.passwordRepeat) {
            errors.passwordRepeat = 'Required'
          }
          if (values.password !== values.passwordRepeat) {
            errors.password = "Passwords don't match"
          }
          return errors
        }}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            await props.onSubmit(values)
          } catch (e) {
            setErrorMessage(e.message)
          }
          setSubmitting(false)
          setOpen(false)
          resetForm()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => {
          const close = () => {
            resetForm()
            toggleOpen()
          }
          return (
            <>
              <Dialog
                open={open}
                onClose={close}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle id="form-dialog-title">Create User</DialogTitle>
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <TextField
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {touched.email && errors.email ? (
                      <FormHelperText error>{errors.email}</FormHelperText>
                    ) : null}
                    <TextField
                      fullWidth
                      id="password"
                      label="Password"
                      name="password"
                      margin="dense"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    {touched.password && errors.password ? (
                      <FormHelperText error>{errors.password}</FormHelperText>
                    ) : null}
                    <TextField
                      fullWidth
                      id="passwordRepeat"
                      label="Repeat Password"
                      name="passwordRepeat"
                      margin="dense"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.passwordRepeat}
                    />
                    {touched.passwordRepeat && errors.passwordRepeat ? (
                      <FormHelperText error>{errors.passwordRepeat}</FormHelperText>
                    ) : null}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={close} disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button type="submit" color="secondary" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </>
          )
        }}
      </Formik>
    </>
  )
}
