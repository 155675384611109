import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { DatePicker } from '@material-ui/pickers'
import axios from 'axios'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { ALEX_JONES_CHANNEL_ID, AMERICAN_JOURNAL_ID, WAR_ROOM_CHANNEL_ID } from '../consts'
import { useGetVideoUploadChannelsQuery } from '../generated/graphql'
import { useError } from './ErrorModal'
import { FileUpload, FileUploadVariants } from './FileUpload'
import { TabPanel, TabPanelProps } from './TabPanel'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      marginTop: theme.spacing(4),
      width: '100%',
      color: '#fff',
    },
    snackbar: {
      width: '100%',
      textAlign: 'center',
    },
    audioUploadTitle: {
      marginTop: theme.spacing(1),
    },
    formControl: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    textField: {
      width: '100%',
    },
    resetButton: {
      marginTop: theme.spacing(4),
      width: '25%',
      marginLeft: theme.spacing(1),
    },
    buttonRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    date: {
      marginTop: theme.spacing(2),
      width: '100%',
    },
  })
)

export const AudioUpload = (props: TabPanelProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { data } = useGetVideoUploadChannelsQuery()
  const audioUploadRef = useRef()
  const { setErrorMessage } = useError()
  const [selectedDate, handleDateChange] = useState(moment())
  const channels = data && data.getAdminChannels ? data.getAdminChannels : []

  return (
    <TabPanel value={props.value} index={props.index}>
      <Formik
        initialValues={{
          title: '',
          description: '',
          channelId: '',
          audioUploadId: '',
          publishedAt: moment(),
        }}
        validate={values => {
          const errors: any = {}
          if (!values.title) {
            errors.title = 'Required'
          }
          if (!values.description) {
            errors.description = 'Required'
          }
          if (!values.channelId) {
            errors.channelId = 'Required'
          }
          if (!values.publishedAt) {
            errors.publishedAt = 'Required'
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          console.log('values', values)
          await axios.get('')
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => {
          const reset = () => {
            const audioUpload = audioUploadRef.current! as any
            resetForm()
            if (audioUpload.reset) {
              audioUpload.reset()
            }
          }
          return (
            <form onSubmit={handleSubmit}>
              <FileUpload
                ref={audioUploadRef}
                variant={FileUploadVariants.audio}
                onComplete={(id: string) => setFieldValue('audioUploadId', id)}
                onStart={() => setFieldTouched('audioUploadId')}
              />
              <Typography variant="subtitle1" component="p" className={classes.audioUploadTitle}>
                Audio Upload
              </Typography>
              {touched.audioUploadId && errors.audioUploadId ? (
                <FormHelperText error>{errors.audioUploadId}</FormHelperText>
              ) : null}
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="channelId">Channel</InputLabel>
                <Select
                  value={values.channelId}
                  onChange={e => setFieldValue('channelId', e.target.value)}
                  inputProps={{ name: 'channelId', id: 'channelId' }}
                  error={!!(touched.channelId && errors.channelId)}
                >
                  {channels
                    .filter(({ _id }) => {
                      return (
                        _id === AMERICAN_JOURNAL_ID ||
                        _id === ALEX_JONES_CHANNEL_ID ||
                        _id === WAR_ROOM_CHANNEL_ID
                      )
                    })
                    .map(({ _id, title }) => {
                      return (
                        <MenuItem key={_id} value={_id}>
                          {title}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
              {touched.channelId && errors.channelId ? (
                <FormHelperText error>{errors.channelId}</FormHelperText>
              ) : null}
              <TextField
                id="title"
                label="Title"
                name="title"
                className={classes.textField}
                value={values.title}
                onChange={handleChange}
                margin="normal"
                error={!!(touched.title && errors.title)}
              />
              {touched.title && errors.title ? (
                <FormHelperText error>{errors.title}</FormHelperText>
              ) : null}
              <TextField
                id="description"
                label="Description"
                name="description"
                className={classes.textField}
                value={values.description}
                onChange={handleChange}
                multiline
                maxRows="4"
                margin="normal"
                error={!!(touched.description && errors.description)}
              />
              {touched.description && errors.description ? (
                <FormHelperText error>{errors.description}</FormHelperText>
              ) : null}
              <DatePicker
                id="publishedAt"
                name="publishedAt"
                label="Publish On"
                value={selectedDate}
                onChange={handleDateChange}
                className={classes.date}
              />
              <div className={classes.buttonRow}>
                <Button
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !values.audioUploadId ||
                    !values.channelId ||
                    !values.description ||
                    !values.title ||
                    !values.publishedAt ||
                    (touched.audioUploadId && !values.audioUploadId)
                  }
                  variant="contained"
                  color="secondary"
                  className={classes.submitButton}
                >
                  {isSubmitting ? <CircularProgress size="24px" color="secondary" /> : 'Submit'}
                </Button>
                <Button variant="contained" className={classes.resetButton} onClick={reset}>
                  Reset
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </TabPanel>
  )
}
