import { Chip, MenuItem, Paper, TextField, Typography } from '@material-ui/core'
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import clsx from 'clsx'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { Tag, useGetTagsQuery } from '../generated/graphql'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
}))

interface NoOptionsMessageProps {
  children?: JSX.Element
  innerProps: any
  selectProps: any
}

const NoOptionsMessage = (props: NoOptionsMessageProps) => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
)

interface InputComponentProps {
  inputRef: () => void | { current: any }
}

const inputComponent = ({ inputRef, ...props }: InputComponentProps) => (
  <div ref={inputRef} {...props} />
)

interface ControlProps {
  children?: JSX.Element
  innerProps: {
    onMouseDown: () => void
  }
  innerRef: () => void | { current: any } | null
  selectProps: any
}

const Control = (props: ControlProps) => {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  ) as any
}

interface OptionProps {
  children?: JSX.Element
  innerProps: {
    id: string
    key: string
    onClick: () => void
    onMouseMove: () => void
    onMouseOver: () => void
    tabIndex: number
  }
  innerRef: () => void | { current: any } | null
  isFocused: boolean
  isSelected: boolean
}

const Option = (props: OptionProps) => (
  <MenuItem
    ref={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>
)

interface PlaceholderProps {
  children?: JSX.Element
  innerProps?: any
  selectProps: any
}

const Placeholder = (props: PlaceholderProps) => {
  const { selectProps, innerProps = {}, children } = props
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  )
}

interface SingleValueProps {
  children?: JSX.Element
  innerProps: any
  selectProps: any
}

const SingleValue = (props: SingleValueProps) => (
  <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
    {props.children}
  </Typography>
)

interface ValueContainerProps {
  children?: JSX.Element
  selectProps: any
}

const ValueContainer = (props: ValueContainerProps) => (
  <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
)

interface MultiValueProps {
  children?: JSX.Element
  isFocused: boolean
  removeProps: {
    onClick: (e: any) => void
    onMouseDown: () => void
    onTouchEnd: () => void
  }
  selectProps: any
}

const MultiValue = (props: MultiValueProps) => (
  <Chip
    tabIndex={-1}
    label={props.children}
    className={clsx(props.selectProps.classes.chip, {
      [props.selectProps.classes.chipFocused]: props.isFocused,
    })}
    onDelete={props.removeProps.onClick}
    deleteIcon={<CancelIcon {...props.removeProps} />}
  />
)

interface MenuProps {
  children: JSX.Element
  innerProps: any
  selectProps: any
}

const Menu = (props: MenuProps) => (
  <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
    {props.children}
  </Paper>
)

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

interface TagsAutocompleteProps {
  value: any[]
  onChange: (value: any) => void
}

export const TagsAutocomplete = (props: TagsAutocompleteProps) => {
  const classes = useStyles({})
  const theme = useTheme()
  const [inputValue, setInputValue] = useState('')
  const { data } = useGetTagsQuery({
    fetchPolicy: 'no-cache',
    variables: { searchQuery: inputValue },
  })
  const selectStyles = {
    input: (base: any) => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  }

  const options = (data && data.getTags ? data.getTags : ([] as Tag[])).map(({ _id, name }) => ({
    _id,
    value: _id,
    label: name,
  }))

  return (
    <div className={classes.wrapper}>
      <CreatableSelect
        classes={classes}
        styles={selectStyles}
        inputId="react-select-multiple"
        TextFieldProps={{
          label: 'Tags',
          InputLabelProps: {
            htmlFor: 'react-select-multiple',
            shrink: true,
          },
        }}
        placeholder="Select tag/tags (Required)"
        options={options}
        components={components as any}
        value={props.value}
        inputValue={inputValue}
        onChange={props.onChange}
        onInputChange={(value: string) => setInputValue(value)}
        isMulti
      />
    </div>
  )
}

export const GET_TAGS = gql`
  query GetTags($searchQuery: String!) {
    getTags(searchQuery: $searchQuery) {
      _id
      name
    }
  }
`
