import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { Video } from '../generated/graphql'
import { GridVariants } from '../types'
import { Loader } from './Loader'
import { VideoCard } from './VideoCard'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      height: 140,
    },
    root: {
      flexGrow: 1,
    },
    noVideosFound: {
      textAlign: 'center',
      marginTop: 20,
      marginBottom: 20,
      width: '100%',
    },
  })
)

interface VideoGridProps {
  videos: Video[]
  variant?: GridVariants
  selectedItems?: string[]
  onSelect?: (id: string) => void
  hasMoreVideos?: boolean
  loadMore?: () => void
}

const VideoGridContent = (props: VideoGridProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <Grid container spacing={3}>
      {props.videos && props.videos.length ? (
        props.videos.map(video => (
          <Grid key={video._id} item xs={12} sm={6} md={4} lg={3}>
            <VideoCard
              {...video}
              variant={props.variant}
              selectedItems={props.selectedItems}
              onSelect={props.onSelect}
            />
          </Grid>
        ))
      ) : props.hasMoreVideos ? null : (
        <Typography variant="subtitle1" className={classes.noVideosFound}>
          No videos found
        </Typography>
      )}
    </Grid>
  )
}

export const VideoGrid = (props: VideoGridProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <div className={classes.root}>
      {props.loadMore ? (
        <InfiniteScroll
          useWindow={true}
          pageStart={0}
          threshold={50}
          loader={<Loader key={0} />}
          hasMore={props.hasMoreVideos}
          loadMore={props.loadMore}
        >
          <VideoGridContent {...props} />
        </InfiniteScroll>
      ) : (
        <VideoGridContent {...props} />
      )}
    </div>
  )
}

VideoGrid.Fragment = VideoCard.Fragment
