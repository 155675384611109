import React from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Video } from '../generated/graphql'
import { Surface } from './Surface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    embedWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 20,
      backgroundColor: theme.palette.background.default,
      borderRadius: 4,
    },
  })
)

export const VideoEmbedCode = (props: Video) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return props._id ? (
    <Surface title="Embed Code">
      <code className={classes.embedWrapper}>
        {`<div class="ifw-player" data-video-id="${props._id}"></div>`}
        <br />
        {`<script src="https://infowarsmedia.com/js/player.js" async></script>`}
      </code>
    </Surface>
  ) : null
}
