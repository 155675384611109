import React, { useState } from 'react'
import { debounce } from 'lodash'
import { PageLayout } from '../components/PageLayout'
import { PageSearchInput } from '../components/PageSearchInput'
import { UserList } from '../components/UserList'

export const Users = () => {
  const [searchValue, setSearchValue] = useState('')
  const handleSearchChange = debounce(text => setSearchValue(text), 600)
  return (
    <PageLayout
      loading={false}
      customButton={<PageSearchInput onChange={handleSearchChange} />}
      noViewSwitch
    >
      <UserList searchValue={searchValue} />
    </PageLayout>
  )
}
