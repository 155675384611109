import useReactRouter from 'use-react-router'
import { useMutation } from '@apollo/react-hooks'
import {
  PlaylistInput,
  GetPlaylistsPageQuery,
  ChannelPlaylistsDataFragment,
} from '../../generated/graphql'
import { VIDEO_PAGE_SIZE } from '../../consts'
import { ChannelPlaylists } from '../../components/ChannelPlaylists'
import { CREATE_PLAYLIST, GET_PLAYLISTS_PAGE, GET_PLAYLISTS_PAGE_VARS } from '../../pages/Playlists'

export const useCreatePlaylist = () => {
  const { history } = useReactRouter()
  const [create] = useMutation(CREATE_PLAYLIST)
  const createPlaylist = async (playlist: PlaylistInput) => {
    const playlistToCreate = { ...playlist }
    if (playlistToCreate.isGlobal) {
      delete playlistToCreate.channel
    }
    const { data } = await create({
      variables: { playlist: playlistToCreate },
      update(cache, { data }) {
        try {
          const options = {
            query: GET_PLAYLISTS_PAGE,
            variables: GET_PLAYLISTS_PAGE_VARS,
          }
          const allPlaylistsQuery = cache.readQuery(options) as GetPlaylistsPageQuery
          if (allPlaylistsQuery && allPlaylistsQuery.getAdminPlaylists) {
            cache.writeQuery({
              ...options,
              data: {
                getAdminPlaylists: [data.createPlaylist, ...allPlaylistsQuery.getAdminPlaylists],
              },
            })
          }
        } catch (e) {
          console.log('ERROR', e)
        }

        try {
          const id = `Channel:${playlist.channel}`
          const options = {
            fragment: ChannelPlaylists.Fragment,
            fragmentName: 'ChannelPlaylistsData',
            variables: { playlistVideoLimit: VIDEO_PAGE_SIZE },
            id,
          }
          const channelPlaylists = cache.readFragment(options) as ChannelPlaylistsDataFragment
          cache.writeFragment({
            ...options,
            data: {
              ...channelPlaylists,
              playlists: [data.createPlaylist].concat(channelPlaylists.playlists),
            },
          })
        } catch (e) {
          console.log('ERROR', e)
        }
      },
    })

    if (data && data.createPlaylist) {
      history.push(`/playlists/${data.createPlaylist._id}`)
    }
  }

  return createPlaylist
}
