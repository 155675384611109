import React from 'react'
import { TablePagination as MUITablePagination } from '@material-ui/core'

interface TablePaginationProps {
  rows: any[]
  page: number
  rowsPerPage: number
  totalRows?: number
  handleChangePage: (event: unknown, newPage: number) => void
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const TablePagination = (props: TablePaginationProps) => {
  const { rows, page, totalRows, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = props
  return (
    <MUITablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={totalRows || rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
  )
}
