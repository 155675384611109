import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  width: 100%;
`

export const AudioPlayer = (props: { src: string }) => (
  <Wrapper>
    <audio src={props.src} controls></audio>
  </Wrapper>
)
