import React from 'react'
import gql from 'graphql-tag'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { Channel } from '../../generated/graphql'
import { VideoGrid } from '../VideoGrid'
import { Surface } from '../Surface'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadMore: {
      marginTop: theme.spacing(3),
    },
  })
)

interface ChannelListItemProps extends Channel {
  loadMore?: () => void
}

export const ChannelListItem = (props: ChannelListItemProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <Surface title={props.title} titleLink={`/channels/${props._id}`} subtitle={props.summary}>
      <VideoGrid videos={props.videos} />
      {props.loadMore ? (
        <div style={{ width: '100%' }}>
          <Button onClick={props.loadMore} className={classes.loadMore} fullWidth>
            Load More
          </Button>
        </div>
      ) : null}
    </Surface>
  )
}

ChannelListItem.Fragment = gql`
  fragment ChannelListItemData on Channel {
    _id
    title
    summary
    videos(limit: $channelVideoLimit, offset: $channelVideoOffset) {
      ...VideoCardData
    }
  }
  ${VideoGrid.Fragment}
`
