import React, { useEffect } from 'react'
import gql from 'graphql-tag'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import { Report, useGetCommentReportsLazyQuery } from '../../generated/graphql'
import { PageLoader } from '../PageLoader'

interface CommentReportModalProps {
  open: boolean
  close: () => void
  commentId: string
}

export const CommentReportModal = (props: CommentReportModalProps) => {
  const [load, { data, loading, called }] = useGetCommentReportsLazyQuery()

  useEffect(() => {
    if (props.commentId) {
      load({ variables: { id: props.commentId } })
    }
    return () => {}
  }, [props.commentId])

  const reports = (data && data.getCommentReports ? data.getCommentReports : []) as Report[]

  return (
    <Dialog onClose={props.close} open={props.open}>
      <List
        style={{ width: 480 }}
        subheader={<ListSubheader component="div">Reports</ListSubheader>}
      >
        {loading ? (
          <PageLoader />
        ) : (
          reports.map((report, i) => (
            <div key={report.id}>
              {i !== 0 ? <Divider /> : null}
              <ListItem>
                <ListItemText primary={report.content} />
              </ListItem>
            </div>
          ))
        )}
      </List>
    </Dialog>
  )
}

export const GET_COMMENT_REPORTS = gql`
  query GetCommentReports($id: String!) {
    getCommentReports(id: $id) {
      id
      type
      content
    }
  }
`
