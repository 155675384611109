import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import gql from 'graphql-tag'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { Channel } from '../generated/graphql'
import { Data, HeadRow, Table } from './Table'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
)

export interface ChannelTableData {
  id: string
  title: string
  totalPlayCount: number
  totalVideos: number
  totalPlaylists: number
  createdAt: Date
  type: string
}

function createData(channel: Channel): ChannelTableData {
  return {
    id: channel._id,
    title: channel.title,
    totalPlayCount: channel.totalVideoViews,
    totalVideos: channel.totalVideos,
    totalPlaylists: channel.totalPlaylists,
    createdAt: channel.createdAt,
    type: 'channel',
  }
}

const headRows: HeadRow<ChannelTableData>[] = [
  { id: 'title', alignRight: false, disablePadding: true, label: 'Channel' },
  { id: 'totalPlayCount', alignRight: true, disablePadding: false, label: 'Total Views' },
  { id: 'totalVideos', alignRight: true, disablePadding: false, label: 'Videos' },
  { id: 'totalPlaylists', alignRight: true, disablePadding: false, label: 'Playlists' },
  { id: 'createdAt', alignRight: true, disablePadding: false, label: 'Created At' },
]

function isChannel(row: any): row is ChannelTableData {
  return (row as ChannelTableData).type === 'channel'
}

interface ChannelTableProps {
  channels: Channel[]
  label: string
  onSelect?: (id: string) => void
  selectedItems?: string[]
  noLink?: boolean
}

export const ChannelTable = (props: ChannelTableProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const rows = props.channels.map(createData)
  return (
    <div className={classes.root}>
      <Table
        rows={rows}
        label={props.label}
        headRows={headRows as HeadRow<Data>[]}
        selectedItems={props.selectedItems}
      >
        {({ row, handleClick, isItemSelected, labelId }) => {
          const selected = props.selectedItems
            ? props.selectedItems.indexOf(row.id) !== -1
            : isItemSelected
          return isChannel(row) ? (
            <TableRow
              hover
              onClick={event =>
                props.onSelect ? props.onSelect(row.id) : handleClick(event, row.id)
              }
              role="checkbox"
              aria-checked={selected}
              tabIndex={-1}
              key={row.id}
              selected={selected}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={selected} inputProps={{ 'aria-labelledby': labelId }} />
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="none"
                style={props.noLink ? undefined : { textDecoration: 'underline' }}
              >
                {props.noLink ? row.title : <Link to={`/channels/${row.id}`}>{row.title}</Link>}
              </TableCell>
              {/* <TableCell align="right">{row.totalPlayCount.toLocaleString()}</TableCell> */}
              <TableCell align="right">{row.totalVideos}</TableCell>
              <TableCell align="right">{row.totalPlaylists}</TableCell>
              <TableCell align="right">{moment(row.createdAt).calendar()}</TableCell>
            </TableRow>
          ) : null
        }}
      </Table>
    </div>
  )
}

ChannelTable.Fragment = gql`
  fragment ChannelTableData on Channel {
    _id
    title
    totalVideoViews
    totalVideos
    totalPlaylists
    createdAt
  }
`
