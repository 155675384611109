import { createContext, useContext } from 'react'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
import blueGrey from '@material-ui/core/colors/blueGrey'
import grey from '@material-ui/core/colors/grey'
import amber from '@material-ui/core/colors/amber'
import red from '@material-ui/core/colors/red'

export enum ThemeModes {
  light = 'light',
  dark = 'dark',
}

export const ThemeContext = createContext({
  mode: ThemeModes.light,
  toggleMode: () => {},
})

export const useDangerTheme = () => {
  const theme = useContext(ThemeContext)
  return responsiveFontSizes(
    createMuiTheme({
      palette: {
        type: theme.mode,
        primary: red,
        secondary: amber,
      },
    })
  )
}

const theme = (mode: ThemeModes) =>
  responsiveFontSizes(
    createMuiTheme({
      palette: {
        type: mode,
        primary: blueGrey,
        secondary: amber,
        error: red,
        background: {
          default: mode === ThemeModes.light ? '#fff' : grey['900'],
          paper: mode === ThemeModes.light ? '#fff' : grey['800'],
        },
      },
      typography: {
        fontFamily: 'Open Sans',
        h1: {
          fontFamily: 'Maven Pro',
        },
        h2: {
          fontFamily: 'Maven Pro',
        },
        h3: {
          fontFamily: 'Maven Pro',
        },
        h4: {
          fontFamily: 'Maven Pro',
        },
      },
    })
  )

export default theme
