import React, { useState } from 'react'
import gql from 'graphql-tag'
import { find } from 'lodash'
import { Formik } from 'formik'
import { DropResult } from 'react-beautiful-dnd'
import { Video, Channel, useGetHomeEditQuery } from '../generated/graphql'
import { PageLayout } from './PageLayout'
import { Surface } from './Surface'
import { DraggbleVideoList } from './DraggbleVideoList'
import { DraggableChannelAndPlaylistList } from './DraggableChannelAndPlaylistList'
import { HomeToolbar } from './HomeToolbar'
import { VideoPicker } from './VideoPicker'
import { ChannelPicker } from './ChannelPicker'
import { useError } from './ErrorModal'

interface HomeEditProps {
  featuredVideos: string[]
  navigationChannels: string[]
  onSubmit: (values: { featuredVideos: string[]; navigationChannels: string[] }) => Promise<void>
  onCancel: () => void
}

export const HomeEdit = (props: HomeEditProps) => {
  const { data, refetch, loading } = useGetHomeEditQuery({
    variables: { videoIds: props.featuredVideos },
  })
  const [isVideoPickerOpen, setIsVideoPickerOpen] = useState(false)
  const [isNavigationChannelPickerOpen, setIsNavigationChannelPickerOpen] = useState(false)
  const { setErrorMessage } = useError()

  const channels = (data && data.getAdminChannels ? data.getAdminChannels : []) as Channel[]

  return (
    <Formik
      initialValues={{
        featuredVideos: props.featuredVideos || [],
        navigationChannels: props.navigationChannels || [],
      }}
      validate={values => {
        let errors: any = {}
        // if (!values.featuredVideos || !values.featuredVideos.length) {
        //   errors.featuredVideos = 'Required'
        // }
        return errors
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await props.onSubmit(values)
        } catch (e) {
          setErrorMessage(e.message)
        }
        setSubmitting(false)
      }}
    >
      {({ values, errors, handleSubmit, setFieldValue, isSubmitting, resetForm }) => {
        const onDragEnd = (field: 'featuredVideos' | 'navigationChannels') => (
          result: DropResult
        ) => {
          const { destination, source, draggableId } = result

          if (!destination) return

          if (destination.droppableId === source.droppableId && destination.index === source.index)
            return

          const newVideoIds = Array.from(values[field])
          newVideoIds.splice(source.index, 1)
          newVideoIds.splice(destination.index, 0, draggableId)

          setFieldValue(field, newVideoIds)
        }
        const onRemove = (field: 'featuredVideos' | 'navigationChannels') => (id: string) => {
          setFieldValue(field, values[field].filter(v => v !== id))
        }

        const videos = (data && data.getVideos
          ? values.featuredVideos
              .map(id => find(data.getVideos, v => v._id === id))
              .filter(v => !!v)
          : []) as Video[]

        const navigationChannels = values.navigationChannels
          .map(id => find(channels, ({ _id }) => _id === id))
          .filter(v => !!v) as Channel[]

        return (
          <PageLayout loading={loading} noViewSwitch>
            <HomeToolbar
              onAddVideos={() => setIsVideoPickerOpen(true)}
              onAddNavigationChannel={() => setIsNavigationChannelPickerOpen(true)}
              onCancel={props.onCancel}
              loading={isSubmitting}
              save={handleSubmit}
            />
            <Surface title="Featured Videos" collapsable>
              <DraggbleVideoList
                videos={videos}
                onDragEnd={onDragEnd('featuredVideos')}
                onRemove={onRemove('featuredVideos')}
              />
            </Surface>
            <Surface title="Navigation Channels" collapsable>
              <DraggableChannelAndPlaylistList
                items={navigationChannels}
                onDragEnd={onDragEnd('navigationChannels')}
                onRemove={onRemove('navigationChannels')}
              />
            </Surface>
            <VideoPicker.MultiChannel
              filters
              open={isVideoPickerOpen}
              close={() => setIsVideoPickerOpen(false)}
              onSubmit={() => {
                refetch({ videoIds: values.featuredVideos })
                setIsVideoPickerOpen(false)
              }}
              selectedItems={values.featuredVideos}
              onSelect={(_id: string) => {
                if (values.featuredVideos.indexOf(_id) === -1) {
                  setFieldValue('featuredVideos', values.featuredVideos.concat([_id]))
                } else {
                  setFieldValue('featuredVideos', values.featuredVideos.filter(id => id !== _id))
                }
              }}
            />
            <ChannelPicker
              open={isNavigationChannelPickerOpen}
              close={() => setIsNavigationChannelPickerOpen(false)}
              selectedItems={values.navigationChannels}
              onSelect={(_id: string) => {
                if (values.navigationChannels.indexOf(_id) === -1) {
                  setFieldValue('navigationChannels', values.navigationChannels.concat([_id]))
                } else {
                  setFieldValue(
                    'navigationChannels',
                    values.navigationChannels.filter(id => id !== _id)
                  )
                }
              }}
            />
          </PageLayout>
        )
      }}
    </Formik>
  )
}

export const GET_HOME_EDIT = gql`
  query GetHomeEdit($videoIds: [String!]!) {
    getVideos(ids: $videoIds) {
      ...DraggbleVideoListData
    }
    getAdminChannels {
      ...DraggableChannelList
    }
  }
  ${DraggbleVideoList.Fragment}
  ${DraggableChannelAndPlaylistList.Fragment}
`
