import React, { Dispatch, SetStateAction } from 'react'
import gql from 'graphql-tag'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Switch,
  useMediaQuery,
} from '@material-ui/core'
import { Channel } from '../../generated/graphql'
import { VideoPickerProps } from './VideoPicker'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtersWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(5),
    },
    filtersWrapperSmall: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(5),
    },
    select: {
      margin: theme.spacing(1),
      width: 200,
    },
  })
)

interface VideoPickerFiltersProps extends VideoPickerProps {
  channelId: string
  channels?: Channel[]
  setCurrentChannel?: Dispatch<SetStateAction<string>>
  includeLive: boolean
  includeUnpublished: boolean
  includeUnlisted: boolean
  toggleLive: () => void
  toggleUnpublished: () => void
  toggleUnlisted: () => void
}

export const VideoPickerFilters = (props: VideoPickerFiltersProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const handleChannelChange = (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (props.setCurrentChannel) props.setCurrentChannel(e.target.value as string)
  }

  return (props.channels && props.channels.length) || props.filters ? (
    <div className={isSmall ? classes.filtersWrapperSmall : classes.filtersWrapper}>
      {props.channels && props.channels.length ? (
        <FormControl className={classes.select}>
          <InputLabel htmlFor="channel">Channel</InputLabel>
          <Select
            value={props.channelId}
            onChange={handleChannelChange}
            inputProps={{ name: 'channel', id: 'channel' }}
          >
            {props.channels.map(({ _id, title }) => (
              <MenuItem key={_id} value={_id}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <div />
      )}
      {props.filters ? (
        <FormGroup row>
          <FormControlLabel
            control={<Switch checked={props.includeLive} onChange={props.toggleLive} />}
            label="Live"
          />
          <FormControlLabel
            control={
              <Switch checked={props.includeUnpublished} onChange={props.toggleUnpublished} />
            }
            label="Unpublished"
          />
          <FormControlLabel
            control={<Switch checked={props.includeUnlisted} onChange={props.toggleUnlisted} />}
            label="Unlisted"
          />
        </FormGroup>
      ) : (
        <div />
      )}
    </div>
  ) : null
}

VideoPickerFilters.Fragment = gql`
  fragment VideoPickerFiltersPropsData on Channel {
    _id
    title
  }
`
