import { Formik } from 'formik'
import gql from 'graphql-tag'
import { find } from 'lodash'
import moment from 'moment-timezone'
import React, { useRef } from 'react'
// import MomentUtils from '@date-io/moment'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
// import {
//   MuiPickersUtilsProvider,
//   MaterialUiPickersDate,
//   KeyboardDateTimePicker,
// } from '@material-ui/pickers'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { FileUpload, FileUploadVariants } from '../components/FileUpload'
import { TabPanel, TabPanelProps } from '../components/TabPanel'
import { VideoInput, useGetLiveVideoUploadChannelsQuery } from '../generated/graphql'
import { useError } from './ErrorModal'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageUploadTitle: {
      marginTop: theme.spacing(1),
    },
    textField: {
      width: '100%',
    },
    formControl: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    submitButton: {
      width: '75%',
      marginRight: theme.spacing(1),
      color: '#fff',
    },
    resetButton: {
      width: '25%',
      marginLeft: theme.spacing(1),
    },
    buttonRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    timeMessage: {
      marginBottom: theme.spacing(2),
      fontSize: 12,
    },
  })
)

interface LiveVideoUploadProps extends TabPanelProps {
  createVideo: (video: VideoInput) => Promise<any>
}

export const LiveVideoUpload = (props: LiveVideoUploadProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { data } = useGetLiveVideoUploadChannelsQuery()
  const { setErrorMessage } = useError()
  const imageUploadRef = useRef()
  const channels = data && data.getAdminChannels ? data.getAdminChannels : []
  return (
    <TabPanel value={props.value} index={props.index}>
      <Formik
        initialValues={{
          title: '',
          summary: '',
          channelId: '',
          playlistId: '',
          posterUploadId: '',
          publishedAt: null,
          useFeed: false,
          feed: '',
        }}
        validate={values => {
          const errors: any = {}
          if (!values.title) {
            errors.title = 'Required'
          }
          if (!values.summary) {
            errors.summary = 'Required'
          }
          if (!values.channelId) {
            errors.channelId = 'Required'
          }
          // if (!values.publishedAt) {
          //   errors.publishedAt = 'Required'
          // }
          if (!values.posterUploadId) {
            errors.posterUploadId = 'Required'
          }
          if (values.useFeed && !values.feed) {
            errors.feed = 'Required'
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await props.createVideo({
              ...values,
              publishedAt: moment()
                .tz('America/Chicago')
                .toDate(),
              published: false,
              live: true,
              unlisted: false,
            })
          } catch (e) {
            setErrorMessage(e.message)
          }
          setSubmitting(false)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => {
          const reset = () => {
            const imageUpload = imageUploadRef.current! as any
            resetForm()
            if (imageUpload.reset) {
              imageUpload.reset()
            }
          }
          const channel = values.channelId
            ? find(channels, ({ _id }) => _id === values.channelId)
            : null
          const playlists = channel ? channel.playlists : null
          return (
            // <MuiPickersUtilsProvider utils={MomentUtils}>
            <form onSubmit={handleSubmit}>
              <FileUpload
                ref={imageUploadRef}
                variant={FileUploadVariants.image}
                onComplete={(id: string) => setFieldValue('posterUploadId', id)}
                onStart={() => setFieldTouched('posterUploadId')}
              />
              <Typography variant="subtitle1" component="p" className={classes.imageUploadTitle}>
                Thumbnail Upload
              </Typography>
              {touched.posterUploadId && errors.posterUploadId ? (
                <FormHelperText error>{errors.posterUploadId}</FormHelperText>
              ) : null}
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="channelId">Channel</InputLabel>
                <Select
                  value={values.channelId}
                  onChange={e => setFieldValue('channelId', e.target.value)}
                  inputProps={{ name: 'channelId', id: 'channelId' }}
                  error={!!(touched.channelId && errors.channelId)}
                >
                  {channels.map(({ _id, title }) => (
                    <MenuItem key={_id} value={_id}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {touched.channelId && errors.channelId ? (
                <FormHelperText error>{errors.channelId}</FormHelperText>
              ) : null}
              {playlists && playlists.length ? (
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="playlistId">Playlist</InputLabel>
                  <Select
                    value={values.playlistId}
                    onChange={e => setFieldValue('playlistId', e.target.value)}
                    inputProps={{ name: 'playlistId', id: 'playlistId' }}
                  >
                    {playlists.map(({ _id, title }) => (
                      <MenuItem key={_id} value={_id}>
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null}
              <TextField
                id="title"
                label="Title"
                name="title"
                className={classes.textField}
                value={values.title}
                onChange={handleChange}
                margin="normal"
                error={!!(touched.title && errors.title)}
              />
              {touched.title && errors.title ? (
                <FormHelperText error>{errors.title}</FormHelperText>
              ) : null}
              <TextField
                id="summary"
                label="Summary"
                name="summary"
                className={classes.textField}
                value={values.summary}
                onChange={handleChange}
                multiline
                maxRows="4"
                margin="normal"
                error={!!(touched.summary && errors.summary)}
              />
              {touched.summary && errors.summary ? (
                <FormHelperText error>{errors.summary}</FormHelperText>
              ) : null}
              {/* <FormControl className={classes.formControl}>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Publish Date & Time"
                    format="MM/D/YYYY hh:mm"
                    minDate={moment()
                      .tz('America/Chicago')
                      .toDate()}
                    value={values.publishedAt}
                    onChange={(date: MaterialUiPickersDate) => {
                      if (date) {
                        const time = moment(date)
                        setFieldValue('publishedAt', time.tz('America/Chicago').toDate())
                      }
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date and time',
                    }}
                  />
                  {!errors.publishedAt ? (
                    <Typography component="p" className={classes.timeMessage}>
                      Time is converted to Austin local time
                    </Typography>
                  ) : null}
                </FormControl>
                {touched.publishedAt && errors.publishedAt ? (
                  <FormHelperText error>{errors.publishedAt}</FormHelperText>
                ) : null} */}
              <FormGroup>
                <FormControlLabel
                  label="Use House Feed"
                  control={
                    <Checkbox checked={values.useFeed} onChange={handleChange} name="useFeed" />
                  }
                />
                {values.useFeed ? (
                  <FormControl className={classes.formControl}>
                    <InputLabel>Feed</InputLabel>
                    <Select value={values.feed} onChange={handleChange} name="feed">
                      <MenuItem value="alex">The Alex Jones Show</MenuItem>
                      <MenuItem value="david">The David Knight Show</MenuItem>
                      <MenuItem value="owen">War Room With Owen Shroyer</MenuItem>
                      <MenuItem value="house">House Feed</MenuItem>
                    </Select>
                    {touched.feed && errors.feed ? (
                      <FormHelperText error>{errors.feed}</FormHelperText>
                    ) : null}
                  </FormControl>
                ) : null}
              </FormGroup>
              <div className={classes.buttonRow}>
                <Button
                  type="submit"
                  disabled={isSubmitting || !values.posterUploadId}
                  variant="contained"
                  color="secondary"
                  className={classes.submitButton}
                >
                  {isSubmitting ? <CircularProgress size="24px" color="secondary" /> : 'Submit'}
                </Button>
                <Button variant="contained" className={classes.resetButton} onClick={reset}>
                  Reset
                </Button>
              </div>
            </form>
            // </MuiPickersUtilsProvider>
          )
        }}
      </Formik>
    </TabPanel>
  )
}

export const GET_LIVE_VIDEO_UPLOAD_CHANNELS = gql`
  query GetLiveVideoUploadChannels {
    getAdminChannels {
      _id
      title
      playlists {
        _id
        title
      }
    }
  }
`
