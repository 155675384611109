import React from 'react'
import { InputAdornment, IconButton } from '@material-ui/core'
import FileCopyIcon from '@material-ui/icons/FileCopy'

interface CopyButtonProps {
  onClick: () => void
}

export const CopyButton = (props: CopyButtonProps) => (
  <InputAdornment position="end">
    <IconButton aria-label="copy image" onClick={props.onClick}>
      <FileCopyIcon />
    </IconButton>
  </InputAdornment>
)
