import React from 'react'
import { PageLayout } from '../components/PageLayout'
import { Surface } from '../components/Surface'
import { ChangePassword } from '../components/ChangePassword'

export const Settings = () => {
  return (
    <PageLayout loading={false} noViewSwitch>
      <Surface title="Change Password">
        <ChangePassword />
      </Surface>
    </PageLayout>
  )
}
