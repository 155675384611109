import React, { useState } from 'react'
import { Formik } from 'formik'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Drawer,
  Button,
  TextField,
  Typography,
  FormHelperText,
  CircularProgress,
  Hidden,
  useMediaQuery,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import { Playlist, UpdatePlaylistInput } from '../generated/graphql'
import { VideoPicker } from './VideoPicker'
import { useError } from './ErrorModal'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editDrawer: {
      margin: theme.spacing(2),
      width: 400,
    },
    editDrawerSmall: {
      padding: theme.spacing(2),
      width: '100%',
    },
    textField: {
      width: '100%',
    },
    formControl: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    submitButton: {
      width: '100%',
      marginTop: theme.spacing(2),
      color: '#fff',
    },
    selectVideosButton: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    loadMoreButton: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
  })
)

interface PlaylistEditProps extends Playlist {
  open: boolean
  close: () => void
  onSubmit: (playlist: UpdatePlaylistInput) => Promise<any>
  videoIds: string[]
}

export const PlaylistEdit = (props: PlaylistEditProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { setErrorMessage } = useError()
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const [videoPickerOpen, setVideoPickerOpen] = useState(false)

  return (
    <Formik
      initialValues={{
        title: props.title,
        summary: props.summary || '',
        videoIds: props.videoIds,
      }}
      validate={values => {
        let errors: any = {}
        if (!values.title) {
          errors.title = 'Required'
        }
        if (!values.videoIds.length) {
          errors.videoIds = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await props.onSubmit(values)
        } catch (e) {
          setErrorMessage(e.message)
        }
        setSubmitting(false)
        props.close()
        resetForm()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        resetForm,
      }) => {
        const close = () => {
          props.close()
          resetForm()
        }
        return (
          <Drawer anchor={isSmall ? 'bottom' : 'right'} open={props.open} onClose={close}>
            <div className={isSmall ? classes.editDrawerSmall : classes.editDrawer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h4" component="h4" gutterBottom>
                  Edit Playlist
                </Typography>
                <Hidden smUp>
                  <Close onClick={close} />
                </Hidden>
              </div>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  id="title"
                  label="Title"
                  name="title"
                  margin="dense"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                />
                {touched.title && errors.title ? (
                  <FormHelperText error>{errors.title}</FormHelperText>
                ) : null}
                <TextField
                  fullWidth
                  id="summary"
                  label="Summary (Optional)"
                  name="summary"
                  margin="dense"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.summary}
                />
                {touched.summary && errors.summary ? (
                  <FormHelperText error>{errors.summary}</FormHelperText>
                ) : null}
                <Button
                  variant="contained"
                  className={classes.selectVideosButton}
                  onClick={() => setVideoPickerOpen(true)}
                >
                  Select Videos {values.videoIds.length ? `(${values.videoIds.length})` : ''}
                </Button>
                {touched.videoIds && errors.videoIds ? (
                  <FormHelperText error>{errors.videoIds}</FormHelperText>
                ) : null}
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  variant="contained"
                  color="secondary"
                  className={classes.submitButton}
                >
                  {isSubmitting ? <CircularProgress size="24px" color="secondary" /> : 'Submit'}
                </Button>
              </form>
            </div>
            {props.isGlobal ? (
              <VideoPicker.MultiChannel
                open={videoPickerOpen}
                close={() => setVideoPickerOpen(false)}
                selectedItems={values.videoIds}
                onSelect={(_id: string) => {
                  if (values.videoIds.indexOf(_id) === -1) {
                    setFieldValue('videoIds', values.videoIds.concat([_id]))
                  } else {
                    setFieldValue('videoIds', values.videoIds.filter(id => id !== _id))
                  }
                }}
              />
            ) : (
              <VideoPicker
                open={videoPickerOpen}
                close={() => setVideoPickerOpen(false)}
                selectedItems={values.videoIds}
                channelId={props.channel!._id}
                onSelect={(_id: string) => {
                  if (values.videoIds.indexOf(_id) === -1) {
                    setFieldValue('videoIds', values.videoIds.concat([_id]))
                  } else {
                    setFieldValue('videoIds', values.videoIds.filter(id => id !== _id))
                  }
                }}
              />
            )}
          </Drawer>
        )
      }}
    </Formik>
  )
}
