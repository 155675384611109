import React, { useState, Dispatch, SetStateAction } from 'react'
import gql from 'graphql-tag'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogContent,
  Slide,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { TransitionProps } from '@material-ui/core/transitions'
import { Channel, useGetVideoPickerChannelsQuery } from '../../generated/graphql'
import { SwitchViewbutton } from '../SwitchViewButton'
import { VideoPickerVideos } from './VideoPickerVideos'
import { VideoPickerFilters } from './VideoPickerFilters'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
      color: '#fff',
    },
    submitButton: {
      color: '#fff',
      marginLeft: theme.spacing(1),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
    },
  })
)

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface VideoPickerBaseProps {
  open: boolean
  single?: boolean
  filters?: boolean
  close: () => void
  onSubmit?: () => void
  onSelect: (id: string) => void
  selectedItems: string[]
}

export interface VideoPickerProps extends VideoPickerBaseProps {
  channelId: string
  channels?: Channel[]
  liveOnly?: boolean
  setCurrentChannel?: Dispatch<SetStateAction<string>>
}

export const VideoPicker = (props: VideoPickerProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [includeLive, setIncludeLive] = useState(true)
  const [includeUnpublished, setIncludeUnpublished] = useState(false)
  const [includeUnlisted, setIncludeUnlisted] = useState(false)
  const childProps = { ...props, includeLive, includeUnpublished, includeUnlisted }

  return (
    <Dialog fullScreen open={props.open} onClose={props.close} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" onClick={props.close} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Videos
          </Typography>
          <SwitchViewbutton />
          {props.single ? null : (
            <Button
              variant="contained"
              color="secondary"
              disabled={!props.selectedItems.length}
              onClick={props.onSubmit ? props.onSubmit : props.close}
              className={classes.submitButton}
            >
              Submit
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <DialogContent className={classes.content}>
        <VideoPickerFilters
          {...childProps}
          toggleLive={() => setIncludeLive(!includeLive)}
          toggleUnpublished={() => setIncludeUnpublished(!includeUnpublished)}
          toggleUnlisted={() => setIncludeUnlisted(!includeUnlisted)}
        />
        <VideoPickerVideos {...childProps} liveOnly={props.liveOnly} />
      </DialogContent>
    </Dialog>
  )
}

VideoPicker.MultiChannel = ((props: VideoPickerBaseProps) => {
  const { data } = useGetVideoPickerChannelsQuery()
  const [currentChannel, setCurrentChannel] = useState('')
  const channels = (data && data.getAdminChannels ? data.getAdminChannels : []) as Channel[]

  return (
    <VideoPicker
      {...props}
      channelId={currentChannel}
      channels={channels}
      setCurrentChannel={setCurrentChannel}
    />
  )
}) as React.FC<VideoPickerBaseProps>

export const GET_VIDEO_PICKER_CHANNELS = gql`
  query GetVideoPickerChannels {
    getAdminChannels {
      ...VideoPickerFiltersPropsData
    }
  }
  ${VideoPickerFilters.Fragment}
`
