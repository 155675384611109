import { Typography } from '@material-ui/core'
import gql from 'graphql-tag'
import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Comment } from '../../generated/graphql'
import { useLoadMoreComments } from '../../lib/graphql/comment'
import { Loader } from '../Loader'
import { PageLoader } from '../PageLoader'
import { CommentCard } from './CommentCard'
import { CommentModerationCard } from './CommentModerationCard'

interface CommentListProps {
  query: (...args: any) => any
  queryName: string
  search?: string
  queryVars?: any
  openReportModal?: (id: string) => void
  isModerate?: boolean
}

export const CommentList = (props: CommentListProps) => {
  const [load, { data, loading, called, fetchMore }] = props.query(props.queryVars)
  const { loadMoreComments, hasMoreComments } = useLoadMoreComments(fetchMore, props.queryName)

  useEffect(() => {
    load({ variables: { query: props.search } })
    return () => {}
  }, [props.search])

  const comments = (data && data[props.queryName] ? data[props.queryName] : []) as Comment[]
  const content = comments.map(comment =>
    props.isModerate ? (
      //@ts-ignore
      <CommentModerationCard
        key={comment._id}
        {...comment}
        openReportModal={props.openReportModal}
      />
    ) : (
      <CommentCard key={comment._id} {...comment} />
    )
  )

  return (
    <>
      {!called || loading ? (
        <PageLoader />
      ) : comments && comments.length ? (
        loadMoreComments ? (
          <InfiniteScroll
            useWindow={true}
            pageStart={0}
            threshold={50}
            loader={<Loader key={0} />}
            hasMore={hasMoreComments}
            loadMore={loadMoreComments}
          >
            {content}
          </InfiniteScroll>
        ) : (
          content
        )
      ) : (
        <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: 20 }}>
          No comments found
        </Typography>
      )}
    </>
  )
}

CommentList.Fragment = gql`
  fragment CommentListData on Comment {
    _id
    ...CommentContentData
    ...CommentModerationCardData
  }
  ${CommentCard.Fragment}
  ${CommentModerationCard.Fragment}
`
