import { useState, useRef, useEffect } from 'react'
import { uniqBy, orderBy, debounce } from 'lodash'
import { DataProxy } from 'apollo-cache'
import { DocumentNode } from 'graphql'
import {
  Comment,
  useApproveCommentMutation,
  useRejectCommentMutation,
  GetApprovedCommentsQuery,
  GetRejectedCommentsQuery,
} from '../../generated/graphql'
import {
  GET_REPORTED_COMMENTS,
  GET_APPROVED_COMMENTS,
  GET_REJECTED_COMMENTS,
  GET_UNFILTERED_COMMENTS,
} from '../../pages/Comments'
import { COMMENT_PAGE_SIZE, LOAD_MORE_DEBOUCE_MS } from '../../consts'

const updateCommentList = (
  cache: DataProxy,
  args: { query: DocumentNode; queryName: string; commentId: string }
) => {
  const { query, queryName, commentId } = args
  try {
    const comments = cache.readQuery({ query }) as any
    if (comments) {
      cache.writeQuery({
        query,
        data: {
          [queryName]: orderBy(
            comments[queryName].filter(({ _id }: Comment) => _id !== commentId),
            'createdAt',
            'desc'
          ),
        },
      })
    }
  } catch (e) {
    console.log('ERROR', e)
  }
}

export const useApproveComment = (id: string) => {
  const [approveMutation] = useApproveCommentMutation()
  const approve = () =>
    approveMutation({
      variables: { id },
      update(cache, { data }) {
        if (data) {
          try {
            const approvedComments = cache.readQuery({
              query: GET_APPROVED_COMMENTS,
            }) as GetApprovedCommentsQuery
            if (approvedComments) {
              cache.writeQuery({
                query: GET_APPROVED_COMMENTS,
                data: {
                  getApprovedComments: orderBy(
                    [data.approveComment, ...approvedComments.getApprovedComments],
                    'createdAt',
                    'desc'
                  ),
                },
              })
            }
          } catch (e) {
            console.log('ERROR', e)
          }
          updateCommentList(cache, {
            query: GET_REJECTED_COMMENTS,
            queryName: 'getRejectedComments',
            commentId: data.approveComment._id,
          })
          updateCommentList(cache, {
            query: GET_REPORTED_COMMENTS,
            queryName: 'getReportedComments',
            commentId: data.approveComment._id,
          })
          updateCommentList(cache, {
            query: GET_UNFILTERED_COMMENTS,
            queryName: 'getUnfilteredComments',
            commentId: data.approveComment._id,
          })
        }
      },
    })
  return approve
}

export const useRejectComment = (id: string) => {
  const [rejectMutation] = useRejectCommentMutation()
  const reject = () =>
    rejectMutation({
      variables: { id },
      update(cache, { data }) {
        if (data) {
          try {
            const rejectedComments = cache.readQuery({
              query: GET_REJECTED_COMMENTS,
            }) as GetRejectedCommentsQuery
            if (rejectedComments) {
              cache.writeQuery({
                query: GET_REJECTED_COMMENTS,
                data: {
                  getRejectedComments: orderBy(
                    [data.rejectComment, ...rejectedComments.getRejectedComments],
                    'createdAt',
                    'desc'
                  ),
                },
              })
            }
          } catch (e) {
            console.log('ERROR', e)
          }
          updateCommentList(cache, {
            query: GET_APPROVED_COMMENTS,
            queryName: 'getApprovedComments',
            commentId: data.rejectComment._id,
          })
          updateCommentList(cache, {
            query: GET_REPORTED_COMMENTS,
            queryName: 'getReportedComments',
            commentId: data.rejectComment._id,
          })
          updateCommentList(cache, {
            query: GET_UNFILTERED_COMMENTS,
            queryName: 'getUnfilteredComments',
            commentId: data.rejectComment._id,
          })
        }
      },
    })
  return reject
}

export const useLoadMoreComments = (fetchMoreComments: any, queryName: string) => {
  const [hasMoreComments, setHasMoreComments] = useState(true)
  const page = useRef(0)
  const fetchMoreCommentsFunc = useRef<any>()

  useEffect(() => {
    fetchMoreCommentsFunc.current = fetchMoreComments
    return () => {}
  }, [fetchMoreComments])

  const loadMoreComments = debounce(() => {
    if (hasMoreComments) {
      const newVideoPage = page.current + 1
      page.current = newVideoPage
      fetchMoreCommentsFunc.current({
        variables: { offset: newVideoPage * COMMENT_PAGE_SIZE },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev
          const comments = uniqBy([...prev[queryName], ...fetchMoreResult[queryName]], '_id')
          if (
            !fetchMoreResult ||
            !fetchMoreResult[queryName] ||
            !fetchMoreResult[queryName].length ||
            fetchMoreResult[queryName].length < COMMENT_PAGE_SIZE
          ) {
            setHasMoreComments(false)
          }
          return Object.assign({}, prev, {
            [queryName]: comments,
          })
        },
      })
    }
  }, LOAD_MORE_DEBOUCE_MS)

  const resetLoadMoreComments = () => {
    page.current = 0
    setHasMoreComments(true)
  }
  return { loadMoreComments, resetLoadMoreComments, hasMoreComments }
}
