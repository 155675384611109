import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { PageLayout } from '../components/PageLayout'
import { SearchVideos } from '../components/SearchVideos'

const getQuery = (search: string) => {
  if (!search || !search.length) return ''
  const query = search.substring(1).split('=')
  if (!query.length) return ''
  if (query[0] === 'query') return unescape(query[1])
  return ''
}

export const Search = () => {
  const router = useReactRouter()
  const query = getQuery(router.location.search)

  useEffect(() => {
    if (!query) {
      router.history.push('/')
    }
    return () => {}
  }, [query])

  return (
    <PageLayout loading={false} noViewSwitch>
      <SearchVideos query={query} />
    </PageLayout>
  )
}
