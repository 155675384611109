import React from 'react'
import gql from 'graphql-tag'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'
import { Video } from '../generated/graphql'

const Wrapper = styled.div`
  width: 100%;
`

export const VideoPlayer = (props: Partial<Video>) => (
  <Wrapper>
    <ReactPlayer
      url={props.streamUrl!}
      className="react-player"
      controls
      width="100%"
      height="100%"
    />
  </Wrapper>
)

VideoPlayer.Fragment = gql`
  fragment VideoPlayerData on Video {
    _id
    streamUrl
  }
`
