import styled from '@emotion/styled'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import React from 'react'
import { PageLoader } from '../components/PageLoader'
import { SwitchViewbutton } from '../components/SwitchViewButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    extrasWrapper: {
      position: 'relative',
      top: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px',
      width: '100%',
    },
  })
)

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  @media (min-width: 500px) {
    width: 75%;
  }
`

interface PageLayoutProps {
  children: JSX.Element | JSX.Element[] | Array<JSX.Element | null>
  loading: boolean
  noViewSwitch?: boolean
  customButton?: JSX.Element
}

export const PageLayout = (props: PageLayoutProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <Wrapper>
      {props.loading ? (
        <PageLoader />
      ) : (
        <>
          <div className={classes.extrasWrapper}>
            {props.customButton ? props.customButton : null}
            {props.noViewSwitch ? null : <SwitchViewbutton />}
          </div>
          {props.children}
        </>
      )}
    </Wrapper>
  )
}
