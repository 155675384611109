import MomentUtils from '@date-io/moment'
import {
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider as MUIThemeProvider } from '@material-ui/styles'
import { Formik } from 'formik'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { Channel, ChannelLinks, UpdateChannelInput } from '../generated/graphql'
import { useDangerTheme } from '../lib/theme'
import { isLink } from '../lib/utils'
import { useError } from './ErrorModal'
import { FileUpload, FileUploadVariants } from './FileUpload'
import { VideoPicker } from './VideoPicker'

const EMPTY_DAY_TIMES_LIVE = {
  sun: [],
  mon: [],
  tue: [],
  wed: [],
  thu: [],
  fri: [],
  sat: [],
}

const DAYS = [
  { label: 'S', value: 'sun' },
  { label: 'M', value: 'mon' },
  { label: 'T', value: 'tue' },
  { label: 'W', value: 'wed' },
  { label: 'T', value: 'thu' },
  { label: 'F', value: 'fri' },
  { label: 'S', value: 'sat' },
]

enum ProfileLink {
  websiteLink = 'websiteLink',
  facebookLink = 'facebookLink',
  twitterLink = 'twitterLink',
  gabLink = 'gabLink',
  mindsLink = 'mindsLink',
  subscribeStarLink = 'subscribeStarLink',
  telegramLink = 'telegramLink',
}

const PROFILE_LINKS = [
  { key: ProfileLink.websiteLink, label: 'Website Link', value: 'website' },
  { key: ProfileLink.facebookLink, label: 'Facebook Link', value: 'facebook' },
  { key: ProfileLink.twitterLink, label: 'Twitter Link', value: 'twitter' },
  { key: ProfileLink.gabLink, label: 'Gab Link', value: 'gab' },
  { key: ProfileLink.mindsLink, label: 'Minds Link', value: 'minds' },
  { key: ProfileLink.subscribeStarLink, label: 'Subscribe Star Link', value: 'subscribeStar' },
  { key: ProfileLink.telegramLink, label: 'Telegram Link', value: 'telegram' },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editDrawer: {
      margin: theme.spacing(2),
      width: 400,
    },
    submitButton: {
      width: '100%',
      marginTop: theme.spacing(2),
      color: '#fff',
    },
    selectVideoButtonsWrapper: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    selectVideosButton: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    uploadWrapper: {
      marginTop: theme.spacing(2),
    },
    alwaysLiveCheckbox: {
      marginTop: theme.spacing(1),
    },
    dayTimesLiveButtons: {
      marginTop: theme.spacing(1),
    },
  })
)

interface ChannelEditProps extends Channel {
  open: boolean
  close: () => void
  onSubmit: (channel: UpdateChannelInput) => Promise<any>
}

export const ChannelEdit = (props: ChannelEditProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [selectedDay, setSelectedDay] = useState('')
  const [featuredVideoPickerOpen, setFeaturedVideoPickerOpen] = useState(false)
  const [liveStreamVideoPickerOpen, setLiveStreamVideoPickerOpen] = useState(false)
  const [avatarUploading, setAvatarUploading] = useState(false)
  const [coverImageUploading, setCoverImageUploading] = useState(false)
  const { setErrorMessage } = useError()
  const dangerTheme = useDangerTheme()

  return (
    <Formik
      initialValues={{
        title: props.title,
        summary: props.summary,
        avatar: '',
        coverImage: '',
        alwaysLive: !!props.alwaysLive,
        dayTimesLive: props.dayTimesLive
          ? Object.keys(EMPTY_DAY_TIMES_LIVE).reduce(
              (obj, key) => {
                const dayTimesLive = { ...props.dayTimesLive } as any
                obj[key] =
                  dayTimesLive && dayTimesLive[key] && dayTimesLive[key].length
                    ? dayTimesLive[key].map((ts: string) => {
                        const times = ts.split(':')
                        return {
                          from: new Date(parseInt(times[0])),
                          to: new Date(parseInt(times[1])),
                        }
                      })
                    : []
                return obj
              },
              {} as any
            )
          : EMPTY_DAY_TIMES_LIVE,
        showTimes: props.showInfo ? props.showInfo.times : '',
        showPhone: props.showInfo ? props.showInfo.phone : '',
        featuredVideo: props.featuredVideo ? props.featuredVideo._id : '',
        liveStreamVideo: props.liveStreamVideo ? props.liveStreamVideo._id : '',
        ...PROFILE_LINKS.reduce((obj: any, { key, value }) => {
          obj[key] = props.links ? props.links[value as keyof ChannelLinks] || '' : ''
          return obj
        }, {}),
      }}
      validate={values => {
        let errors: any = {}
        if (!values.title) {
          errors.title = 'Required'
        } else if (!/^[a-zA-Z0-9\s]{3,}$/.test(values.title)) {
          errors.title = 'Invalid title. Must use only letters, numbers and spaces.'
        }
        if (!values.summary) {
          errors.summary = 'Required'
        }
        PROFILE_LINKS.forEach(({ key }) => {
          if (values[ProfileLink[key]] && !isLink(values[ProfileLink[key]])) {
            errors[ProfileLink[key]] = 'Invalid Link'
          }
        })
        return errors
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const toSubmit = {
          ...values,
          showInfo: {
            times: values.showTimes,
            phone: values.showPhone,
          },
          links: PROFILE_LINKS.reduce((obj: any, { key, value }) => {
            obj[value as keyof ChannelLinks] = values[key]
            return obj
          }, {}),
          dayTimesLive: values.alwaysLive
            ? EMPTY_DAY_TIMES_LIVE
            : Object.keys(EMPTY_DAY_TIMES_LIVE).reduce((obj: any, key: string) => {
                const dayTimesLive = { ...values.dayTimesLive } as any
                obj[key] = dayTimesLive[key]
                  ? dayTimesLive[key]
                      .filter((t: { from: Date; to: Date }) => !!(t.from && t.to))
                      .map(
                        (t: { from: Date; to: Date }) =>
                          `${Math.floor(t.from.getTime() / 1000) * 1000}:${Math.floor(
                            t.to.getTime() / 1000
                          ) * 1000}`
                      )
                  : []
                return obj
              }, {}),
        }

        delete toSubmit.showTimes
        delete toSubmit.showPhone
        PROFILE_LINKS.forEach(({ key }) => {
          delete toSubmit[key]
        })

        try {
          await props.onSubmit(toSubmit)
        } catch (e) {
          setErrorMessage(e.message)
        }
        setSubmitting(false)
        props.close()
        resetForm()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        resetForm,
      }) => {
        const close = () => {
          props.close()
          resetForm()
        }
        const resetAvatar = () => {
          setFieldValue('avatar', '')
          setAvatarUploading(true)
        }
        const onAvatarComplete = (imageId: string) => {
          setFieldValue('avatar', imageId)
          setAvatarUploading(false)
        }
        const resetCoverImage = () => {
          setFieldValue('coverImage', '')
          setCoverImageUploading(true)
        }
        const onCoverImageComplete = (imageId: string) => {
          setFieldValue('coverImage', imageId)
          setCoverImageUploading(false)
        }
        return (
          <Drawer anchor="right" open={props.open} onClose={close}>
            <div className={classes.editDrawer}>
              <Typography variant="h4" component="h4" gutterBottom>
                Edit Channel
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                  />
                  {touched.title && errors.title ? (
                    <FormHelperText error>{errors.title}</FormHelperText>
                  ) : null}
                  <TextField
                    fullWidth
                    id="summary"
                    label="Summary"
                    name="summary"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.summary}
                  />
                  {touched.summary && errors.summary ? (
                    <FormHelperText error>{errors.summary}</FormHelperText>
                  ) : null}
                  <TextField
                    fullWidth
                    id="showTimes"
                    label="Show Times"
                    name="showTimes"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.showTimes}
                  />
                  <TextField
                    fullWidth
                    id="showPhone"
                    label="Show Phone"
                    name="showPhone"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.showPhone}
                  />
                  {PROFILE_LINKS.map(({ key, label }) => (
                    <>
                      <TextField
                        fullWidth
                        id={key}
                        label={label}
                        name={key}
                        margin="dense"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[key]}
                      />
                      {touched[key] && errors[key] ? (
                        <FormHelperText error>{errors[key]}</FormHelperText>
                      ) : null}
                    </>
                  ))}
                  <FormControlLabel
                    className={classes.alwaysLiveCheckbox}
                    control={
                      <Checkbox
                        color="secondary"
                        checked={values.alwaysLive}
                        onClick={() => setFieldValue('alwaysLive', !values.alwaysLive)}
                      />
                    }
                    label="Always live"
                    labelPlacement="end"
                  />
                  {!values.alwaysLive ? (
                    <div className={classes.dayTimesLiveButtons}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {DAYS.map(day => (
                          <IconButton
                            key={day.value}
                            size="small"
                            color={selectedDay === day.value ? 'secondary' : undefined}
                            onClick={() => setSelectedDay(day.value)}
                          >
                            <span
                              style={{
                                width: 30,
                                height: 30,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {day.label}
                            </span>
                          </IconButton>
                        ))}
                      </div>
                      {selectedDay ? (
                        <div>
                          {values.dayTimesLive[selectedDay].map((t: any, i: number) => (
                            <div
                              key={`${selectedDay}-${i}`}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-from"
                                format="hh:mm a"
                                label="From"
                                value={t.from}
                                onChange={(e: any) =>
                                  setFieldValue('dayTimesLive', {
                                    ...values.dayTimesLive,
                                    [selectedDay]: values.dayTimesLive[selectedDay].map(
                                      (t: any, j: number) => {
                                        if (i === j) {
                                          return { ...t, from: e.tz('America/Chicago').toDate() }
                                        }
                                        return t
                                      }
                                    ),
                                  })
                                }
                                KeyboardButtonProps={{
                                  'aria-label': 'change from time',
                                }}
                              />
                              <KeyboardTimePicker
                                margin="normal"
                                id="time-picker-to"
                                format="hh:mm a"
                                label="To"
                                value={t.to}
                                onChange={(e: any) =>
                                  setFieldValue('dayTimesLive', {
                                    ...values.dayTimesLive,
                                    [selectedDay]: values.dayTimesLive[selectedDay].map(
                                      (t: any, j: number) => {
                                        if (i === j) {
                                          return { ...t, to: e.tz('America/Chicago').toDate() }
                                        }
                                        return t
                                      }
                                    ),
                                  })
                                }
                                KeyboardButtonProps={{
                                  'aria-label': 'change to time',
                                }}
                              />
                              <MUIThemeProvider theme={dangerTheme}>
                                <IconButton
                                  style={{ marginTop: 20 }}
                                  color="primary"
                                  onClick={() =>
                                    setFieldValue('dayTimesLive', {
                                      ...values.dayTimesLive,
                                      [selectedDay]: values.dayTimesLive[selectedDay].filter(
                                        (t: any, j: number) => i !== j
                                      ),
                                    })
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </MUIThemeProvider>
                            </div>
                          ))}
                          <Button
                            variant="contained"
                            className={classes.selectVideosButton}
                            onClick={() =>
                              setFieldValue('dayTimesLive', {
                                ...values.dayTimesLive,
                                [selectedDay]: [
                                  ...values.dayTimesLive[selectedDay],
                                  { from: null, to: null },
                                ],
                              })
                            }
                          >
                            Add Time
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <div className={classes.uploadWrapper}>
                    <FileUpload
                      variant={FileUploadVariants.image}
                      defaultImage={props.avatar}
                      onStart={resetAvatar}
                      onComplete={onAvatarComplete}
                      title="Upload Avatar"
                    />
                  </div>
                  <div className={classes.uploadWrapper}>
                    <FileUpload
                      variant={FileUploadVariants.image}
                      defaultImage={props.coverImage}
                      onStart={resetCoverImage}
                      onComplete={onCoverImageComplete}
                      title="Upload Cover Image"
                    />
                  </div>
                  <div className={classes.selectVideoButtonsWrapper}>
                    <Button
                      variant="contained"
                      className={classes.selectVideosButton}
                      onClick={() => setFeaturedVideoPickerOpen(true)}
                    >
                      Select Featured Video {values.featuredVideo ? '(1)' : ''}
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.selectVideosButton}
                      onClick={() => setLiveStreamVideoPickerOpen(true)}
                    >
                      Select Live Stream Video {values.liveStreamVideo ? '(1)' : ''}
                    </Button>
                  </div>
                  <Button
                    type="submit"
                    disabled={isSubmitting || avatarUploading || coverImageUploading}
                    variant="contained"
                    color="secondary"
                    className={classes.submitButton}
                  >
                    {isSubmitting ? <CircularProgress size="24px" color="secondary" /> : 'Submit'}
                  </Button>
                </form>
              </MuiPickersUtilsProvider>
            </div>
            <VideoPicker
              single
              open={featuredVideoPickerOpen}
              close={() => setFeaturedVideoPickerOpen(false)}
              channelId={props._id}
              selectedItems={[values.featuredVideo]}
              onSelect={(_id: string) => {
                setFieldValue('featuredVideo', _id)
                setFeaturedVideoPickerOpen(false)
              }}
            />
            <VideoPicker
              single
              liveOnly
              open={liveStreamVideoPickerOpen}
              close={() => setLiveStreamVideoPickerOpen(false)}
              channelId={props._id}
              selectedItems={[values.liveStreamVideo]}
              onSelect={(_id: string) => {
                setFieldValue('liveStreamVideo', _id)
                setLiveStreamVideoPickerOpen(false)
              }}
            />
          </Drawer>
        )
      }}
    </Formik>
  )
}

ChannelEdit.Fragment = gql`
  fragment ChannelEditData on Channel {
    _id
    title
    summary
    avatar
    coverImage
    alwaysLive
    dayTimesLive {
      sun
      mon
      tue
      wed
      thu
      fri
      sat
    }
    showInfo {
      times
      phone
    }
    featuredVideo {
      _id
    }
    liveStreamVideo {
      _id
    }
    links {
      website
      facebook
      twitter
      gab
      minds
      subscribeStar
    }
  }
`
