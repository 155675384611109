import { Icon } from '@material-ui/core'
import React from 'react'
import gabIcon from '../assets/icons/gab.svg'
import mindsIcon from '../assets/icons/minds.svg'
import subscribeStarIcon from '../assets/icons/subscribestar.svg'

export enum ChannelLinkIconVariant {
  website = 'fas fa-link',
  facebook = 'fab fa-facebook-f',
  twitter = 'fab fa-twitter',
  gab = 'gab',
  minds = 'minds',
  subscribeStar = 'subscribeStar',
  telegram = 'fab fa-telegram',
}

export const ChannelLinkIcon = (props: { link: string; variant: ChannelLinkIconVariant }) => (
  <a href={props.link} target="_blank" style={{ margin: 5, cursor: 'pointer' }}>
    {props.variant === ChannelLinkIconVariant.gab ? (
      <img src={gabIcon} style={{ width: 20, height: 20 }} />
    ) : props.variant === ChannelLinkIconVariant.minds ? (
      <img src={mindsIcon} style={{ width: 20, height: 20 }} />
    ) : props.variant === ChannelLinkIconVariant.subscribeStar ? (
      <img src={subscribeStarIcon} style={{ width: 20, height: 20 }} />
    ) : (
      <Icon className={props.variant} style={{ fontSize: 20 }} />
    )}
  </a>
)
