import { List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { AdminUser, Roles } from '../generated/graphql'
import { AdminUserPermissionModal } from './AdminUserPermissionModal'
import { DeleteButton, DeleteButtonVariants } from './DeleteButton'
import { Surface } from './Surface'
import { TooltipIconButton } from './TooltipIconButton'

interface AdminUserListProps {
  adminUsers: AdminUser[]
  setAdminUserPermissions: (args: {
    id: string
    roles: Roles[]
    channels: string[]
  }) => Promise<any>
  suspendAdminUser: (id: string) => Promise<any>
}

export const AdminUserList = (props: AdminUserListProps) => {
  const [permissionModalUser, setPermissionModalUser] = useState<string>()
  return (
    <>
      <Surface title="Admin Users">
        <List>
          {props.adminUsers
            .filter(_id => _id !== null)
            .map(({ _id, email }) => (
              <ListItem key={_id} button onClick={() => setPermissionModalUser(_id)}>
                <ListItemText primary={email} />
                <ListItemSecondaryAction>
                  <TooltipIconButton
                    label="Edit"
                    placement="left"
                    onClick={() => setPermissionModalUser(_id)}
                  >
                    <EditIcon />
                  </TooltipIconButton>
                  <DeleteButton
                    variant={DeleteButtonVariants.iconButton}
                    onClick={async () => props.suspendAdminUser(_id)}
                    labelPlacement="left"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </Surface>
      <AdminUserPermissionModal
        open={!!permissionModalUser}
        close={() => setPermissionModalUser(undefined)}
        selectedUser={permissionModalUser}
        adminUsers={props.adminUsers}
        onSubmit={props.setAdminUserPermissions}
      />
    </>
  )
}

AdminUserList.Fragment = gql`
  fragment AdminUserListData on AdminUser {
    _id
    email
    ...AdminUserPermissionModalData
  }
  ${AdminUserPermissionModal.Fragment}
`
