import React from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Card } from '@material-ui/core'
import { Comment } from '../../generated/graphql'
import { CommentContent } from './CommentContent'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      minWidth: 275,
      marginBottom: theme.spacing(2),
    },
    username: {
      fontWeight: 700,
      marginRight: theme.spacing(1),
    },
    videoTitle: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 12,
    },
    button: {
      color: '#fff',
    },
  })
)

interface CommentCardProps extends Comment {
  noViewVideo?: boolean
}

export const CommentCard = (props: CommentCardProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Card className={classes.card}>
      <CommentContent {...props} />
    </Card>
  )
}

CommentCard.Fragment = CommentContent.Fragment
