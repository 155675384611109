import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  Select,
} from '@material-ui/core'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Formik } from 'formik'
import gql from 'graphql-tag'
import { find, pull } from 'lodash'
import React from 'react'
import { AdminUser, Roles, useGetAdminUserPermissionModalChannelsQuery } from '../generated/graphql'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    roleRow: {
      marginBottom: theme.spacing(2),
    },
    channelsRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    allButton: {
      marginLeft: theme.spacing(1),
    },
  })
)

export interface AdminUserPermissionModalProps {
  open: boolean
  close: () => void
  onSubmit: (values: { id: string; roles: Roles[]; channels: string[] }) => Promise<void>
  selectedUser?: string
  adminUsers: AdminUser[]
}

export const AdminUserPermissionModal = (props: AdminUserPermissionModalProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { data } = useGetAdminUserPermissionModalChannelsQuery()
  const channels = data && data.getAdminChannels ? data.getAdminChannels : []
  const adminUser = props.selectedUser
    ? find(props.adminUsers, ({ _id }) => _id === props.selectedUser)
    : undefined
  return (
    <Dialog onClose={props.close} open={props.open}>
      {adminUser ? (
        <>
          <DialogTitle>{adminUser.email}</DialogTitle>
          <Formik
            initialValues={{
              roles: adminUser.roles,
              channels: adminUser.collaboratingChannels
                .filter(_id => _id !== null)
                .map(({ _id }) => _id),
            }}
            validate={values => {
              const errors: any = {}
              if (!values.roles.length) {
                errors.roles = 'Required'
              }
              if (values.roles.indexOf(Roles.Uploader) !== -1 && !values.channels.length) {
                errors.channels = 'Required'
              }
              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await props.onSubmit({ id: props.selectedUser as string, ...values })
              setSubmitting(false)
              props.close()
            }}
          >
            {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
              const isSales = values ? values.roles.indexOf(Roles.Sales) !== -1 : undefined
              const isModerator = values ? values.roles.indexOf(Roles.Moderator) !== -1 : undefined
              const isAdmin = values ? values.roles.indexOf(Roles.Admin) !== -1 : undefined
              const isAnon = values ? values.roles.indexOf(Roles.Anon) !== -1 : undefined
              const isUploader = values ? values.roles.indexOf(Roles.Uploader) !== -1 : undefined
              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <div className={classes.roleRow}>
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            checked={isAdmin}
                            onClick={() =>
                              setFieldValue(
                                'roles',
                                pull([...values.roles, Roles.Admin], Roles.Uploader, Roles.Anon)
                              )
                            }
                          />
                        }
                        label="Admin"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            color="primary"
                            checked={isUploader}
                            onClick={() =>
                              setFieldValue(
                                'roles',
                                pull([...values.roles, Roles.Uploader], Roles.Admin, Roles.Anon)
                              )
                            }
                          />
                        }
                        label="Uploader"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={isAnon}
                            onClick={() =>
                              isAnon
                                ? setFieldValue('roles', pull([...values.roles], Roles.Anon))
                                : setFieldValue('roles', [...values.roles, Roles.Anon])
                            }
                          />
                        }
                        label="Block User"
                        labelPlacement="end"
                      />
                    </div>
                    <div className={classes.roleRow}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={isModerator}
                            onClick={() =>
                              isModerator
                                ? setFieldValue('roles', pull([...values.roles], Roles.Moderator))
                                : setFieldValue('roles', [...values.roles, Roles.Moderator])
                            }
                          />
                        }
                        label="Moderator"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={isSales}
                            onClick={() =>
                              isSales
                                ? setFieldValue('roles', pull([...values.roles], Roles.Sales))
                                : setFieldValue('roles', [...values.roles, Roles.Sales])
                            }
                          />
                        }
                        label="Sales"
                        labelPlacement="end"
                      />
                    </div>
                    <div className={classes.roleRow}>
                      <div className={classes.channelsRow}>
                        <FormControl style={{ width: '100%' }}>
                          <InputLabel>Collaborating Channels</InputLabel>
                          <Select
                            value={values.channels}
                            onChange={e => setFieldValue('channels', e.target.value)}
                            multiple
                          >
                            {channels.map(({ _id, title }) => (
                              <MenuItem key={_id} value={_id}>
                                {title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Button
                          color="default"
                          className={classes.allButton}
                          onClick={() => setFieldValue('channels', channels.map(({ _id }) => _id))}
                        >
                          All
                        </Button>
                      </div>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button type="submit" color="secondary" disabled={isSubmitting}>
                      Submit
                    </Button>
                    <Button onClick={props.close} color="primary" disabled={isSubmitting}>
                      Cancel
                    </Button>
                  </DialogActions>
                </form>
              )
            }}
          </Formik>
        </>
      ) : null}
    </Dialog>
  )
}

export const GET_ADMIN_PERMISSION_MODAL_CHANNELS = gql`
  query GetAdminUserPermissionModalChannels {
    getAdminChannels {
      _id
      title
    }
  }
`

AdminUserPermissionModal.Fragment = gql`
  fragment AdminUserPermissionModalData on AdminUser {
    _id
    email
    roles
    collaboratingChannels {
      _id
    }
  }
`
