import React from 'react'
import gql from 'graphql-tag'
import { Drawer, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Playlist, useGetPlaylistsForVideoPageQuery } from '../generated/graphql'

interface VideoAddToPlaylistProps {
  open: boolean
  close: () => void
  videoId: string
  channelId: string
  onSubmit: (id: string) => void
  createPlaylist: () => void
}

export const VideoAddToPlaylist = (props: VideoAddToPlaylistProps) => {
  const { data } = useGetPlaylistsForVideoPageQuery({ variables: { limit: 50 } })
  const playlists = (data && data.getAdminPlaylists ? data.getAdminPlaylists : []).filter(
    p => (p.channel && p.channel._id === props.channelId) || p.isGlobal
  ) as Playlist[]

  return (
    <Drawer anchor="bottom" open={props.open} onClose={props.close}>
      <List>
        {playlists.map(({ _id, title }) => (
          <ListItem button key={_id} onClick={() => props.onSubmit(_id)}>
            <ListItemText primary={title} />
          </ListItem>
        ))}
        <ListItem button onClick={props.createPlaylist}>
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText primary="Create new playlist" />
        </ListItem>
      </List>
    </Drawer>
  )
}

export const GET_PLAYLISTS_FOR_VIDEO_PAGE = gql`
  query GetPlaylistsForVideoPage($limit: Float) {
    getAdminPlaylists(limit: $limit) {
      _id
      title
      channel {
        _id
      }
      isGlobal
    }
  }
`
