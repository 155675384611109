import React, { useState } from 'react'
import { Formik } from 'formik'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { ChannelInput } from '../generated/graphql'
import { useError } from './ErrorModal'
import { TooltipFab } from './TooltipFab'
import { FileUpload, FileUploadVariants } from './FileUpload'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    uploadWrapper: {
      marginTop: theme.spacing(2),
    },
  })
)

interface ChannelAddProps {
  onSubmit: (channel: ChannelInput) => void
}

export const ChannelAdd = (props: ChannelAddProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  const { setErrorMessage } = useError()
  return (
    <>
      <TooltipFab tooltipLabel="Create Channel" aria-label="create channel" onClick={toggleOpen}>
        <AddIcon />
      </TooltipFab>
      <Formik
        initialValues={{ title: '', summary: '', avatar: '', coverImage: '' }}
        validate={values => {
          let errors: any = {}
          if (!values.title) {
            errors.title = 'Required'
          } else if (!/^[a-zA-Z0-9\s]{3,}$/.test(values.title)) {
            errors.title = 'Invalid title. Must use only letters, numbers and spaces.'
          }
          if (!values.summary) {
            errors.summary = 'Required'
          }
          if (!values.avatar) {
            errors.avatar = 'Required'
          }
          if (!values.coverImage) {
            errors.coverImage = 'Required'
          }
          return errors
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            await props.onSubmit(values)
          } catch (e) {
            setErrorMessage(e.message)
          }
          setSubmitting(false)
          setOpen(false)
          resetForm()
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          resetForm,
        }) => {
          const close = () => {
            resetForm()
            toggleOpen()
          }
          const resetAvatar = () => {
            setFieldTouched('avatar')
            setFieldValue('avatar', '')
          }
          const onAvatarComplete = (imageId: string) => {
            setFieldValue('avatar', imageId)
          }
          const resetCoverImage = () => {
            setFieldTouched('coverImage')
            setFieldValue('coverImage', '')
          }
          const onCoverImageComplete = (imageId: string) => {
            setFieldValue('coverImage', imageId)
          }
          return (
            <>
              <Dialog
                open={open}
                onClose={close}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth="sm"
              >
                <DialogTitle id="form-dialog-title">Create Channel</DialogTitle>
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <TextField
                      fullWidth
                      id="title"
                      label="Title"
                      name="title"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                    />
                    {touched.title && errors.title ? (
                      <FormHelperText error>{errors.title}</FormHelperText>
                    ) : null}
                    <TextField
                      fullWidth
                      id="summary"
                      label="Summary"
                      name="summary"
                      margin="dense"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.summary}
                    />
                    {touched.summary && errors.summary ? (
                      <FormHelperText error>{errors.summary}</FormHelperText>
                    ) : null}
                    <div className={classes.uploadWrapper}>
                      <FileUpload
                        variant={FileUploadVariants.image}
                        onStart={resetAvatar}
                        onComplete={onAvatarComplete}
                        title="Upload Avatar"
                      />
                    </div>
                    {touched.avatar && errors.avatar ? (
                      <FormHelperText error>{errors.avatar}</FormHelperText>
                    ) : null}
                    <div className={classes.uploadWrapper}>
                      <FileUpload
                        variant={FileUploadVariants.image}
                        onStart={resetCoverImage}
                        onComplete={onCoverImageComplete}
                        title="Upload Cover Image"
                      />
                    </div>
                    {touched.coverImage && errors.coverImage ? (
                      <FormHelperText error>{errors.coverImage}</FormHelperText>
                    ) : null}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={close} disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button type="submit" color="secondary" disabled={isSubmitting}>
                      Submit
                    </Button>
                  </DialogActions>
                </form>
              </Dialog>
            </>
          )
        }}
      </Formik>
    </>
  )
}
