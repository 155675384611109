import React from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: 100,
    },
    progress: {
      margin: theme.spacing(2),
    },
  })
)

export const PageLoader = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <div className={classes.wrapper}>
      <CircularProgress className={classes.progress} />
    </div>
  )
}
