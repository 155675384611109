import { Chip, Typography, useMediaQuery } from '@material-ui/core'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import gql from 'graphql-tag'
import moment from 'moment'
import React from 'react'
import { LinkifyText } from '../LinkifyText'
import { VideoViewProps } from './VideoView'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginTop: theme.spacing(3),
    },
    headerColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginTop: theme.spacing(3),
    },
    metaWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: theme.breakpoints.down('xs') ? 'flex-start' : 'flex-end',
      justifyContent: 'flex-start',
      minWidth: 220,
    },
    metaTop: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: theme.spacing(1),
    },
    summary: {
      marginTop: theme.spacing(2),
    },
    live: {
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.error.main,
      color: '#fff',
    },
  })
)

export const VideoInfo = (props: VideoViewProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const timestamp = props.publishedAt ? props.publishedAt : props.createdAt

  return (
    <div>
      <div className={isSmall ? classes.headerColumn : classes.headerRow}>
        <Typography variant="h4" component="h3" gutterBottom>
          {props.title}
        </Typography>
        <div className={classes.metaWrapper}>
          <div className={classes.metaTop}>
            {props.live ? <Chip label="LIVE" className={classes.live} /> : null}
            {moment(timestamp).isAfter(new Date()) ? (
              <Typography variant="subtitle2">
                Will be uploaded: {moment(timestamp).calendar()}
              </Typography>
            ) : (
              <Typography variant="subtitle2">Uploaded: {moment(timestamp).calendar()}</Typography>
            )}
          </div>
          {/* <Typography variant="subtitle2">{props.playCount.toLocaleString()} Views</Typography> */}
        </div>
      </div>
      <Typography variant="subtitle2" gutterBottom>
        {props.channel.title}
      </Typography>
      <Typography component="p" className={classes.summary}>
        <LinkifyText>{props.summary}</LinkifyText>
      </Typography>
    </div>
  )
}

VideoInfo.Fragment = gql`
  fragment VideoInfoData on Video {
    _id
    title
    summary
    live
    playCount
    publishedAt
    channel {
      _id
      title
    }
    createdAt
  }
`
