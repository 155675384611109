import { Formik } from 'formik'
import gql from 'graphql-tag'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
// import MomentUtils from '@date-io/moment'
import {
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Hidden,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import { uniqBy } from 'lodash'
// import {
//   MuiPickersUtilsProvider,
//   MaterialUiPickersDate,
//   KeyboardDateTimePicker,
// } from '@material-ui/pickers'
import { UpdateVideoInput, useGetVideoEditChannelsLazyQuery } from '../../generated/graphql'
import { useError } from '../ErrorModal'
import { FileUpload, FileUploadVariants } from '../FileUpload'
import { TagsAutocomplete } from '../TagsAutocomplete'
import { VideoViewProps } from './VideoView'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editDrawer: {
      margin: theme.spacing(2),
      width: 400,
    },
    editDrawerSmall: {
      padding: theme.spacing(2),
      width: '100%',
    },
    textField: {
      width: '100%',
    },
    formControl: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    submitButton: {
      width: '100%',
      marginTop: theme.spacing(2),
      color: '#fff',
    },
    checkRow: {
      marginTop: theme.spacing(1),
    },
    uploadWrapper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  })
)

interface VideoEditProps extends VideoViewProps {
  open: boolean
  close: () => void
}

export const VideoEdit = (props: VideoEditProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { setErrorMessage } = useError()
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const [loadGetAdminChannels, { data }] = useGetVideoEditChannelsLazyQuery()
  useEffect(() => {
    if (props.open) {
      loadGetAdminChannels()
    }
    return () => {}
  }, [props.open, loadGetAdminChannels])
  const [imageUploading, setImageUploading] = useState(false)
  const [videoUploading, setVideoUploading] = useState(false)
  const channels = data && data.getAdminChannels ? data.getAdminChannels : []
  const hasPublished = props.publishedAt ? moment(props.publishedAt).isBefore(new Date()) : true

  return (
    <Formik
      initialValues={{
        title: props.title,
        summary: props.summary,
        channelId: props.channel._id,
        posterUploadId: '',
        videoUploadId: '',
        useFeed: false,
        feed: '',
        live: props.live,
        tags:
          props.tags && props.tags.length
            ? props.tags.map(t => ({ _id: t._id, value: t._id, label: t.name }))
            : [],
        publishedAt: hasPublished ? undefined : props.publishedAt || undefined,
      }}
      validate={values => {
        const errors: any = {}
        if (!values.title) {
          errors.title = 'Required'
        }
        if (!values.summary) {
          errors.summary = 'Required'
        }
        if (!values.tags.length) {
          errors.tags = 'Required'
        }
        if (values.useFeed && !values.feed) {
          errors.feed = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        const tagIds = [] as string[]
        const newTags = [] as string[]
        values.tags.forEach(({ _id, value }) => {
          if (_id) return tagIds.push(_id)
          if (value) return newTags.push(value)
        })
        const video = {
          ...values,
          live: !!values.videoUploadId ? false : values.live,
        } as UpdateVideoInput
        delete (video as any).tags
        if (tagIds && tagIds.length) video.tagIds = tagIds
        if (newTags && newTags.length) video.newTags = newTags
        try {
          await props.editVideo(video)
        } catch (e) {
          setErrorMessage(e.message)
        }
        setSubmitting(false)
        props.close()
        resetForm()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        resetForm,
      }) => {
        const close = () => {
          props.close()
          resetForm()
        }
        const resetImage = () => {
          setFieldValue('posterUploadId', '')
          setImageUploading(true)
        }
        const onImageComplete = (imageId: string) => {
          setFieldValue('posterUploadId', imageId)
          setImageUploading(false)
        }
        const resetVideo = () => {
          setFieldValue('videoUploadId', '')
          setVideoUploading(true)
        }
        const onVideoComplete = (videoId: string) => {
          setFieldValue('videoUploadId', videoId)
          setVideoUploading(false)
        }
        return (
          // <MuiPickersUtilsProvider utils={MomentUtils}>
          <Drawer anchor={isSmall ? 'bottom' : 'right'} open={props.open} onClose={close}>
            <div className={isSmall ? classes.editDrawerSmall : classes.editDrawer}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h4" component="h4" gutterBottom>
                  Edit Video
                </Typography>
                <Hidden smUp>
                  <Close onClick={close} />
                </Hidden>
              </div>
              <form onSubmit={handleSubmit}>
                <TextField
                  id="title"
                  label="Title"
                  name="title"
                  className={classes.textField}
                  value={values.title}
                  onChange={handleChange}
                  margin="normal"
                  error={!!(touched.title && errors.title)}
                />
                {touched.title && errors.title ? (
                  <FormHelperText error>{errors.title}</FormHelperText>
                ) : null}
                <TextField
                  id="summary"
                  label="Summary"
                  name="summary"
                  className={classes.textField}
                  value={values.summary}
                  onChange={handleChange}
                  multiline
                  maxRows="4"
                  margin="normal"
                  error={!!(touched.summary && errors.summary)}
                />
                {touched.summary && errors.summary ? (
                  <FormHelperText error>{errors.summary}</FormHelperText>
                ) : null}
                {props.live ? (
                  <FormGroup>
                    <FormControlLabel
                      label="Use House Feed"
                      control={
                        <Checkbox checked={values.useFeed} onChange={handleChange} name="useFeed" />
                      }
                    />
                    {values.useFeed ? (
                      <FormControl className={classes.formControl}>
                        <InputLabel>Feed</InputLabel>
                        <Select value={values.feed} onChange={handleChange} name="feed">
                          <MenuItem value="alex">The Alex Jones Show</MenuItem>
                          <MenuItem value="david">The David Knight Show</MenuItem>
                          <MenuItem value="owen">War Room With Owen Shroyer</MenuItem>
                          <MenuItem value="house">House Feed</MenuItem>
                        </Select>
                        {touched.feed && errors.feed ? (
                          <FormHelperText error>{errors.feed}</FormHelperText>
                        ) : null}
                      </FormControl>
                    ) : null}
                  </FormGroup>
                ) : null}
                <div className={classes.uploadWrapper}>
                  <FileUpload
                    variant={FileUploadVariants.video}
                    defaultVideo={props.streamUrl}
                    onStart={resetVideo}
                    onComplete={onVideoComplete}
                  />
                </div>
                <div className={classes.uploadWrapper}>
                  <FileUpload
                    variant={FileUploadVariants.image}
                    defaultImage={props.largeImage}
                    onStart={resetImage}
                    onComplete={onImageComplete}
                  />
                </div>
                {/* {hasPublished ? null : (
                    <KeyboardDateTimePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Publish Date & Time"
                      format="MM/D/YYYY hh:mm"
                      minDate={moment()
                        .tz('America/Chicago')
                        .toDate()}
                      value={values.publishedAt}
                      onChange={(date: MaterialUiPickersDate) => {
                        if (date) {
                          const time = moment(date)
                          setFieldValue('publishedAt', time.tz('America/Chicago').toDate())
                        }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date and time',
                      }}
                    />
                  )} */}
                <TagsAutocomplete
                  value={values.tags}
                  onChange={(values: Array<{ label: string; value: string }>) =>
                    setFieldValue('tags', uniqBy(values, ({ value }) => value))
                  }
                />
                {touched.tags && errors.tags ? (
                  <FormHelperText error>{errors.tags}</FormHelperText>
                ) : null}
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="channelId">Channel</InputLabel>
                  <Select
                    value={values.channelId}
                    onChange={e => setFieldValue('channelId', e.target.value)}
                    inputProps={{ name: 'channelId', id: 'channelId' }}
                  >
                    {channels.map(channel => (
                      <MenuItem key={channel._id} value={channel._id}>
                        {channel.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  type="submit"
                  disabled={isSubmitting || imageUploading || videoUploading}
                  variant="contained"
                  color="secondary"
                  className={classes.submitButton}
                >
                  {isSubmitting ? <CircularProgress size="24px" color="secondary" /> : 'Submit'}
                </Button>
              </form>
            </div>
          </Drawer>
          // </MuiPickersUtilsProvider>
        )
      }}
    </Formik>
  )
}

export const GET_VIDEO_EDIT_CHANNELS = gql`
  query GetVideoEditChannels {
    getAdminChannels {
      _id
      title
    }
  }
`

VideoEdit.Fragment = gql`
  fragment VideoEditData on Video {
    _id
    title
    summary
    channel {
      _id
    }
    publishedAt
    streamUrl
    largeImage
  }
`
