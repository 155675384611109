import React from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  TableCell,
  TableHead as MUITableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from '@material-ui/core'
import { HeadRow } from './Table'
import { Data } from './'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
)

type Order = 'asc' | 'desc'

interface TableProps<D> {
  headRows: HeadRow<D>[]
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  order: Order
  orderBy: string
  rowCount: number
}

export const TableHead = (props: TableProps<Data>) => {
  const { headRows, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const theme = useTheme()
  const classes = useStyles(theme)
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <MUITableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all' }}
          />
        </TableCell>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.alignRight ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
              {orderBy === row.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MUITableHead>
  )
}
