import { AppBar, Paper, Tab, Tabs } from '@material-ui/core'
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import FlagIcon from '@material-ui/icons/Flag'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import gql from 'graphql-tag'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { CommentReportModal } from '../components/Comments/CommentReportModal'
import { CommentTab } from '../components/Comments/CommentTab'
import { PageLayout } from '../components/PageLayout'
import { PageSearchInput } from '../components/PageSearchInput'
import {
  useGetAllCommentsAmountSinceQuery,
  useGetAllCommentsLazyQuery,
  useGetApprovedCommentsAmountSinceQuery,
  useGetApprovedCommentsLazyQuery,
  useGetRejectedCommentsAmountSinceQuery,
  useGetRejectedCommentsLazyQuery,
  useGetReportedCommentsAmountSinceQuery,
  useGetReportedCommentsLazyQuery,
  useGetUnfilteredCommentsAmountSinceQuery,
  useGetUnfilteredCommentsLazyQuery,
} from '../generated/graphql'
import { ThemeModes } from '../lib/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.type === ThemeModes.light ? theme.palette.grey['100'] : '#333',
      paddingBottom: theme.spacing(3),
    },
  })
)

export const Comments = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [value, setValue] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [reportModal, setReportModal] = useState({ open: false, id: '' })
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue)
  const handleSearchChange = debounce(text => setSearchValue(text), 600)
  return (
    <PageLayout
      loading={false}
      noViewSwitch
      customButton={<PageSearchInput onChange={handleSearchChange} />}
    >
      <Paper className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            scrollButtons="on"
            centered
          >
            <Tab label="New" wrapped icon={<ChatBubbleIcon />} />
            <Tab label="Reported" wrapped icon={<FlagIcon />} />
            <Tab label="Approved" wrapped icon={<CheckIcon />} />
            <Tab label="Rejected" wrapped icon={<CloseIcon />} />
            <Tab label="All" wrapped icon={<QuestionAnswerIcon />} />
          </Tabs>
        </AppBar>
        <CommentTab
          value={value}
          index={0}
          query={useGetUnfilteredCommentsLazyQuery}
          queryName="getUnfilteredComments"
          openReportModal={(id: string) => setReportModal({ open: true, id })}
          search={searchValue}
          getMoreQuery={useGetUnfilteredCommentsAmountSinceQuery}
          getMoreQueryName="getUnfilteredCommentsAmountSince"
        />
        <CommentTab
          value={value}
          index={1}
          query={useGetReportedCommentsLazyQuery}
          queryName="getReportedComments"
          openReportModal={(id: string) => setReportModal({ open: true, id })}
          search={searchValue}
          getMoreQuery={useGetReportedCommentsAmountSinceQuery}
          getMoreQueryName="getReportedCommentsAmountSince"
        />
        <CommentTab
          value={value}
          index={2}
          query={useGetApprovedCommentsLazyQuery}
          queryName="getApprovedComments"
          search={searchValue}
          getMoreQuery={useGetApprovedCommentsAmountSinceQuery}
          getMoreQueryName="getApprovedCommentsAmountSince"
        />
        <CommentTab
          value={value}
          index={3}
          query={useGetRejectedCommentsLazyQuery}
          queryName="getRejectedComments"
          search={searchValue}
          getMoreQuery={useGetRejectedCommentsAmountSinceQuery}
          getMoreQueryName="getRejectedCommentsAmountSince"
        />
        <CommentTab
          value={value}
          index={4}
          query={useGetAllCommentsLazyQuery}
          queryName="getAllComments"
          openReportModal={(id: string) => setReportModal({ open: true, id })}
          search={searchValue}
          getMoreQuery={useGetAllCommentsAmountSinceQuery}
          getMoreQueryName="getAllCommentsAmountSince"
        />
      </Paper>
      <CommentReportModal
        open={reportModal.open}
        close={() => setReportModal({ open: false, id: '' })}
        commentId={reportModal.id}
      />
    </PageLayout>
  )
}

export const GET_ALL_COMMENTS = gql`
  query GetAllComments($limit: Float, $offset: Float, $query: String) {
    commentPageLimit @client @export(as: "limit")
    getAllComments(limit: $limit, offset: $offset, query: $query) {
      ...CommentTabData
    }
  }
  ${CommentTab.Fragment}
`

export const GET_REPORTED_COMMENTS = gql`
  query GetReportedComments($limit: Float, $offset: Float, $query: String) {
    commentPageLimit @client @export(as: "limit")
    getReportedComments(limit: $limit, offset: $offset, query: $query) {
      ...CommentTabData
      updatedAt
    }
  }
  ${CommentTab.Fragment}
`

export const GET_APPROVED_COMMENTS = gql`
  query GetApprovedComments($limit: Float, $offset: Float, $query: String) {
    commentPageLimit @client @export(as: "limit")
    getApprovedComments(limit: $limit, offset: $offset, query: $query) {
      ...CommentTabData
    }
  }
  ${CommentTab.Fragment}
`

export const GET_REJECTED_COMMENTS = gql`
  query GetRejectedComments($limit: Float, $offset: Float, $query: String) {
    commentPageLimit @client @export(as: "limit")
    getRejectedComments(limit: $limit, offset: $offset, query: $query) {
      bannedFromComment
      duplicate
      ...CommentTabData
    }
  }
  ${CommentTab.Fragment}
`

export const GET_UNFILTERED_COMMENTS = gql`
  query GetUnfilteredComments($limit: Float, $offset: Float, $query: String) {
    commentPageLimit @client @export(as: "limit")
    getUnfilteredComments(limit: $limit, offset: $offset, query: $query) {
      ...CommentTabData
    }
  }
  ${CommentTab.Fragment}
`

export const GET_ALL_COMMENTS_AMOUNT_SINCE = gql`
  query GetAllCommentsAmountSince($since: DateTime!) {
    getAllCommentsAmountSince(since: $since)
  }
`

export const GET_REPORTED_COMMENTS_AMOUNT_SINCE = gql`
  query GetReportedCommentsAmountSince($since: DateTime!) {
    getReportedCommentsAmountSince(since: $since)
  }
`

export const GET_APPROVED_COMMENTS_AMOUNT_SINCE = gql`
  query GetApprovedCommentsAmountSince($since: DateTime!) {
    getApprovedCommentsAmountSince(since: $since)
  }
`

export const GET_REJECTED_COMMENTS_AMOUNT_SINCE = gql`
  query GetRejectedCommentsAmountSince($since: DateTime!) {
    getRejectedCommentsAmountSince(since: $since)
  }
`

export const GET_UNFILTERED_COMMENTS_AMOUNT_SINCE = gql`
  query GetUnfilteredCommentsAmountSince($since: DateTime!) {
    getUnfilteredCommentsAmountSince(since: $since)
  }
`

export const APPROVE_COMMENT = gql`
  mutation ApproveComment($id: String!) {
    approveComment(id: $id) {
      ...CommentTabData
    }
  }
  ${CommentTab.Fragment}
`

export const REJECT_COMMENT = gql`
  mutation RejectComment($id: String!) {
    rejectComment(id: $id) {
      ...CommentTabData
    }
  }
  ${CommentTab.Fragment}
`
