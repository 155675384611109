import { Button, Snackbar, TextField } from '@material-ui/core'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import React, { useState } from 'react'
import { CopyButton } from './CopyButton'
import { FileUpload, FileUploadVariants } from './FileUpload'
import { TabPanel, TabPanelProps } from './TabPanel'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      marginTop: theme.spacing(4),
      width: '100%',
      color: '#fff',
    },
    snackbar: {
      width: '100%',
      textAlign: 'center',
    },
  })
)

export const ImageUpload = (props: TabPanelProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [imageId, setImageId] = useState('') 
  const imageUrl = `${process.env.REACT_APP_IMAGES_INFOWARSMEDIA_URL}/images/${imageId}`
  const handleSnackbarClose = () => setSnackbarOpen(false)
  const copyImageUrl = () =>
    navigator.clipboard.writeText(imageUrl).then(() => {
      setSnackbarOpen(true)
      setTimeout(handleSnackbarClose, 3000)
    })
  const resetForm = () => {
    setSubmitted(false)
    setImageId('')
  }

  return (
    <TabPanel value={props.value} index={props.index}>
      {!submitted ? (
        <div>
          <FileUpload
            variant={FileUploadVariants.image}
            onStart={resetForm}
            onComplete={setImageId}
          />
          <Button
            id="submit"
            variant="contained"
            color="secondary"
            onClick={() => setSubmitted(true)}
            disabled={!imageId}
            className={classes.submitButton}
            size="large"
          >
            Submit
          </Button>
        </div>
      ) : (
        <div>
          <TextField
            label="Image Url"
            type="text"
            value={imageUrl}
            fullWidth
            variant="outlined"
            contentEditable={false}
            InputProps={{
              endAdornment: <CopyButton onClick={copyImageUrl} />,
            }}
          />
          <Button
            id="reset"
            variant="contained"
            color="primary"
            onClick={resetForm}
            className={classes.submitButton}
            size="large"
          >
            Upload Another
          </Button>
        </div>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message="Image URL copied!"
      />
    </TabPanel>
  )
}
