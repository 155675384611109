import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import HomeIcon from '@material-ui/icons/Home'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay'
import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
// import ScheduleIcon from '@material-ui/icons/Schedule'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import CommentIcon from '@material-ui/icons/Comment'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import { Channel } from '../pages/Channel'
import { Channels } from '../pages/Channels'
import { ForgotPassword } from '../pages/ForgotPassword'
import { ForgotPasswordToken } from '../pages/ForgotPasswordToken'
import { Home } from '../pages/Home'
import { Login } from '../pages/Login'
import { Playlist } from '../pages/Playlist'
import { Playlists } from '../pages/Playlists'
import { Upload } from '../pages/Upload'
import { Video } from '../pages/Video'
import { MainLayout } from './MainLayout'
import { UserContext } from './UserProvider'
// import { Schedule } from '../pages/Schedule'
import { AdminActions } from '../pages/AdminActions'
import { AdminUsers } from '../pages/AdminUsers'
import { Blocked } from '../pages/Blocked'
import { Comments } from '../pages/Comments'
import { Sales } from '../pages/Sales'
import { Search } from '../pages/Search'
import { Settings } from '../pages/Settings'
import { User } from '../pages/User'
import { Users } from '../pages/Users'

type GetBreadcrumb = (args: {
  useLast: boolean
  lastName: string
  currentPath: string
}) => {
  name?: string
  link?: string
  none?: boolean
  useLast: boolean
  lastName: string
  currentPath: string
}

type RouteType = {
  path: string
  component: () => JSX.Element
  admin?: boolean
  superAdmin?: boolean
  uploader?: boolean
  sales?: boolean
  moderator?: boolean
  drawer?: {
    name: string
    icon: JSX.Element
    route: string
  }
  getBreadcrumb?: GetBreadcrumb
}

export enum BreadcrumbPage {
  home = '',
  playlists = 'playlists',
  playlist = 'playlist',
  adminUsers = 'admin-users',
  users = 'users',
  user = 'user',
  comments = 'comments',
  upload = 'upload',
  // schedule = 'schedule',
  sales = 'sales',
  channels = 'channels',
  channel = 'channel',
  search = 'search',
  settings = 'settings',
  videos = 'videos',
  adminActions = 'admin-actions',
  blocked = 'blocked',
}

const pageToRoute = (page: BreadcrumbPage) => `/${page}`
const pageToPath = (page: BreadcrumbPage) => `${page}/`

export const routes = {
  [BreadcrumbPage.home]: {
    path: pageToRoute(BreadcrumbPage.home),
    component: Home,
    admin: true,
    drawer: {
      name: 'Home',
      icon: <HomeIcon />,
      route: pageToRoute(BreadcrumbPage.home),
    },
    getBreadcrumb: (args => {
      const currentPath = args.currentPath + pageToRoute(BreadcrumbPage.home)
      return { ...args, name: 'Home', link: currentPath, currentPath }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.channels]: {
    path: pageToRoute(BreadcrumbPage.channels),
    component: Channels,
    uploader: true,
    drawer: {
      name: 'Channels',
      icon: <OndemandVideoIcon />,
      route: pageToRoute(BreadcrumbPage.channels),
    },
    getBreadcrumb: (args => {
      const useLast = true
      const lastName = 'Channel'
      const currentPath = args.currentPath + pageToPath(BreadcrumbPage.channels)
      return { ...args, name: 'Channels', link: currentPath, useLast, lastName, currentPath }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.channel]: {
    path: `${pageToRoute(BreadcrumbPage.channels)}/:channelId`,
    component: Channel,
  } as RouteType,
  [BreadcrumbPage.videos]: {
    path: `${pageToRoute(BreadcrumbPage.channels)}/:channelId${pageToRoute(
      BreadcrumbPage.videos
    )}/:videoId`,
    component: Video,
    getBreadcrumb: (args => {
      const useLast = true
      const lastName = 'Video'
      return { ...args, useLast, lastName, none: true }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.playlists]: {
    path: pageToRoute(BreadcrumbPage.playlists),
    component: Playlists,
    uploader: true,
    drawer: {
      name: 'Playlists',
      icon: <PlaylistPlayIcon />,
      route: pageToRoute(BreadcrumbPage.playlists),
    },
    getBreadcrumb: (args => {
      const useLast = true
      const lastName = 'Playlist'
      const currentPath = args.currentPath + pageToPath(BreadcrumbPage.playlists)
      return { ...args, name: 'Playlists', link: currentPath, useLast, lastName, currentPath }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.playlist]: {
    path: `${pageToRoute(BreadcrumbPage.playlists)}/:playlistId`,
    component: Playlist,
  } as RouteType,
  // [BreadcrumbPage.schedule]: {
  //   path: pageToRoute(BreadcrumbPage.schedule),
  //   component: Schedule,
  //   uploader: true,
  //   drawer: {
  //     name: 'Schedule',
  //     icon: <ScheduleIcon />,
  //     route: pageToRoute(BreadcrumbPage.schedule),
  //   },
  //   getBreadcrumb: (args => {
  //     const currentPath = args.currentPath + pageToPath(BreadcrumbPage.schedule)
  //     return { ...args, name: 'Schedule', link: currentPath }
  //   }) as GetBreadcrumb,
  // } as RouteType,
  [BreadcrumbPage.sales]: {
    path: pageToRoute(BreadcrumbPage.sales),
    component: Sales,
    sales: true,
    drawer: {
      name: 'Sales',
      icon: <MonetizationOnIcon />,
      route: pageToRoute(BreadcrumbPage.sales),
    },
    getBreadcrumb: (args => {
      const currentPath = args.currentPath + pageToPath(BreadcrumbPage.sales)
      return { ...args, name: 'Sales', link: currentPath }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.upload]: {
    path: pageToRoute(BreadcrumbPage.upload),
    component: Upload,
    uploader: true,
    drawer: {
      name: 'Upload',
      icon: <CloudUploadIcon />,
      route: pageToRoute(BreadcrumbPage.upload),
    },
    getBreadcrumb: (args => {
      const currentPath = args.currentPath + pageToPath(BreadcrumbPage.upload)
      return { ...args, name: 'Upload', link: currentPath, currentPath }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.comments]: {
    path: pageToRoute(BreadcrumbPage.comments),
    component: Comments,
    moderator: true,
    drawer: {
      name: 'Comments',
      icon: <CommentIcon />,
      route: pageToRoute(BreadcrumbPage.comments),
    },
    getBreadcrumb: (args => {
      const currentPath = args.currentPath + pageToPath(BreadcrumbPage.comments)
      return { ...args, name: 'Comments', link: currentPath, currentPath }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.users]: {
    path: pageToRoute(BreadcrumbPage.users),
    component: Users,
    moderator: true,
    drawer: {
      name: 'Users',
      icon: <PeopleIcon />,
      route: pageToRoute(BreadcrumbPage.users),
    },
    getBreadcrumb: (args => {
      const useLast = true
      const lastName = 'User'
      const currentPath = args.currentPath + pageToPath(BreadcrumbPage.users)
      return { ...args, name: 'Users', link: currentPath, useLast, lastName }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.user]: {
    path: `${pageToRoute(BreadcrumbPage.users)}/:username`,
    component: User,
    moderator: true,
  } as RouteType,
  [BreadcrumbPage.adminUsers]: {
    path: pageToRoute(BreadcrumbPage.adminUsers),
    component: AdminUsers,
    superAdmin: true,
    drawer: {
      name: 'Admin Users',
      icon: <AssignmentIndIcon />,
      route: pageToRoute(BreadcrumbPage.adminUsers),
    },
    getBreadcrumb: (args => {
      const currentPath = args.currentPath + pageToPath(BreadcrumbPage.adminUsers)
      return { ...args, name: 'Admin Users', link: currentPath, currentPath }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.adminActions]: {
    path: pageToRoute(BreadcrumbPage.adminActions),
    component: AdminActions,
    superAdmin: true,
    drawer: {
      name: 'Admin Actions',
      icon: <FormatListBulletedIcon />,
      route: pageToRoute(BreadcrumbPage.adminActions),
    },
    getBreadcrumb: (args => {
      const currentPath = args.currentPath + pageToPath(BreadcrumbPage.adminActions)
      return { ...args, name: 'Admin Actions', link: currentPath, currentPath }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.settings]: {
    path: pageToRoute(BreadcrumbPage.settings),
    component: Settings,
    drawer: {
      name: 'Settings',
      icon: <SettingsIcon />,
      route: pageToRoute(BreadcrumbPage.settings),
    },
    getBreadcrumb: (args => {
      const currentPath = args.currentPath + pageToPath(BreadcrumbPage.settings)
      return { ...args, name: 'Settings', link: currentPath, currentPath }
    }) as GetBreadcrumb,
  } as RouteType,
  [BreadcrumbPage.search]: {
    path: pageToRoute(BreadcrumbPage.search),
    component: Search,
    getBreadcrumb: (args => {
      const currentPath = args.currentPath + pageToPath(BreadcrumbPage.search)
      return { ...args, name: 'Search', link: currentPath, currentPath }
    }) as GetBreadcrumb,
  } as RouteType,
}

export const routeMap = [
  routes[BreadcrumbPage.home],
  routes[BreadcrumbPage.channels],
  routes[BreadcrumbPage.channel],
  routes[BreadcrumbPage.videos],
  routes[BreadcrumbPage.playlists],
  routes[BreadcrumbPage.playlist],
  // routes[BreadcrumbPage.schedule],
  routes[BreadcrumbPage.sales],
  routes[BreadcrumbPage.upload],
  routes[BreadcrumbPage.comments],
  routes[BreadcrumbPage.users],
  routes[BreadcrumbPage.user],
  routes[BreadcrumbPage.adminUsers],
  routes[BreadcrumbPage.adminActions],
  routes[BreadcrumbPage.settings],
  routes[BreadcrumbPage.search],
]

export const Router = () => {
  const user = React.useContext(UserContext)
  let redirect = undefined
  if (user && user.isAnon) {
    redirect = () => <Redirect to={pageToRoute(BreadcrumbPage.blocked)} />
  } else {
    if (user.isSuperAdmin || user.isAdmin) {
      redirect = () => <Redirect to={pageToRoute(BreadcrumbPage.home)} />
    } else if (user.isUploader) {
      redirect = () => <Redirect to={pageToRoute(BreadcrumbPage.channels)} />
    } else if (user.isModerator) {
      redirect = () => <Redirect to={pageToRoute(BreadcrumbPage.comments)} />
    } else if (user.isSales) {
      redirect = () => <Redirect to={pageToRoute(BreadcrumbPage.sales)} />
    }
  }
  return (
    <BrowserRouter>
      {(user.loggedIn) ? (
        <MainLayout>
          <Switch>
            {routeMap.map(({ superAdmin, admin, uploader, sales, moderator, path, component }) => {
              if (user.isAnon) {
                // if user is set as anon, that means it is blocked
                path = pageToRoute(BreadcrumbPage.blocked)
                component = Blocked
              }
              const route = <Route key={path} path={path} exact component={component} />
              return superAdmin
                ? user.isSuperAdmin
                  ? route
                  : null
                : admin
                ? user.isSuperAdmin || user.isAdmin
                  ? route
                  : null
                : uploader
                ? user.isSuperAdmin || user.isAdmin || user.isUploader
                  ? route
                  : null
                : sales
                ? user.isSuperAdmin || user.isSales
                  ? route
                  : null
                : moderator
                ? user.isSuperAdmin || user.isModerator
                  ? route
                  : null
                : route
            })}
            <Route path="*" render={redirect} />
          </Switch>
        </MainLayout>
      ) : (
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/forgot-password/:token" exact component={ForgotPasswordToken} />
          <Route path="*" render={() => <Redirect to="/login" />} />
        </Switch>
      )}
    </BrowserRouter>
  )
}
