import React, { useContext } from 'react'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import { Pages } from '../../types'
import EditIcon from '@material-ui/icons/Edit'
import { Playlist } from '../../generated/graphql'
import { UserContext } from '../UserProvider'
import { DeleteButton, DeleteButtonVariants } from '../DeleteButton'
import { Surface } from '../Surface'
import { VideoGrid } from '../VideoGrid'
import { VideoTable } from '../VideoTable'
import { useViewType } from '../SwitchViewButton'
import { TooltipIconButton } from '../TooltipIconButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3),
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    button: {
      marginLeft: theme.spacing(2),
    },
    channelPagePlaylist: {
      // marginBottom: theme.spacing(4),
    },
  })
)

interface PlaylistListItem extends Playlist {
  variant?: Pages
  removePlaylist?: () => Promise<any>
  toggleEdit?: () => void
  loadMoreVideos?: () => void
  hasMoreVideos?: boolean
}

export const PlaylistListItem = (props: PlaylistListItem) => {
  const user = useContext(UserContext)
  const theme = useTheme()
  const classes = useStyles(theme)
  const { isTable } = useViewType()
  const isChannelPage = props.variant === Pages.channel
  const isPlaylistPage = props.variant === Pages.playlist
  const content = isTable ? (
    <VideoTable
      videos={props.videos}
      label="Playlist Videos"
      loadMore={props.loadMoreVideos}
      totalVideos={props.totalVideos}
    />
  ) : isPlaylistPage ? (
    <VideoGrid
      videos={props.videos}
      loadMore={props.loadMoreVideos}
      hasMoreVideos={props.hasMoreVideos}
    />
  ) : (
    <VideoGrid videos={props.videos.slice(0, 4)} />
  )
  return isChannelPage ? (
    <div className={classes.channelPagePlaylist}>
      <div className={classes.header}>
        <Link to={`/playlists/${props._id}`}>
          <Typography
            variant={isChannelPage ? 'h5' : 'h4'}
            component="h3"
            className={classes.title}
          >
            {props.title}
          </Typography>
        </Link>
        <Link to={`/playlists/${props._id}`}>
          <Button className={classes.button}>View More</Button>
        </Link>
      </div>
      {content}
    </div>
  ) : (
    <Surface
      title={props.title}
      titleLink={!isPlaylistPage ? `/playlists/${props._id}` : undefined}
      subtitle={!props.isGlobal && props.channel ? props.channel.title : 'Global'}
      subtitleLink={props.channel ? `/channels/${props.channel._id}` : undefined}
      actions={
        user.isSuperAdmin ||
        user.isAdmin ||
        user.isChannelCollaborator(props.channel ? props.channel._id : '') ? (
          isPlaylistPage ? (
            <div>
              <TooltipIconButton label="Edit" onClick={props.toggleEdit}>
                <EditIcon />
              </TooltipIconButton>
              {user.isSuperAdmin ||
              user.isAdmin ||
              user.isChannelCollaborator(props.channel ? props.channel._id : '') ? (
                <DeleteButton
                  variant={DeleteButtonVariants.iconButton}
                  onClick={props.removePlaylist!}
                />
              ) : null}
            </div>
          ) : (
            undefined
          )
        ) : (
          undefined
        )
      }
    >
      {content}
    </Surface>
  )
}

PlaylistListItem.Fragment = gql`
  fragment PlaylistListItemData on Playlist {
    _id
    title
    summary
    isGlobal
    channel {
      _id
      title
    }
    videos(limit: $playlistVideoLimit, offset: $playlistVideoOffset) {
      ...VideoTableData
      ...VideoCardData
    }
  }
  ${VideoTable.Fragment}
  ${VideoGrid.Fragment}
`
