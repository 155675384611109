import React, { useState } from 'react'
import { Formik } from 'formik'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from '@material-ui/core'
import { PlaylistInput } from '../generated/graphql'
import { VideoPicker } from './VideoPicker'
import { useError } from './ErrorModal'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectVideosButton: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
  })
)

interface ChannelPlaylistAddProps {
  videoIds: string[]
  selectVideos?: boolean
  channelId: string
  onSubmit: (playlist: PlaylistInput) => Promise<any>
  open: boolean
  setOpen: (open: boolean) => void
}

export const ChannelPlaylistAdd = (props: ChannelPlaylistAddProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const [videoPickerOpen, setVideoPickerOpen] = useState(false)
  const { setErrorMessage } = useError()

  return (
    <Formik
      initialValues={{ title: '', summary: '', channel: props.channelId, videoIds: [] as string[] }}
      validate={values => {
        let errors: any = {}
        if (!values.title) {
          errors.title = 'Required'
        }
        if (props.selectVideos && !values.videoIds.length) {
          errors.videoIds = 'Required'
        }
        return errors
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          await props.onSubmit({
            ...values,
            videoIds: props.selectVideos ? values.videoIds : props.videoIds,
          })
        } catch (e) {
          setErrorMessage(e.message)
        }
        setSubmitting(false)
        props.setOpen(false)
        resetForm()
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
      }) => {
        const close = () => {
          resetForm()
          props.setOpen(false)
        }
        return (
          <>
            <Dialog
              open={props.open}
              onClose={close}
              aria-labelledby="form-dialog-title"
              fullWidth
              maxWidth="sm"
            >
              <DialogTitle id="form-dialog-title">Create Playlist</DialogTitle>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextField
                    fullWidth
                    id="title"
                    label="Title"
                    name="title"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                  />
                  {touched.title && errors.title ? (
                    <FormHelperText error>{errors.title}</FormHelperText>
                  ) : null}
                  <TextField
                    fullWidth
                    id="summary"
                    label="Summary (Optional)"
                    name="summary"
                    margin="dense"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.summary}
                  />
                  {touched.summary && errors.summary ? (
                    <FormHelperText error>{errors.summary}</FormHelperText>
                  ) : null}
                  {props.selectVideos ? (
                    <>
                      <Button
                        variant="contained"
                        className={classes.selectVideosButton}
                        disabled={!values.channel}
                        onClick={() => setVideoPickerOpen(true)}
                      >
                        Select Videos {values.videoIds.length ? `(${values.videoIds.length})` : ''}
                      </Button>
                      {touched.videoIds && errors.videoIds ? (
                        <FormHelperText error>{errors.videoIds}</FormHelperText>
                      ) : null}
                    </>
                  ) : null}
                </DialogContent>
                <DialogActions>
                  <Button onClick={close} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button type="submit" color="secondary" disabled={isSubmitting}>
                    Submit
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            <VideoPicker
              open={videoPickerOpen}
              close={() => setVideoPickerOpen(false)}
              channelId={values.channel}
              selectedItems={values.videoIds}
              onSelect={(_id: string) => {
                if (values.videoIds.indexOf(_id) === -1) {
                  setFieldValue('videoIds', values.videoIds.concat([_id]))
                } else {
                  setFieldValue('videoIds', values.videoIds.filter(id => id !== _id))
                }
              }}
            />
          </>
        )
      }}
    </Formik>
  )
}
