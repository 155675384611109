import React from 'react'
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { Channel } from '../generated/graphql'
import { GridVariants } from '../types'
import { ChannelCard } from './ChannelCard'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
)

interface ChannelGridProps {
  channels: Channel[]
  variant?: GridVariants
  selectedItems?: string[]
  onSelect?: (id: string) => void
}

export const ChannelGrid = (props: ChannelGridProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {props.channels && props.channels.length
          ? props.channels.map(channel => (
              <Grid key={channel._id} item xs={6} md={4} lg={3}>
                <ChannelCard
                  {...channel}
                  variant={props.variant}
                  selectedItems={props.selectedItems}
                  onSelect={props.onSelect}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </div>
  )
}

ChannelGrid.Fragment = ChannelCard.Fragment
