import React from 'react'
import { Theme, useTheme, makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Toolbar, AppBar } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import AddVideoIcon from '@material-ui/icons/LibraryAdd'
import AddChannelIcon from '@material-ui/icons/AddToQueue'
import { TooltipIconButton } from './TooltipIconButton'

const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButtonStart: {
    marginRight: theme.spacing(2),
  },
  menuButtonEnd: {
    marginLeft: theme.spacing(2),
  },
  toolbar: {
    marginBottom: theme.spacing(2),
  },
}))

interface HomeToolbarProps {
  onAddVideos: () => void
  onAddNavigationChannel: () => void
  loading?: boolean
  onCancel: () => void
  save: () => void
}

export const HomeToolbar = (props: HomeToolbarProps) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <AppBar position="static" className={classes.toolbar} color="secondary">
      <Toolbar>
        <TooltipIconButton
          label="Add Video"
          className={classes.menuButtonStart}
          onClick={props.onAddVideos}
        >
          <AddVideoIcon />
        </TooltipIconButton>
        <TooltipIconButton
          label="Add Navigation Channel"
          className={classes.menuButtonStart}
          onClick={props.onAddNavigationChannel}
        >
          <AddChannelIcon />
        </TooltipIconButton>
        <div className={classes.grow} />
        <TooltipIconButton
          label="Cancel"
          className={classes.menuButtonEnd}
          onClick={props.onCancel}
        >
          <CancelIcon />
        </TooltipIconButton>
        <TooltipIconButton
          label="Save"
          className={classes.menuButtonEnd}
          onClick={props.save}
          disabled={props.loading}
        >
          {props.loading ? <CircularProgress color="secondary" size={24} /> : <SaveIcon />}
        </TooltipIconButton>
      </Toolbar>
    </AppBar>
  )
}
